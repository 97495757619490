import React from "react";

function Schedule() {
  const emailAddress = "your@example.com";
  const subject = "<p>Animesh</p>";
  return (
    <div>
      <a href={`https://mail.google.com/mail/?view=cm&to=${encodeURIComponent(emailAddress)}&body=${encodeURIComponent(subject)}`} target="_blank">
        <button>Send Mail</button>
      </a>
    </div>
  );
}

export default Schedule;
