import { ArrowBack, Close, Done, Star, StarOutline } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Service from "../../../services/httpService";
import { get_specialCharReplace, send_specialCharReplace } from "../../../exportedFunctions/specialCharacterReplace";

function NewHiringModal({ studentList, close, updateShortListedDat, selectedJobs, handleViewProfile }) {
  const [allStudentList, setAllStudentList] = useState(studentList);
  const [modalState, setModalState] = useState({
    current: "Student",
    previous: "Student",
  });

  const [acceptedStudentList, SetAcceptedStudentList] = useState([]);
  const [rejectedStudentList, SetRejectedStudentList] = useState([]);
  const [overviewState, setOverviewState] = useState(false);
  const [currentStudent, setCurrentStudent] = useState(0);
  const [totalStudentCount, setTotalStudentCount] = useState(0);
  const [rejectionRemark, setRejectionRemark] = useState("");
  const services = new Service();
  const handleStudentClick = (stu) => {
    let index = allStudentList.findIndex((student, stu_index) => student.id === stu.id);
    setCurrentStudent(index);
    setModalState((prev) => {
      return {
        ...prev,
        current: "Student",
      };
    });
    //FIXME: SAVE BUTTTONNNNN
    // setActiveSaveButton(true);
  };

  const handleReject = () => {
    let tempStudent = [...allStudentList];
    tempStudent[currentStudent].status_name = "Not selected";
    setAllStudentList(tempStudent);
  };

  const handleAccept = () => {
    let tempStudent = [...allStudentList];
    tempStudent[currentStudent].status_name = "Selected";
    tempStudent[currentStudent].rejection_remark = null;
    setAllStudentList(tempStudent);
  };

  const handleSaveAndNext = () => {
    let temp_student = [...allStudentList];
    if (allStudentList[currentStudent].status_name === "Not selected" && [null, undefined, ""].includes(allStudentList[currentStudent].rejection_remark)) {
      setRejectionRemark("remark is mandatory");
      return;
    }
    if (overviewState) {
      setCurrentStudent(allStudentList.length - 1);
      setModalState((prev) => {
        return {
          ...prev,
          current: "Overview",
        };
      });
    } else {
      setCurrentStudent((prev) => {
        if (prev + 1 === allStudentList.length) {
          setModalState((prev) => {
            return {
              ...prev,
              current: "Overview",
            };
          });
          setOverviewState(true);
          return prev;
        } else {
          let next_student = allStudentList.findIndex((student, index) => student.status_name !== "Interview missed" && index > prev);

          if ([null, undefined, -1].includes(next_student)) {
            setModalState((prev) => {
              return {
                ...prev,
                current: "Overview",
              };
            });
            setOverviewState(true);
            return prev;
          } else {
            return next_student;
          }
        }
      });
    }
    let data = localStorage.getItem("hiringStudent");
    if ([null, undefined].includes(data)) {
      let temp = `${selectedJobs.id}`;
      let item = {
        temp: temp_student,
      };
      localStorage.setItem("hiringStudent", JSON.stringify(item));
    } else {
      data = JSON.parse(data);
      data[`${selectedJobs.id}`] = temp_student;
      localStorage.setItem("hiringStudent", JSON.stringify(data));
    }
    setRejectionRemark("");
  };

  const handleCompleteProcess = async () => {
    let filteredData = allStudentList
      .filter((stu) => stu.status_name !== "Interview missed")
      .map((student) => {
        if (student.status_name === "Selected") {
          return {
            id: student.id,
            is_accepted: 1,
            // rejection_remark: null,
          };
        } else {
          return {
            id: student.id,
            is_accepted: 0,
            rejection_remark: send_specialCharReplace(student.rejection_remark),
          };
        }
      });
    let body = {
      job_id: selectedJobs.id,
      student_list_data: filteredData,
    };

    const putRequest = await services.put(`/jobs/recruiter/accRejStudent`, body);
    // let frontEndData = allStudentList.map((item) => {
    //   return {
    //     ...item,
    //     status_name: item.status_name === "Selected" || item.status_name === "Not selected" ? "Interview scheduled" : item.status_name,
    //   };
    // });
    let frontEndData = allStudentList.filter((item) => item.status_name === "Selected" || item.status_name === "Not selected").map((item) => item.id);
    updateShortListedDat(frontEndData);
    close();
    // setModalState((prev) => {
    //   return {
    //     ...prev,
    //     current: "Confirmation",
    //   };
    // });
  };

  const handleBack = () => {
    let data = localStorage.getItem("hiringStudent");
    let temp_student = [...allStudentList];
    if ([null, undefined].includes(data)) {
      let temp = `${selectedJobs.id}`;
      let item = {
        temp: temp_student,
      };
      localStorage.setItem("hiringStudent", JSON.stringify(item));
    } else {
      data = JSON.parse(data);
      data[`${selectedJobs.id}`] = temp_student;
      localStorage.setItem("hiringStudent", JSON.stringify(data));
    }
    setCurrentStudent((prev) => {
      if (prev === 0) {
        return prev;
      } else {
        return prev - 1;
      }
    });
  };

  useEffect(() => {
    let acceptedList = allStudentList.filter((item) => item.status_name === "Selected");
    let rejectedList = allStudentList.filter((item) => item.status_name === "Not selected");

    SetAcceptedStudentList(acceptedList);
    SetRejectedStudentList(rejectedList);
  }, [currentStudent, modalState, allStudentList]);

  useEffect(() => {
    let data = localStorage.getItem("hiringStudent");
    if (![null, undefined].includes(data)) {
      data = JSON.parse(data);
      if (![null, undefined].includes(data[`${selectedJobs.id}`])) {
        data = data[`${selectedJobs.id}`];
        let student_count = data.filter((stu) => stu.status_name !== "Interview missed").length;

        setAllStudentList(data);
        setTotalStudentCount(student_count);
        let last_updated = data.findIndex((item) => item.status_name === "Interview scheduled");
        if ([null, undefined, -1].includes(last_updated)) {
          setCurrentStudent(data.length - 1);
          setModalState((prev) => {
            return {
              ...prev,
              current: "Overview",
            };
          });
          setOverviewState(true);
        } else {
          setCurrentStudent(last_updated);
        }
      } else {
        let student_count = studentList.filter((stu) => stu.status_name !== "Interview missed").length;
        let lastUpdated = studentList.findIndex((stu) => stu.status_name === "Interview scheduled");
        if ([null, undefined, -1].includes(lastUpdated)) {
          setCurrentStudent(studentList.length - 1);
          setModalState((prev) => {
            return {
              ...prev,
              current: "Overview",
            };
          });
          setOverviewState(true);
        } else {
          setCurrentStudent(lastUpdated);
        }
        setTotalStudentCount(student_count);
      }
    } else {
      let student_count = studentList.filter((stu) => stu.status_name !== "Interview missed").length;
      setTotalStudentCount(student_count);
      let lastUpdated = studentList.findIndex((stu) => stu.status_name === "Interview scheduled");
      if ([null, undefined, -1].includes(lastUpdated)) {
        setCurrentStudent(studentList.length - 1);
        setModalState((prev) => {
          return {
            ...prev,
            current: "Overview",
          };
        });
        setOverviewState(true);
      } else {
        setCurrentStudent(lastUpdated);
      }
    }
  }, []);
  return (
    <Box sx={{ background: "#fff", display: "flex", flexDirection: "column", gap: "20px", width: "45%", height: "auto", borderRadius: "20px", padding: "40px" }}>
      {modalState.current === "Overview" ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{}}>
              <Typography sx={{ color: "#165983", fontSize: "16px" }}>View Hired Details</Typography>
              <Typography sx={{ fontSize: "32px" }}>
                {acceptedStudentList.length}/{selectedJobs.openings}
              </Typography>
            </Box>

            <Typography sx={{ color: "#165983", fontSize: "32px", textAlign: "center", ml: "-1.5rem" }}>Student Selected</Typography>
            <IconButton
              onClick={() =>
                setModalState((prev) => {
                  return {
                    previous: prev.current,
                    current: "Exit",
                  };
                })
              }
              sx={{ height: "100%" }}
            >
              <Close sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {acceptedStudentList.length > 0 && (
              <Box sx={{ "&:hover": { cursor: "pointer" } }}>
                <Typography sx={{ color: "#1EBE70", fontSize: "16px" }}>Accepted</Typography>
                <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>To change your decision, click on the name</Typography>
                <Box>
                  {acceptedStudentList.map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #1EBE70", background: "rgba(30, 190, 112, 0.10)", alignItems: "center" }} onClick={() => handleStudentClick(item)}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <Done sx={{ color: "#1EBE70" }} />
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {rejectedStudentList.length > 0 && (
              <Box sx={{ "&:hover": { cursor: "pointer" } }}>
                <Typography sx={{ color: "#FF0E0E", fontSize: "16px" }}>Rejected</Typography>
                <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>To change your decision, click on the name</Typography>

                <Box>
                  {rejectedStudentList.map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #FF0E0E", background: "rgba(255, 14, 14, 0.10)" }} onClick={() => handleStudentClick(item)}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <Close sx={{ color: "#FF0E0E" }} />
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{ color: "#fff", border: "1px solid #1EBE70", background: "#1EBE70", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", "&:hover": { background: "#1EBE70", color: "#fff" } }}
                onClick={() => {
                  setModalState((prev) => {
                    return {
                      ...prev,
                      current: "Confirmation",
                    };
                  });
                }}
              >
                Save and Complete Process
              </Button>
            </Box>
          </Box>
        </>
      ) : modalState.current === "Confirmation" ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "24px" }}>
              Are you sure you would like to <span style={{ color: "#1EBE70" }}>Complete the process</span>?
            </Typography>
            <IconButton
              onClick={() =>
                setModalState((prev) => {
                  return {
                    previous: prev.current,
                    current: "Exit",
                  };
                })
              }
              sx={{ height: "100%" }}
            >
              <Close sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
            Since you have completed the selection process, a <span style={{ color: "#165983" }}>confirmation mail</span> will now be sent to all the accepted candidates.
            <br />
            Are you sure you would end the process and want CareerCarve to send the confirmation mail?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <Button sx={{ color: "#fff", border: "1px solid #1EBE70", background: "#1EBE70", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", "&:hover": { background: "#1EBE70", color: "#fff" } }} onClick={() => handleCompleteProcess()}>
              Yes, send email
            </Button>
            <Button
              sx={{ color: "#165983", border: "1px solid #165983", background: "#fff", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", "&:hover": { background: "#fff", color: "#165983" } }}
              onClick={() => {
                setModalState((prev) => {
                  return {
                    previous: "Confirmation",
                    current: "Overview",
                  };
                });
              }}
            >
              No, I need more time
            </Button>
          </Box>
        </Box>
      ) : modalState.current === "Exit" ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "24px" }}>
              Are you sure you would like to <span style={{ color: "#FF0E0E" }}>exit</span>?
            </Typography>
            <IconButton onClick={() => close()} sx={{ height: "100%" }}>
              <Close sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
            Until a decision has been taken on all candidates, the process cannot be completed. Your decision progress may not be saved.
            <br />
            Are you sure you would like to exit?
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center" }}>
            <Button
              sx={{ color: "#fff", border: "none", background: "#165983", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", width: "150px", "&:hover": { background: "#165983", color: "#fff" } }}
              onClick={() =>
                setModalState((prev) => {
                  return {
                    ...prev,
                    current: prev.previous,
                  };
                })
              }
            >
              Go Back
            </Button>
            <Button sx={{ color: "#FF0E0E", border: "1px solid #FF0E0E", background: "rgba(255, 14, 14, 0.10)", padding: "8px 15px", borderRadius: "15px", textTransform: "none", width: "150px", alignSelf: "center", "&:hover": { background: "rgba(255, 14, 14, 0.10)", color: "#FF0E0E" } }} onClick={() => close()}>
              Exit
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography sx={{ color: "#165983", fontSize: "16px" }}>View Hired Details</Typography>
              <Typography sx={{ fontSize: "32px" }}>
                <span style={{ color: "#1EBE70" }}>{acceptedStudentList.length}</span>/{selectedJobs.openings}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#165983", fontSize: "16px", textAlign: "center" }}>Student left</Typography>
              <Typography sx={{ fontSize: "32px", textAlign: "center" }}>
                <span style={{ color: "#165983" }}> {allStudentList.length - (currentStudent + 1)}</span>/{totalStudentCount}
              </Typography>
            </Box>
            <IconButton
              onClick={() =>
                setModalState((prev) => {
                  return {
                    previous: prev.current,
                    current: "Exit",
                  };
                })
              }
              sx={{ height: "100%" }}
            >
              <Close sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box>
                <Typography sx={{ fontSize: "20px" }}>
                  {allStudentList[currentStudent].student_name} <span style={{ color: "#8B8B8B" }}>| {allStudentList[currentStudent].college}</span>
                </Typography>
                <Typography sx={{ color: "#165983", fontSize: "16px", textDecoration: "underline", "&:hover": { cursor: "pointer" } }} onClick={() => handleViewProfile(allStudentList[currentStudent])}>
                  View Profile
                </Typography>
              </Box>
              <Box>
                {[1, 2, 3, 4, 5].map((item) => {
                  if (allStudentList[currentStudent].interview_note.rating >= item) {
                    return <Star sx={{ color: "#F5A536" }} />;
                  } else {
                    return <StarOutline sx={{ color: "#F5A536" }} />;
                  }
                })}
              </Box>
            </Box>
            {![null, "", undefined].includes(allStudentList[currentStudent].interview_note.note) && (
              <Box>
                <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>Interview Notes</Typography>
                <Typography sx={{ overflowWrap: "break-word" }}>{get_specialCharReplace(allStudentList[currentStudent].interview_note.note)}</Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              {currentStudent !== 0 && (
                <Box sx={{ padding: "10px", border: "1px solid #000", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", "&:hover": { cursor: "pointer" } }} onClick={() => handleBack()}>
                  <ArrowBack />
                </Box>
              )}
              <Button sx={{ color: "#FF0E0E", border: "1px solid #FF0E0E", background: "rgba(255, 14, 14, 0.10)", padding: "8px 15px", borderRadius: "15px", textTransform: "none", width: "150px", "&:hover": { background: "rgba(255, 14, 14, 0.10)", color: "#FF0E0E" } }} onClick={() => handleReject()} disabled={allStudentList[currentStudent].status_name === "Not selected"}>
                Reject
              </Button>
              <Button sx={{ color: "#fff", border: "1px solid #1EBE70", background: "#1EBE70", padding: "3px 15px", borderRadius: "15px", textTransform: "none", width: "150px", "&:hover": { background: "#1EBE70", color: "#fff" } }} onClick={() => handleAccept()}>
                {allStudentList[currentStudent].status_name === "Selected" ? <Done /> : "Accept"}
              </Button>
            </Box>

            {(allStudentList[currentStudent].status_name === "Not selected" || allStudentList[currentStudent].status_name === "Selected") && (
              <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px", flexDirection: "column", gap: "12px" }}>
                {allStudentList[currentStudent].status_name === "Not selected" && (
                  <Box>
                    <Typography sx={{ fontSize: "16px" }}>Please input the reason for rejecting this candidate</Typography>
                    <TextField
                      value={[null, ""].includes(allStudentList[currentStudent].rejection_remark) ? "" : get_specialCharReplace(allStudentList[currentStudent].rejection_remark)}
                      sx={{ padding: 0, m: 0 }}
                      multiline
                      error={[null, ""].includes(rejectionRemark) ? false : true}
                      helperText={rejectionRemark}
                      placeholder="Describe reason to proceed..."
                      fullWidth
                      InputProps={{
                        style: { padding: "0.4rem", fontSize: "14px" },
                      }}
                      onChange={(e) => {
                        let temp = [...allStudentList];
                        temp[currentStudent].rejection_remark = e.target.value;
                        setAllStudentList(temp);
                      }}
                    />
                    <Typography sx={{ fontSize: "12px", color: "#8B8B8B" }}>Character count {[null, undefined].includes(allStudentList[currentStudent].rejection_remark) ? 0 : allStudentList[currentStudent].rejection_remark.length}/1000</Typography>
                  </Box>
                )}
                <Button disabled={allStudentList[currentStudent].status === "rejected" ? ([null, ""].includes(allStudentList[currentStudent].rejection_remark) ? true : false) : false} sx={{ color: "#fff", border: "1px solid #165983", background: "#165983", padding: "8px 15px", borderRadius: "15px", textTransform: "none", width: "150px", alignSelf: "center", "&:hover": { background: "#165983", color: "#fff" } }} onClick={() => handleSaveAndNext()}>
                  Save and Next
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default NewHiringModal;
