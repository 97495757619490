import { Box } from "@mui/material";
import Scheduler, { Resource } from "devextreme-react/scheduler";
import "devextreme/dist/css/dx.light.css";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Service from "../../services/httpService";
import RecruiterSidenavbar from "./RecruiterSidenavbar";
// import { publishTheCalenderEvent } from "../../exportedFunctions/addToGoogle";
import AddEventButton from "./AddEventButton";
function NewScheduler() {
  const [loading, setLoading] = useState(true);

  const [appointments, setAppoinments] = useState([
    {
      title: "Website Re-Design Plan",
      startDate: new Date(2018, 5, 25, 12, 35),
      endDate: new Date(2018, 5, 25, 15, 0),
      id: 0,
      members: [1],
      location: "Room 1",
    },
    {
      title: "Book Flights to San Fran for Sales Trip",
      startDate: new Date(2018, 5, 26, 12, 35),
      endDate: new Date(2018, 5, 26, 15, 0),
      id: 1,
      members: [2, 4],
      location: "Room 2",
    },
    {
      title: "Install New Router in Dev Room",
      startDate: new Date(2018, 5, 27, 12, 35),
      endDate: new Date(2018, 5, 27, 15, 0),
      id: 2,
      members: [3],
      location: "Room 3",
    },
    {
      title: "Approve Personal Computer Upgrade Plan",
      startDate: new Date(2018, 5, 28, 12, 35),
      endDate: new Date(2018, 5, 28, 15, 0),
      id: 3,
      members: [4, 1],
      location: "Room 4",
    },
    {
      title: "Final Budget Review",
      startDate: new Date(2018, 5, 29, 12, 35),
      endDate: new Date(2018, 5, 29, 15, 0),
      id: 4,
      members: [5, 1, 3],
      location: "Room 5",
    },
    {
      title: "Final Budget Review 2",
      startDate: new Date(2018, 5, 30, 17),
      endDate: new Date(2018, 5, 30, 18, 30),
      id: 5,
      members: [5, 1, 3],
      location: "Room 4",
    },
  ]);
  const services = new Service();
  const views = ["week"];
  const onContentReady = (e) => {
    e.component.scrollTo(new Date());
  };
  useEffect(() => {
    const getStudentData = async () => {
      setLoading(true);
      const studentGet = await services.get("/jobs/recruiter/interviewApplicants?job_id=41");
      let tempData = studentGet.get_shortlisted_student
        .filter((item) => ![null, undefined].includes(item.meeting_link))
        .map((stu) => {
          return {
            id: stu.id,
            text: `Meeting with ${stu.student_name}`,
            startDate: new Date(parseInt(stu.interview_time)),
            endDate: new Date(parseInt(stu.interview_time) + 1800 * 1000),
          };
        });

      setAppoinments(tempData);

      setLoading(false);
    };

    getStudentData();
  }, []);

  const priorityData = [
    {
      text: "Low Priority",
      id: 65,
      color: "#fcb65e",
    },
    {
      text: "High Priority",
      id: 60,
      color: "#e18e92",
    },
  ];

  return (
    <div>
      <RecruiterSidenavbar />
      <div style={{ padding: "2rem 9rem", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        <Navbar title="Your Schedule" />
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <AddEventButton />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Scheduler
            dataSource={appointments}
            views={views}
            defaultCurrentView="week"
            showCurrentTimeIndicator={false}
            allDayPanelMode="hidden"
            cellDuration={30}
            customizeDateNavigatorText={(date) => {
              return `Today`;
            }}
            startDayHour={6}
            firstDayOfWeek={1}
            // indicatorUpdateInterval={updateInterval * 1000}
            // showAllDayPanel={tru}
            // shadeUntilCurrentTime={shadeUntilCurrentTime}
            defaultCurrentDate={new Date()}
            editing={false}
            height={600}
            width={1000}
            // appointmentComponent={AppointmentTemplate}
            onContentReady={onContentReady}
            // onAppointmentClick={onAppointmentClick}
            // onAppointmentDblClick={onAppointmentDblClick}
          >
            <Resource dataSource={priorityData} fieldExpr="id" label="Priority" allowMultiple={false} />
            {/* <Resource dataSource={moviesData} fieldExpr="movieId" /> */}
          </Scheduler>
        </Box>
      </div>
    </div>
  );
}

export default NewScheduler;
