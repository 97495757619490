import { Close, DeleteOutlineOutlined, ModeEditOutlineOutlined, MoreHoriz, Search, Visibility } from "@mui/icons-material";
import { Box, Button, IconButton, LinearProgress, Modal, Skeleton, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { SortJobs } from "../../exportedFunctions/SortingJobs";
import TimeService from "../../exportedFunctions/epochTime";
import Service from "../../services/httpService";
import HiredModal from "./Modals/HiredModal";
import ViewPost from "./Modals/ViewPost";
import RecruiterSidenavbar from "./RecruiterSidenavbar";
import "./recruiterJobs.css";

function RecruiterJobs() {
  const [allJobs, setAllJobs] = useState([]); // ALL POSTED JOBS
  const navigate = useNavigate();
  const [renderJobs, setRenderJobs] = useState([]); //Render JObs after Filter
  const [selectedFilter, setSelectedFilter] = useState("All"); ///Choosed Filter
  const [allRoles, setAllRoles] = useState([]);
  const [allSpecializations, setAllSpecializations] = useState([]);
  const services = new Service();
  const [detailJobs, setDetailsJob] = useState(null);
  const [loader, setLoader] = useState(false);
  const [displayOption, setDisplayOptions] = useState(null); //DISPLAY EDIT AND DELETE ICONS
  const [viewPostModal, setViewPostModal] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [hiredModal, setHiredModal] = useState(false);
  const time = new TimeService();

  const getActions = (postedDate, listTiming, closeDate, testTiming, acceptedTime) => {
    let today = new Date().getTime();

    let firstList = new Date(listTiming.first_list);
    let secondList = new Date(listTiming.second_list);
    let thirdList = new Date(listTiming.third_list);
    // let published = new Date(postedDate);
    // let testDate = new Date(testTiming);
    // let closeTest = new Date(closeDate);
    let acceptTime = new Date(acceptedTime);
    const AcceptedNextDay = new Date(acceptTime);
    AcceptedNextDay.setDate(new Date().getDate() + 1);

    if (listTiming.last_interview_time !== null && listTiming.last_interview_time !== undefined) {
      return {
        current: "Final Hiring decision now open",
        next_action: ``,
      };
    } else if (listTiming.third_list < today && [null, undefined].includes(listTiming.first_interview_time)) {
      return {
        current: "Interviews in Progress ",
        next_action: `Declare final hiring results`,
      };
    } else if (listTiming.third_list < today) {
      return {
        current: "Shortlist Set 03 Available to view ",
        next_action: `Schedule and complete all interviews`,
      };
    } else if (listTiming.second_list < today) {
      return {
        current: "Shortlist Set 02 Available to view ",
        next_action: `Shortlist set 03 will be available on ${thirdList.getDate()}/${thirdList.getMonth() + 1}/${thirdList.getFullYear()}`,
      };
    } else if (listTiming.first_list < today) {
      return {
        current: "Shortlist Set 01 Available to view",
        next_action: `Shortlist set 02 will be available on ${secondList.getDate()}/${secondList.getMonth() + 1}/${secondList.getFullYear()}`,
      };
    } else if (acceptTime.toDateString() === new Date().toDateString()) {
      return {
        current: "Open to applications",
        next_action: `Candidate assessments will begin`,
      };
    } else {
      return {
        current: "Candidate Assessments in Progress",
        next_action: `Shortlist set 01 will be available on ${firstList.getDate()}/${firstList.getMonth() + 1}/${firstList.getFullYear()}`,
      };
    }
  };
  const [deleteModal, setDeleteModal] = useState(false);
  useEffect(() => {
    const getAllJobs = async () => {
      setLoader(true);
      const allPostedJobs = await services.get("/jobs/recruiter/allJobTable");
      const roles = await services.get("/superAdmin/role");
      const specialization = await services.get("/superAdmin/specialization");
      let filteredJobs = SortJobs(allPostedJobs.data);
      filteredJobs = filteredJobs.map((item) => {
        let item_status =
          item.job_status === "Accept" || item.job_status === "Paused"
            ? getActions(item.job_published_time, item.list_timing, item.closed_timing, item.test_timing, item.job_accepted_time)
            : {
                current: "Publish the Job",
                next_action: "Shortlisting will begin soon",
              };
        return {
          ...item,
          current_action: item_status.current,
          next_action: item_status.next_action,
          showOptions: false,
        };
      });
      setAllRoles(roles.data);
      setAllSpecializations(specialization.data);
      setAllJobs(filteredJobs);
      setRenderJobs(filteredJobs);
      setLoader(false);
    };

    getAllJobs();
  }, []);

  const handleEditClick = (job_id) => {
    navigate("/Jobs", { state: { job_id: job_id } });
  };
  const getStatus = (postedDate, listTiming, closeDate, testTiming, acceptedJobTime, type, job) => {
    if (job.job_status === "Completed") return "Completed";
    let today = new Date();
    let firstList = new Date(listTiming.first_list);

    let thirdList = new Date(listTiming.third_list);
    let first_scheduled = listTiming.first_interview_time ? (listTiming.first_interview_time === null ? null : new Date(listTiming.first_interview_time)) : null;
    let last_scheduled = listTiming.last_interview_time ? (listTiming.last_interview_time === null ? null : new Date(listTiming.last_interview_time)) : null;

    let acceptedTime = new Date(acceptedJobTime);
    // let threeDay = new Date(acceptedJobTime);
    // threeDay = threeDay.setDate(threeDay.getDate() + 3);
    let testDate = new Date(testTiming);
    // let closeTest = new Date(closeDate);

    switch (type) {
      case "test":
        if (today > acceptedTime && today < testDate) {
          return "On going";
        } else if (today > testDate) {
          return "Completed";
        } else if (today < firstList) {
          return "Not Started";
        }

      case "applications":
        if (today > closeDate) return "Completed";
        else if (today > acceptedTime) return "On going";
        else return "Not Started";

      case "shortlisted":
        if (today > thirdList) {
          return "Completed";
        } else if (today > firstList) return "On going";
        else return "Not Started";

      case "interview":
        if (first_scheduled === null) return "Not Started";
        else if (today > first_scheduled) return "On going";
        else if (last_scheduled !== null) return "Completed";
        else return "Not Started";

      case "hiring":
        if (last_scheduled !== null) return "On going";
        else if (job.job_status === "Completed") return "Completed";
        else return "Not Started";

      default:
        return "Not Started";
    }
  };
  useEffect(() => {
    if (selectedFilter === "All") {
      let temp_render = [...allJobs];
      if (!["", undefined, null].includes(searchParams)) {
        temp_render = temp_render.filter((item) => item.role_name?.toLowerCase().includes(searchParams.toLowerCase()) || item.job_type_name.toLowerCase().includes(searchParams.toLowerCase()));
      }
      setRenderJobs(temp_render);
    } else if (selectedFilter === "Closed") {
      let temp_render = allJobs.filter((item) => item.job_status === "Closed" || item.job_status === "Expired");
      if (!["", undefined, null].includes(searchParams)) {
        temp_render = temp_render.filter((item) => item.role_name?.toLowerCase().includes(searchParams.toLowerCase()) || item.job_type_name.toLowerCase().includes(searchParams.toLowerCase()));
      }
      setRenderJobs(temp_render);
    } else if (selectedFilter === "In Process") {
      let temp_render = allJobs.filter((item) => item.job_status === "On hold" || item.job_status === "Incomplete" || item.job_status === "In review");

      if (!["", undefined, null].includes(searchParams)) {
        temp_render = temp_render.filter((item) => item.role_name?.toLowerCase().includes(searchParams.toLowerCase()) || item.job_type_name.toLowerCase().includes(searchParams.toLowerCase()));
      }
      setRenderJobs(temp_render);
    } else if (selectedFilter === "Accept") {
      let temp_render = allJobs.filter((item) => item.job_status === "Accept" || item.job_status === "Paused");
      if (!["", undefined, null].includes(searchParams)) {
        temp_render = temp_render.filter((item) => item.role_name?.toLowerCase().includes(searchParams.toLowerCase()) || item.job_type_name.toLowerCase().includes(searchParams.toLowerCase()));
      }
      setRenderJobs(temp_render);
    } else if (selectedFilter === "Completed") {
      let temp_render = allJobs.filter((item) => item.job_status === "Completed");
      if (!["", undefined, null].includes(searchParams)) {
        temp_render = temp_render.filter((item) => item.role_name?.toLowerCase().includes(searchParams.toLowerCase()) || item.job_type_name.toLowerCase().includes(searchParams.toLowerCase()));
      }
      setRenderJobs(temp_render);
    }
  }, [selectedFilter, allJobs, searchParams]);

  const handleDelete = async () => {
    try {
      const jobItem = allJobs.find((item) => item.showOptions === true);
      const response = await services.put(`/jobs/recruiter/deleteJob?job_id=${jobItem.id}`);

      if (response.success) {
        let data = renderJobs.filter((item) => item.id !== jobItem.id);
        let temp_all_jobs = allJobs.filter((item) => item.id !== jobItem.id);
        setRenderJobs(data);
        setAllJobs(temp_all_jobs);
        setDeleteModal(false);
      }
    } catch (error) {}
  };

  const jobRef = useRef(null);
  const location = useLocation();

  const handleCloseJob = async (job) => {
    try {
      const sendRequest = await services.put(`/jobs/recruiter/closeJob?job_id=${job.id}`);
      let temp_jobs = allJobs.map((item) => {
        if (item.id === job.id) {
          return {
            ...item,
            job_status: "Closed",
          };
        } else {
          return {
            ...item,
          };
        }
      });

      setAllJobs(temp_jobs);
    } catch (error) {}
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get("job_id");

    if (![null, undefined, ""].includes(job_id) && jobRef.current !== null && !loader) {
      window.scrollTo({
        top: jobRef.current.offsetTop - window.innerHeight / 2 + 500,
        behavior: "smooth",
      });
    }
  }, [jobRef.current]);
  return (
    <Box>
      <RecruiterSidenavbar />

      <Box
        sx={{
          padding: "2rem 9rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Navbar title="Your Jobs" />

        <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <Box>
            <Typography sx={{ fontSize: "24px" }}>Your Job Posts</Typography>
            <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Browse through all your job postings here.</Typography>
          </Box>

          <Box>
            <TextField
              sx={{
                width: "100%",
                fontSize: "20px",
              }}
              autoComplete="off"
              InputProps={{
                startAdornment: <Search sx={{ marginRight: "10px" }} />,
                sx: { borderRadius: "30px", height: "40px" },
              }}
              onChange={(e) => setSearchParams(e.target.value)}
              placeholder="Search for your job type or role "
            />
          </Box>

          <Box
            sx={{
              padding: "10px",
              display: "flex",
              borderRadius: "15px",
              justifyContent: "space-between",
              boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
              gap: "2.5rem",
            }}
          >
            <Box
              sx={{
                width: "100%",
                background: selectedFilter === "All" ? "#FFD5CF" : "#fff",
                padding: "8px",
                borderRadius: "10px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ textAlign: "center" }} onClick={() => setSelectedFilter("All")}>
                All
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "8px",
                borderRadius: "10px",
                background: selectedFilter === "Accept" ? "#FFD5CF" : "#fff",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ textAlign: "center" }} onClick={() => setSelectedFilter("Accept")}>
                Live
              </Typography>
            </Box>{" "}
            <Box
              sx={{
                width: "100%",
                padding: "8px",
                borderRadius: "10px",
                background: selectedFilter === "In Process" ? "#FFD5CF" : "#fff",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ textAlign: "center" }} onClick={() => setSelectedFilter("In Process")}>
                In-Process
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "8px",
                borderRadius: "10px",
                background: selectedFilter === "Completed" ? "#FFD5CF" : "#fff",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ textAlign: "center" }} onClick={() => setSelectedFilter("Completed")}>
                Completed
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "8px",
                borderRadius: "10px",
                background: selectedFilter === "Closed" ? "#FFD5CF" : "#fff",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ textAlign: "center" }} onClick={() => setSelectedFilter("Closed")}>
                Closed
              </Typography>
            </Box>
          </Box>
          <div>
            {loader ? (
              <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
                <Box
                  sx={{
                    boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                    border: "1px solid #E6E6E6",
                    borderRadius: "15px",
                    padding: "16px 24px",
                    display: "flex",
                    flexDirection: "column",

                    zIndex: 100,
                    marginBottom: "-20px",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="text" width="200px" height="40px" />
                    <Skeleton variant="text" width="100px" height="40px" sx={{ borderRadius: "20px" }} />
                  </div>
                  <Skeleton variant="text" width="200px" height="40px" />
                  <Skeleton variant="text" width="200px" height="40px" />
                  <Skeleton variant="text" width="200px" height="40px" />
                </Box>

                <Box
                  sx={{
                    boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                    border: "1px solid #E6E6E6",
                    borderRadius: "15px",
                    padding: "16px 24px",
                    display: "flex",
                    flexDirection: "column",

                    zIndex: 100,
                    marginBottom: "-20px",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="text" width="200px" height="40px" />
                    <Skeleton variant="text" width="200px" height="40px" />
                  </div>
                  <Skeleton variant="text" width="200px" height="40px" />
                  <Skeleton variant="text" width="200px" height="40px" />
                  <Skeleton variant="text" width="200px" height="40px" />
                </Box>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
                {renderJobs.length > 0 ? (
                  renderJobs.map((job) => {
                    if (job.job_status === "Accept" || job.job_status === "Paused") {
                      return (
                        <div
                          style={{
                            boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                            border: "1px solid #E6E6E6",
                            borderRadius: "15px",
                            padding: "16px 24px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            zIndex: 100,
                            marginBottom: "-20px",
                          }}
                          ref={detailJobs === job.id ? jobRef : null}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "16px" }}>
                                    {job.job_type_id === 1 ? (
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                        <img src="https://du03hetco8xdw.cloudfront.net/recruiter/live_project_svg.svg" style={{ width: "30px", height: "30px" }} alt="live project" />
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                          <Typography>{job.job_designation} | Live Project Intern</Typography>
                                          <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              padding: "1px 10px",
                                              background: "rgba(30, 190, 112, 0.10)",
                                              color: "#1EBE70",
                                              letterSpacing: "1.79px",
                                              borderRadius: "8px",
                                              border: "1px solid #1EBE70",
                                            }}
                                          >
                                            LIVE
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : job.job_type_id === 2 ? (
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <img src="https://du03hetco8xdw.cloudfront.net/recruiter/summer_intern_svg.svg" style={{ width: "30px", height: "30px" }} alt="summer intern" />
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                          <Typography>{job.job_designation} | Summer Intern</Typography>
                                          <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              padding: "1px 10px",
                                              background: "rgba(30, 190, 112, 0.10)",
                                              color: "#1EBE70",
                                              letterSpacing: "1.79px",
                                              borderRadius: "8px",
                                              border: "1px solid #1EBE70",
                                            }}
                                          >
                                            LIVE{" "}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : (
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                        <img src="https://du03hetco8xdw.cloudfront.net/recruiter/full_time_svg.svg" style={{ width: "30px", height: "30px" }} alt="full time" />
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                          <Typography>{job.job_designation} | Full Time Employee</Typography>
                                          <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              padding: "1px 10px",
                                              background: "rgba(30, 190, 112, 0.10)",
                                              color: "#1EBE70",
                                              letterSpacing: "1.79px",
                                              borderRadius: "8px",
                                              border: "1px solid #1EBE70",
                                            }}
                                          >
                                            LIVE
                                          </Typography>
                                        </Box>
                                      </Box>
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              {job.role_id && (
                                <Box sx={{ display: "flex", gap: "10px" }}>
                                  <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>
                                    {allRoles.find((ele) => ele.id === job.role_id).name} | {allSpecializations.find((ele) => ele.id === job.spe_id).name}
                                  </Typography>
                                </Box>
                              )}
                              <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>Posted on: {time.convertEpochToDate(job.job_published_time)}</Typography>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                              <Box>
                                <IconButton
                                  sx={{ display: "flex", flexDirection: "column", zIndex: 999 }}
                                  onClick={() => {
                                    let temp = allJobs.map((item, i) => {
                                      return {
                                        ...item,
                                        showOptions: item.id === job.id ? (item.showOptions ? false : true) : false,
                                      };
                                    });
                                    setAllJobs(temp);
                                  }}
                                >
                                  <MoreHoriz />
                                </IconButton>
                                <Box sx={{ position: "absolute", right: "10rem", backgroundColor: "#fff", boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)", borderRadius: "10px", padding: "10px", border: "1px solid #E6E6E6", display: job.showOptions ? "" : "none" }}>
                                  <Box
                                    sx={{
                                      paddingInline: "15px",
                                      display: "flex",
                                      gap: "5px",
                                      marginBottom: "5px",
                                      width: "100%",
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={(e) => setViewPostModal(true)}
                                  >
                                    <Visibility />
                                    <Typography>View Post</Typography>
                                  </Box>

                                  <Box
                                    sx={{
                                      paddingInline: "15px",
                                      display: "flex",
                                      gap: "5px",
                                      marginBottom: "5px",
                                      width: "100%",
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={(e) => handleCloseJob(job)}
                                  >
                                    <Close />
                                    <Typography>Close Job</Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Typography sx={{ color: "#8B8B8B", fontSize: "14px", textAlign: "right" }}>Current Status</Typography>
                              <Typography sx={{ color: "#1EBE70", fontSize: "16px", textAlign: "right" }}>{job.current_action}</Typography>
                              <Typography sx={{ color: "#8B8B8B", fontSize: "14px", textAlign: "right" }}>Next Action</Typography>
                              <Typography sx={{ color: "#165983", fontSize: "14px", textAlign: "right" }}>{job.next_action}</Typography>
                            </Box>
                          </Box>

                          <Typography
                            sx={{ color: "#165983", fontSize: "16px", textDecoration: "underline", "&:hover": { cursor: "pointer" } }}
                            onClick={() => {
                              if (detailJobs === job.id) {
                                setDetailsJob(null);
                              } else {
                                setDetailsJob(job.id);
                              }
                            }}
                          >
                            {job.id === detailJobs ? "Hide Details" : "Show details"}
                          </Typography>
                          {detailJobs === job.id && (
                            <Box>
                              <Typography sx={{ fontSize: "24px", marginBottom: "16px" }}>Recruitment Process</Typography>
                              <Box sx={{ display: "flex" }}>
                                {/* 01 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "applications", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Applicants</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "applications", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Applicants</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Applicants</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 02 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "test", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>02</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Assessment Test</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "test", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>02</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Assessment Test</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Assessment Test</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 03 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "shortlisted", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>03</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Shortlisted Candidate</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "shortlisted", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>03</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Shortlisted Candidate</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>03</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Shortlisted Candidate</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 04 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "interview", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>04</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Schedule Interview</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "interview", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>04</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Schedule Interview</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>04</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Schedule Interview</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 05 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "hiring", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>05</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Final Hiring</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "hiring", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>05</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Final Hiring</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>05</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Final Hiring</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                              {["On going", "Completed"].includes(getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "shortlisted", job)) && (
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                  <Box sx={{ flex: 2 }}>
                                    <Button sx={{ border: "1px solid #165983", borderRadius: "20px", color: "#165983", "&:hover": { color: "#165983" }, textTransform: "none", padding: "5px 15px", fontSize: "14px", width: "fit-content", ml: "6%" }} onClick={() => navigate(`/Applicants?job_id=${job.id}`)}>
                                      View Applicants
                                    </Button>
                                  </Box>

                                  <Box sx={{ flex: 3 }}>
                                    <Button sx={{ border: "1px solid #165983", borderRadius: "20px", color: "#165983", "&:hover": { color: "#165983" }, textTransform: "none", padding: "5px 15px", fontSize: "14px", width: "fit-content", ml: "10%" }} onClick={() => navigate(`/Recruiter/Shortlist?job_id=${job.id}`)}>
                                      View List
                                    </Button>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          )}
                        </div>
                      );
                    } else if (job.job_status === "In review") {
                      return (
                        <Box>
                          <Box
                            sx={{
                              boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                              border: "1px solid #E6E6E6",
                              borderRadius: "15px",
                              padding: "16px 24px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                              zIndex: 100,
                              marginBottom: "-20px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>
                                  {job.job_type_id === 1 ? (
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                      <img src="https://du03hetco8xdw.cloudfront.net/recruiter/live_project_svg.svg" style={{ width: "30px", height: "30px" }} alt="live project" />
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography>{job.job_designation} | Live Project Intern</Typography>
                                        <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            padding: "1px 10px",
                                            background: "rgba(245, 165, 54, 0.10)",
                                            color: "#F5A536",
                                            letterSpacing: "1.79px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          IN-REVIEW
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : job.job_type_id === 2 ? (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      <img src="https://du03hetco8xdw.cloudfront.net/recruiter/summer_intern_svg.svg" style={{ width: "30px", height: "30px" }} alt="summer intern" />
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography>{job.job_designation} | Summer Intern</Typography>
                                        <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            padding: "1px 10px",
                                            background: "rgba(245, 165, 54, 0.10)",
                                            color: "#F5A536",
                                            letterSpacing: "1.79px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          IN-REVIEW
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                      <img src="https://du03hetco8xdw.cloudfront.net/recruiter/full_time_svg.svg" style={{ width: "30px", height: "30px" }} alt="full time" />
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography>{job.job_designation} | Full Time Employee</Typography>
                                        <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            padding: "1px 10px",
                                            background: "rgba(245, 165, 54, 0.10)",
                                            color: "#F5A536",
                                            letterSpacing: "1.79px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          IN-REVIEW
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )}
                                </Typography>
                              </Box>
                              <Box>
                                <IconButton
                                  sx={{ display: "flex", flexDirection: "column", zIndex: 999 }}
                                  onClick={() => {
                                    let temp = allJobs.map((item, i) => {
                                      return {
                                        ...item,
                                        showOptions: item.id === job.id ? (item.showOptions ? false : true) : false,
                                      };
                                    });
                                    setAllJobs(temp);
                                  }}
                                >
                                  <MoreHoriz />
                                </IconButton>
                                <Box sx={{ position: "relative" }}>
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      right: 10,
                                      border: "1px solid #E6E6E6",
                                      paddingBlock: "5px",
                                      boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                                      borderRadius: "10px",
                                      display: job.showOptions ? "" : "none",
                                      backgroundColor: "#fff",
                                      width: "150px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        paddingInline: "15px",
                                        display: "flex",
                                        gap: "5px",
                                        marginBottom: "5px",
                                        width: "100%",
                                      }}
                                      onClick={(e) => setViewPostModal(true)}
                                    >
                                      <Visibility />
                                      <Typography>View Post</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            {job.role_id && (
                              <Box sx={{ display: "flex", gap: "10px" }}>
                                <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>
                                  {allRoles.find((ele) => ele.id === job.role_id).name} | {allSpecializations.find((ele) => ele.id === job.spe_id).name}
                                </Typography>
                              </Box>
                            )}
                            <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>Posted on: {time.convertEpochToDate(job.job_published_time)}</Typography>
                          </Box>
                          <Box
                            sx={{
                              borderRight: "1px solid #8B8B8B",
                              borderLeft: "1px solid #8B8B8B",
                              borderBottom: "1px solid #8B8B8B",
                              borderRadius: "0px 0px 30px 30px",
                              paddingBlock: "30px 10px",
                            }}
                          >
                            <Typography sx={{ textAlign: "center" }}>Your Job Post is under review. You will be notified via email once it goes live.</Typography>
                          </Box>
                        </Box>
                      );
                    } else if (job.job_status === "Incomplete") {
                      return (
                        <Box
                          sx={{
                            boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                            border: "1px solid #E6E6E6",
                            borderRadius: "15px",
                            padding: "16px 24px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: "16px" }}>
                                {job.job_type_id === 1 ? (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/live_project_svg.svg" style={{ width: "30px", height: "30px" }} alt="live project" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Live Project Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "#D0DEE6",
                                          color: "#165983",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        INCOMPLETE
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : job.job_type_id === 2 ? (
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/summer_intern_svg.svg" style={{ width: "30px", height: "30px" }} alt="Summer intern" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Summer Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "#D0DEE6",
                                          color: "#165983",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        INCOMPLETE
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/full_time_svg.svg" style={{ width: "30px", height: "30px" }} alt="full time" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Full Time Employee</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "#D0DEE6",
                                          color: "#165983",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        INCOMPLETE
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </Typography>
                            </Box>

                            <Box>
                              <IconButton
                                sx={{ display: "flex", flexDirection: "column", zIndex: 999 }}
                                onClick={() => {
                                  let temp = allJobs.map((item, i) => {
                                    return {
                                      ...item,
                                      showOptions: item.id === job.id ? (item.showOptions ? false : true) : false,
                                    };
                                  });
                                  setAllJobs(temp);
                                }}
                              >
                                <MoreHoriz />
                              </IconButton>
                              <Box sx={{ position: "relative" }}>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: 10,
                                    border: "1px solid #E6E6E6",
                                    paddingBlock: "5px",
                                    boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                                    borderRadius: "10px",
                                    display: job.showOptions ? "" : "none",
                                    backgroundColor: "#fff",
                                    width: "150px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      paddingInline: "15px",
                                      display: "flex",
                                      gap: "5px",
                                      marginBottom: "5px",
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={() => handleEditClick(job.id)}
                                  >
                                    <ModeEditOutlineOutlined />
                                    <Typography>Edit</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      paddingInline: "15px",
                                      display: "flex",
                                      gap: "5px",
                                      color: "red",
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={() => setDeleteModal(true)}
                                  >
                                    <DeleteOutlineOutlined />
                                    <Typography>Delete</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {job.role_id && (
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>
                                {allRoles.find((ele) => ele.id === job.role_id).name} | {allSpecializations.find((ele) => ele.id === job.spe_id).name}
                              </Typography>
                            </Box>
                          )}
                          <Box sx={{ width: "40%" }}>
                            <LinearProgress
                              variant="determinate"
                              value={Math.floor((job.last_updated / 5) * 100)}
                              sx={{
                                background: "#E6E6E6",
                                height: 8,
                                borderRadius: 5,
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "#165983",
                                },
                              }}
                            />
                          </Box>
                          <Typography sx={{ color: "#165983", fontSize: "12px" }}>{Math.floor((job.last_updated / 5) * 100)}% Complete</Typography>
                          <Button
                            sx={{
                              fontSize: "16px",
                              color: "#165983",
                              padding: "5px 40px",
                              background: "#fff",
                              border: "1px solid #165983",
                              borderRadius: "15px",
                              width: "fit-content",
                              textTransform: "none",
                            }}
                            onClick={() => handleEditClick(job.id)}
                          >
                            Complete Now
                          </Button>
                        </Box>
                      );
                    } else if (job.job_status === "On hold") {
                      return (
                        <Box
                          sx={{
                            boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                            border: "1px solid #E6E6E6",
                            borderRadius: "15px",
                            padding: "16px 24px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: "16px" }}>
                                {job.job_type_id === 1 ? (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/live_project_svg.svg" style={{ width: "30px", height: "30px" }} alt="live project" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Live Project Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "rgba(255, 14, 14, 0.10)",
                                          color: "#FF0E0E",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        ON-HOLD
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : job.job_type_id === 2 ? (
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/summer_intern_svg.svg" style={{ width: "30px", height: "30px" }} alt="summer intern" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Summer Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "rgba(255, 14, 14, 0.10)",
                                          color: "#FF0E0E",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        ON-HOLD
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/full_time_svg.svg" style={{ width: "30px", height: "30px" }} alt="full time" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Full Time Employee</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "rgba(255, 14, 14, 0.10)",
                                          color: "#FF0E0E",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        ON-HOLD
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton sx={{ display: "flex", flexDirection: "column" }} onClick={() => (displayOption === job.id ? setDisplayOptions(null) : setDisplayOptions(job.id))}>
                                <MoreHoriz />
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: 30,
                                    border: "1px solid #E6E6E6",
                                    top: 22,
                                    paddingBlock: "5px",
                                    boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                                    borderRadius: "10px",
                                    display: displayOption === job.id ? "" : "none",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      paddingInline: "15px",
                                      display: "flex",
                                      gap: "5px",
                                      marginBottom: "5px",
                                      "&:hover": {
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={() => handleEditClick(job.id)}
                                  >
                                    <ModeEditOutlineOutlined />
                                    <Typography>Edit</Typography>
                                  </Box>
                                </Box>
                              </IconButton>
                            </Box>
                          </Box>
                          {job.role_id && (
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>
                                {allRoles.find((ele) => ele.id === job.role_id).name} | {allSpecializations.find((ele) => ele.id === job.spe_id).name}
                              </Typography>
                            </Box>
                          )}
                          <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>Posted on: {time.convertEpochToDate(job.job_published_time)}</Typography>
                          <Box>
                            <Typography sx={{ color: "#FF0E0E", fontSize: "16px" }}>Reason for placing On-Hold</Typography>
                            {job.remarks && <Typography sx={{ fontSize: "16px" }}>{job.remarks.remarks[job.remarks.remarks.length - 1].remark}</Typography>}
                          </Box>
                          <Button
                            sx={{
                              fontSize: "16px",
                              color: "#165983",
                              padding: "5px 40px",
                              background: "#fff",
                              border: "1px solid #165983",
                              borderRadius: "15px",
                              width: "fit-content",
                              textTransform: "none",
                            }}
                            onClick={() => handleEditClick(job.id)}
                          >
                            Edit now
                          </Button>
                        </Box>
                      );
                    } else if (job.job_status === "Expired") {
                      return (
                        <Box>
                          <Box
                            sx={{
                              boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                              border: "1px solid #E6E6E6",
                              borderRadius: "15px",
                              padding: "16px 24px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                              zIndex: 100,
                              marginBottom: "-20px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>
                                  {job.job_type_id === 1 ? (
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                      <img src="https://du03hetco8xdw.cloudfront.net/recruiter/live_project_svg.svg" style={{ width: "30px", height: "30px" }} alt="live project" />
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography>{job.job_designation} | Live Project Intern</Typography>
                                        <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            padding: "1px 10px",
                                            background: "rgba(255, 14, 14, 0.10)",
                                            color: "#FF0E0E",
                                            letterSpacing: "1.79px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          EXPIRED
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : job.job_type_id === 2 ? (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      <img src="https://du03hetco8xdw.cloudfront.net/recruiter/summer_intern_svg.svg" style={{ width: "30px", height: "30px" }} alt="summer intern" />
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography>{job.job_designation} | Summer Intern</Typography>
                                        <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            padding: "1px 10px",
                                            background: "rgba(255, 14, 14, 0.10)",
                                            color: "#FF0E0E",
                                            letterSpacing: "1.79px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          EXPIRED
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                      <img src="https://du03hetco8xdw.cloudfront.net/recruiter/full_time_svg.svg" style={{ width: "30px", height: "30px" }} alt="full time" />
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography>{job.job_designation} | Full Time Employee</Typography>
                                        <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            padding: "1px 10px",
                                            background: "rgba(255, 14, 14, 0.10)",
                                            color: "#FF0E0E",
                                            letterSpacing: "1.79px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          EXPIRED
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )}
                                </Typography>
                              </Box>
                              <Box>
                                <IconButton
                                  sx={{ display: "flex", flexDirection: "column", zIndex: 999 }}
                                  onClick={() => {
                                    let temp = allJobs.map((item, i) => {
                                      return {
                                        ...item,
                                        showOptions: item.id === job.id ? (item.showOptions ? false : true) : false,
                                      };
                                    });
                                    setAllJobs(temp);
                                  }}
                                >
                                  <MoreHoriz />
                                </IconButton>
                                <Box sx={{ position: "relative" }}>
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      right: 10,
                                      border: "1px solid #E6E6E6",
                                      paddingBlock: "5px",
                                      boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                                      borderRadius: "10px",
                                      display: job.showOptions ? "" : "none",
                                      backgroundColor: "#fff",
                                      width: "150px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        paddingInline: "15px",
                                        display: "flex",
                                        gap: "5px",
                                        marginBottom: "5px",
                                        width: "100%",
                                      }}
                                      onClick={(e) => setViewPostModal(true)}
                                    >
                                      <Visibility />
                                      <Typography>View Post</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            {job.role_id && (
                              <Box sx={{ display: "flex", gap: "10px" }}>
                                <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>
                                  {allRoles.find((ele) => ele.id === job.role_id).name} | {allSpecializations.find((ele) => ele.id === job.spe_id).name}
                                </Typography>
                              </Box>
                            )}
                            <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>Posted on: {time.convertEpochToDate(job.job_published_time)}</Typography>
                          </Box>
                          <Box
                            sx={{
                              borderRight: "1px solid #8B8B8B",
                              borderLeft: "1px solid #8B8B8B",
                              borderBottom: "1px solid #8B8B8B",
                              borderRadius: "0px 0px 30px 30px",
                              paddingBlock: "30px 10px",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontSize: "16px",
                                color: "#545454",
                              }}
                            >
                              Since no action has taken place on this job post, it has expired
                            </Typography>
                          </Box>
                        </Box>
                      );
                    } else if (job.job_status === "Closed") {
                      return (
                        <Box
                          sx={{
                            boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                            border: "1px solid #E6E6E6",
                            borderRadius: "15px",
                            padding: "16px 24px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            zIndex: 100,
                            marginBottom: "-20px",
                          }}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: "16px" }}>
                                {job.job_type_id === 1 ? (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/live_project_svg.svg" style={{ width: "30px", height: "30px" }} alt="live Project" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Live Project Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "#F2F2F2",
                                          color: "#000",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        CLOSED
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : job.job_type_id === 2 ? (
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/summer_intern_svg.svg" style={{ width: "30px", height: "30px" }} alt="/" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Summer Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "#F2F2F2",
                                          color: "#000",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        CLOSED
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/full_time_svg.svg" style={{ width: "30px", height: "30px" }} alt="/" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Full Time Employee</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "#F2F2F2",
                                          color: "#000",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        CLOSED
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton
                                sx={{ display: "flex", flexDirection: "column", zIndex: 999 }}
                                onClick={() => {
                                  let temp = allJobs.map((item, i) => {
                                    return {
                                      ...item,
                                      showOptions: item.id === job.id ? (item.showOptions ? false : true) : false,
                                    };
                                  });
                                  setAllJobs(temp);
                                }}
                              >
                                <MoreHoriz />
                              </IconButton>
                              <Box sx={{ position: "relative" }}>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: 10,
                                    border: "1px solid #E6E6E6",
                                    paddingBlock: "5px",
                                    boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                                    borderRadius: "10px",
                                    display: job.showOptions ? "" : "none",
                                    backgroundColor: "#fff",
                                    width: "150px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      paddingInline: "15px",
                                      display: "flex",
                                      gap: "5px",
                                      marginBottom: "5px",
                                      width: "100%",
                                    }}
                                    onClick={(e) => setViewPostModal(true)}
                                  >
                                    <Visibility />
                                    <Typography>View Post</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>

                          {job.role_id && (
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>
                                {allRoles.find((ele) => ele.id === job.role_id).name} | {allSpecializations.find((ele) => ele.id === job.spe_id).name}
                              </Typography>
                            </Box>
                          )}
                          <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>Posted on: {time.convertEpochToDate(job.job_published_time)}</Typography>
                        </Box>
                      );
                    } else if (job.job_status === "Completed") {
                      return (
                        <Box
                          sx={{
                            boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                            border: "1px solid #E6E6E6",
                            borderRadius: "15px",
                            padding: "16px 24px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            zIndex: 100,
                            marginBottom: "-20px",
                          }}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: "16px" }}>
                                {job.job_type_id === 1 ? (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/live_project_svg.svg" style={{ width: "30px", height: "30px" }} alt="/" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Live Project Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "rgba(30, 190, 112, 0.10)",
                                          color: "#1EBE70",
                                          border: "1px solid #1EBE70",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        COMPLETED
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : job.job_type_id === 2 ? (
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/summer_intern_svg.svg" style={{ width: "30px", height: "30px" }} alt="summer intern" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Summer Intern</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "rgba(30, 190, 112, 0.10)",
                                          color: "#1EBE70",
                                          letterSpacing: "1.79px",
                                          border: "1px solid #1EBE70",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        COMPLETED
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/full_time_svg.svg" style={{ width: "30px", height: "30px" }} alt="" />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <Typography>{job.job_designation} | Full Time Employee</Typography>
                                      <Typography sx={{ fontSize: "16px" }}>|</Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: "1px 10px",
                                          background: "rgba(30, 190, 112, 0.10)",
                                          color: "#1EBE70",
                                          letterSpacing: "1.79px",
                                          borderRadius: "8px",
                                          border: "1px solid #1EBE70",
                                        }}
                                      >
                                        COMPLETED
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton
                                sx={{ display: "flex", flexDirection: "column", zIndex: 999 }}
                                onClick={() => {
                                  let temp = allJobs.map((item, i) => {
                                    return {
                                      ...item,
                                      showOptions: item.id === job.id ? (item.showOptions ? false : true) : false,
                                    };
                                  });
                                  setAllJobs(temp);
                                }}
                              >
                                <MoreHoriz />
                              </IconButton>
                              <Box sx={{ position: "absolute", right: "10rem", backgroundColor: "#fff", boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)", borderRadius: "10px", padding: "10px", border: "1px solid #E6E6E6", display: job.showOptions ? "" : "none" }}>
                                <Box
                                  sx={{
                                    paddingInline: "15px",
                                    display: "flex",
                                    gap: "5px",
                                    marginBottom: "5px",
                                    width: "100%",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={(e) => setViewPostModal(true)}
                                >
                                  <Visibility />
                                  <Typography>View Post</Typography>
                                </Box>

                                {/* <Box
                                  sx={{
                                    paddingInline: "15px",
                                    display: "flex",
                                    gap: "5px",
                                    marginBottom: "5px",
                                    width: "100%",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={(e) => handleCloseJob(job)}
                                >
                                  <Close />
                                  <Typography>Close Job</Typography>
                                </Box> */}
                              </Box>
                            </Box>
                          </Box>

                          {job.role_id && (
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>
                                {allRoles.find((ele) => ele.id === job.role_id).name} | {allSpecializations.find((ele) => ele.id === job.spe_id).name}
                              </Typography>
                            </Box>
                          )}
                          <Typography sx={{ color: "#8B8B8B", fontSize: "14px" }}>Posted on: {time.convertEpochToDate(job.job_published_time)}</Typography>

                          <Typography
                            sx={{ color: "#165983", fontSize: "16px", textDecoration: "underline", "&:hover": { cursor: "pointer" } }}
                            onClick={() => {
                              if (detailJobs === job.id) {
                                setDetailsJob(null);
                              } else {
                                setDetailsJob(job.id);
                              }
                            }}
                          >
                            {job.id === detailJobs ? "Hide Details" : "Show details"}
                          </Typography>
                          {detailJobs === job.id && (
                            <Box>
                              <Typography sx={{ fontSize: "24px", marginBottom: "16px" }}>Recruitment Process</Typography>
                              <Box sx={{ display: "flex" }}>
                                {/* 01 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "applications", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Applicants</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "applications", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Applicants</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Applicants</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 02 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "test", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>02</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Assessment Test</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "test", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>02</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Assessment Test</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>01</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Assessment Test</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 03 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "shortlisted", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>03</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Shortlisted Candidate</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "shortlisted", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>03</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Shortlisted Candidate</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>03</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Shortlisted Candidate</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 04 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "interview", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>04</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Schedule Interview</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "interview", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>04</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Schedule Interview</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>04</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Schedule Interview</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ height: "1px", width: "250px", bgcolor: "#165983", marginTop: "10px" }}></Box>

                                {/* 05 */}
                                {getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "hiring", job) === "On going" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#D0DEE6", border: "1px solid #165983", padding: "2px" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>05</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Final Hiring</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#F5A536" }}>Ongoing</span>
                                    </Typography>
                                  </Box>
                                ) : getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "hiring", job) === "Completed" ? (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", background: "#165983" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>05</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Final Hiring</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span style={{ color: "#1EBE70" }}>Completed</span>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid #D0DEE6" }}></Box>
                                    <Typography sx={{ fontSize: "31px", fontWeight: "700" }}>05</Typography>
                                    <Typography sx={{ fontSize: "16px" }}>Final Hiring</Typography>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                                      Status: <span>Not Started</span>
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                              {["On going", "Completed"].includes(getStatus(job.job_published_time, job.list_timing, job.closed_timing, job.test_timing, job.job_accepted_time, "shortlisted", job)) && (
                                <Box sx={{ display: "flex", justifyContent: "space-between", paddingInline: "4%" }}>
                                  <Box sx={{ flex: 1 }}>
                                    <Button sx={{ border: "1px solid #E8E8E8", borderRadius: "20px", color: "#165983", "&:hover": { color: "#165983" }, textTransform: "none", padding: "5px 15px", fontSize: "14px", width: "fit-content" }} onClick={() => navigate(`/Recruiter/Shortlist?job_id=${job.id}`)} disabled={true}>
                                      View Applicants
                                    </Button>
                                  </Box>
                                  <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
                                    <Button sx={{ border: "1px solid #165983", borderRadius: "20px", color: "#165983", "&:hover": { color: "#165983" }, textTransform: "none", padding: "5px 15px", fontSize: "14px", width: "fit-content" }} onClick={() => navigate(`/Recruiter/Shortlist?job_id=${job.id}`)}>
                                      View List
                                    </Button>
                                  </Box>
                                  <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                                    <Button sx={{ mr: "4%", border: "1px solid #165983", borderRadius: "20px", color: "#165983", "&:hover": { color: "#165983" }, textTransform: "none", padding: "5px 15px", fontSize: "14px", width: "fit-content" }} onClick={() => setHiredModal(true)}>
                                      View Hired
                                    </Button>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      );
                    }
                  })
                ) : (
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                    <img src="https://du03hetco8xdw.cloudfront.net/recruiter/nojob.svg" style={{ width: "50vw", height: "50vh" }} alt="No Jobs Available" />
                    <Typography>
                      Looks like you <span style={{ color: "#165983" }}>don't have any Job</span>
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        </Box>

        <Modal open={viewPostModal} onClose={() => setViewPostModal(false)} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
          <ViewPost jobDetails={allJobs} close={() => setViewPostModal(false)} allRoles={allRoles} allSpecializations={allSpecializations} />
        </Modal>

        <Modal open={deleteModal} onClose={() => setDeleteModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ width: "600px", height: "250px", background: "#fff", padding: "20px", borderRadius: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
              <Typography sx={{ fontSize: "24px" }}>
                Are you sure you would like to <span style={{ color: "red" }}>delete</span>?
              </Typography>
              <IconButton onClick={() => setDeleteModal(false)} sx={{ "&:hover": { cursor: "pointer" } }}>
                <Close sx={{ fontSize: "30px" }} />
              </IconButton>
            </Box>
            <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>All you progress in your job posting will be deleted. Are you sure you would like to continue?</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center" }}>
              <Button sx={{ color: "#fff", border: "none", background: "#165983", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", width: "150px", "&:hover": { background: "#165983", color: "#fff" } }} onClick={() => setDeleteModal(false)}>
                Go Back
              </Button>
              <Button sx={{ color: "#FF0E0E", border: "1px solid #FF0E0E", background: "rgba(255, 14, 14, 0.10)", padding: "8px 15px", borderRadius: "15px", textTransform: "none", width: "150px", alignSelf: "center", "&:hover": { background: "rgba(255, 14, 14, 0.10)", color: "#FF0E0E" } }} onClick={() => handleDelete()}>
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={hiredModal} onClose={() => setHiredModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <HiredModal close={() => setHiredModal(false)} job_id={detailJobs} job={allJobs.find((job) => job.id === detailJobs)} />
        </Modal>
      </Box>
    </Box>
  );
}

export default RecruiterJobs;
