import { Box, IconButton, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { socket } from "../../../Socket";
import TimeService from "../../../exportedFunctions/epochTime";
import { useDispatch, useSelector } from "react-redux";
import Service from "../../../services/httpService";
import { CalendarTodayTwoTone, Chat, Close, Send } from "@mui/icons-material";
import { setChatPage } from "../../../store/reducers";
import ScheduleMeeting from "./ScheduleMeeting";
import { AnimatePresence, motion } from "framer-motion";
function NewChatPage() {
  const dispatch = useDispatch();
  const services = new Service();
  const [allChats, setAllChats] = useState([]);
  const [textValue, setTextValue] = useState("");
  const { user_id, chat_id } = useSelector((store) => store.user);
  const [messages, setMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const chat_section = useRef(null);
  const textBoxRef = useRef(null);
  const time = new TimeService();
  const [onExit, setOnexit] = useState(false);
  const [rescheduleMeeting, setRescheduleMeeting] = useState(false);
  useEffect(() => {
    const getChatData = async () => {
      const chatData = await services.get("/jobs/recruiter/inbox");
      let temp_chat_data = chatData.data.map((item) => {
        return {
          ...item,
          id: item.student_job_map_id,
        };
      });
      if (![null, undefined].includes(chat_id)) {
        let data = temp_chat_data.find((item) => item.student_job_map_id === chat_id);

        setSelectedChat(data);
      }
      setAllChats(temp_chat_data);
    };

    getChatData();
  }, []);

  useEffect(() => {
    if (chat_section.current) {
      chat_section.current.scrollTop = chat_section.current.scrollHeight;
    }
  }, [messages, chat_section]);

  useEffect(() => {
    if (selectedChat !== null) {
      socket.connect();

      socket.emit("findJobRoom", { student_job_map_id: selectedChat.student_job_map_id }, () => {});

      return () => {
        socket.disconnect();
      };
    }
  }, [selectedChat]);

  useEffect(() => {
    function receiveMessage(value) {
      setMessages(value);
    }
    socket.on("receiveJobMessage", receiveMessage);

    return () => {
      socket.off("receiveJobMessage", receiveMessage);
    };
  }, [messages]);

  useEffect(() => {
    function foundPreviousMessages(value) {
      value.chat?.messages ? setMessages(value.chat.messages) : setMessages([]);
      setLoading(false);
    }
    socket.on("foundJobRoom", foundPreviousMessages);

    return () => {
      socket.off("foundJobRoom", foundPreviousMessages);
    };
  }, []);

  useEffect(() => {
    if (textBoxRef.current) {
      textBoxRef.current.scrollTop = textBoxRef.current.scrollHeight;
    }
  }, [textValue]);
  const handleMessageSend = async () => {
    if (!["", null].includes(textValue)) {
      const data = {
        text: textValue,
        user_id: user_id,
        student_job_map_id: selectedChat.student_job_map_id,
      };
      socket.emit("sendJobMessage", data);
      setTextValue("");
    }
  };

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      handleMessageSend();
    }
  };

  return (
    <motion.div style={{ position: "absolute", background: "#fff", borderRadius: "20px" }} transition={{ duration: 0.3, type: "spring", delay: 0.3 }} initial={{ width: 0, height: 0, x: "100vw", y: "100vh", opacity: 0 }} animate={!onExit ? { width: "50vw", height: "70vh", x: "25vw", y: "15vh", opacity: 1 } : { width: 0, height: 0, x: "100vw", y: "100vh", opacity: 0 }}>
      {allChats.length > 0 ? (
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%", width: "30%", background: "#F7F7F7", borderRadius: "20px 0px 0px 20px", boxShadow: "-14px 0px 46.5px 0px rgba(0, 0, 0, 0.03) inset" }}>
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center", flex: 1, marginBottom: "20px", paddingLeft: "10px" }}>
                <Typography sx={{ fontSize: "24px" }}>All Chats </Typography>
              </Box>
              <Box
                sx={{
                  flex: 9,
                  overflowY: "auto",
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                    height: "30%",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#165983",
                    borderRadius: "15px",
                    backgroundClip: "padding-box",
                  },
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track-piece:start": {
                    marginTop: "10px",
                  },
                  "&::-webkit-scrollbar-track-piece:end": {
                    marginBottom: "10px",
                  },
                }}
              >
                {allChats.length > 0 ? (
                  <Box sx={{ display: "flex", flexDirection: "column", "&:hover": { cursor: "pointer" } }}>
                    {allChats.map((item) => (
                      <Box onClick={() => setSelectedChat(item)} sx={{ background: selectedChat === null ? "" : selectedChat.student_job_map_id === item.student_job_map_id ? "#fff" : "", padding: "10px", borderBottom: "1px solid #8B8B8B" }}>
                        <Typography sx={{ fontSize: "16px", color: "#165983" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "14px", color: "#8B8B8B" }}>
                          {item.designation} | {item.job_type_name === "FULL-TIME" ? "Full time" : item.job_type_name === "SUMMER INTERNSHIP" ? "Summer Intern" : "Live Project Intern"}
                        </Typography>

                        <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                          <CalendarTodayTwoTone sx={{ fontSize: "16px", color: "#165983" }} />
                          <Typography sx={{ fontSize: "12px", color: "#8B8B8B" }}>{time.convertEpochToDate(parseInt(item.interview_time))}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box>
                    <Chat sx={{ color: "#165983", fontSize: "4rem" }} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box sx={{ height: "100%", width: "70%" }}>
            <Box sx={{ height: "100%" }}>
              {selectedChat === null ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "100%" }}>
                  <Chat sx={{ color: "#165983", fontSize: "4rem" }} />
                  <Typography sx={{ fontSize: "24px", color: "#8B8B8B" }}>Send and Receive message here</Typography>
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                  <Box sx={{ flex: 1.15, background: "#F7F7F7", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: "0px 20px 0px 0px", paddingInline: "10px" }}>
                    <Box>
                      <Typography sx={{ color: "#165983", fontSize: "14px" }}>
                        {selectedChat.student_name} | {selectedChat.college}{" "}
                      </Typography>
                      <Typography sx={{ color: "#8B8B8B", fontSize: "12px" }}>
                        Interview Scheduled: {time.convertEpochToDate(parseInt(selectedChat.interview_time))} | {time.convertEpochToTime(parseInt(selectedChat.interview_time))}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton sx={{ background: "#fff", color: "#165983", marginInline: 2 }} onClick={() => setRescheduleMeeting(true)}>
                        <CalendarTodayTwoTone fontSize="20px" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setOnexit(true);
                          setTimeout(() => {
                            dispatch(
                              setChatPage({
                                chatPage: false,
                              })
                            );
                          }, 500);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box
                    ref={chat_section}
                    sx={{
                      flex: 6,
                      overflowY: "scroll",
                      padding: "5px 10px 10px 10px",
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                        borderRadius: "15px",
                        height: "30%",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#165983",
                        borderRadius: "15px",
                        backgroundClip: "padding-box",
                      },
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-track-piece:start": {
                        marginTop: "10px",
                      },
                      "&::-webkit-scrollbar-track-piece:end": {
                        marginBottom: "10px",
                      },
                    }}
                  >
                    {messages.length > 0 ? (
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        {messages.map((message) => (
                          <div style={{ display: "flex", justifyContent: message.send_by === user_id ? "end" : "" }}>
                            <div style={{ padding: "10px", borderRadius: "10px", border: "1px solid #CCCCCC", maxWidth: "60%", background: message.send_by === user_id ? "linear-gradient(0deg, rgba(22, 89, 131, 0.20) 0%, rgba(22, 89, 131, 0.20) 100%), #FFF" : "" }}>
                              <Typography sx={{ fontSize: "12px" }}>{message.text}</Typography>

                              <Typography sx={{ fontSize: "9px", color: "#8B8B8B", textAlign: "right" }}>
                                {time.convertEpochToDateMonth(message.timestamp)}, {time.convertEpochToTime(message.timestamp)}
                              </Typography>
                            </div>
                          </div>
                        ))}
                      </Box>
                    ) : (
                      <Box sx={{ paddingInline: "15%", display: "flex", alignItems: "flex-end", height: "100%" }}>
                        <Typography sx={{ background: "#E6E6E6", padding: "8px 20px", textAlign: "center", borderRadius: "12px", flex: 1, fontSize: "16px" }}>Send a message to start chatting now</Typography>
                      </Box>
                    )}
                  </Box>

                  <Box
                    ref={textBoxRef}
                    sx={{
                      flex: 1,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      gap: "5px",
                      alignItems: "center",
                      paddingInline: "10px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <Box
                      ref={textBoxRef}
                      sx={{
                        paddingBlock: "5px 10px",
                        width: "100%",
                        overflowY: "auto",
                        maxHeight: "100%",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      <TextField
                        onChange={(e) => handleTextChange(e)}
                        autoFocus={true}
                        onKeyDown={(e) => handleKeyDown(e)}
                        fullWidth
                        multiline
                        variant="outlined"
                        sx={{
                          fontSize: "16px",
                          color: "#545454",
                          borderRadius: "15px 15px 15px 15px",

                          border: "1.5px solid #165983",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        InputProps={{
                          sx: {
                            padding: "10px",
                            borderRadius: "30px",
                            // boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                            fontSize: "12px",
                          },
                        }}
                        value={textValue}
                      />
                    </Box>

                    <IconButton sx={{ background: "linear-gradient(0deg, rgba(22, 89, 131, 0.10) 0%, rgba(22, 89, 131, 0.10) 100%), #FFF", padding: "10px", width: "fit-content" }} onClick={() => handleMessageSend()}>
                      <Send sx={{ color: "#165983", fontSize: "20px" }} />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center", height: "100%", borderRadius: "20px" }}>
          <Chat sx={{ color: "#165983", fontSize: "6rem" }} />
          <Typography>No chats available</Typography>
        </Box>
      )}

      <Modal open={rescheduleMeeting}>
        <ScheduleMeeting student={selectedChat} close={() => setRescheduleMeeting(false)} />
      </Modal>
    </motion.div>
  );
}

export default NewChatPage;
