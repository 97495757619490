import React, { useState } from "react";

import { motion } from "framer-motion";
import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
const fontSize = "14px";
function JobTypes({ close, tab }) {
  const [onExit, setOnexit] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tab);

  return (
    <motion.div
      style={{
        position: "absolute",
        background: "#fff",
        borderRadius: "20px",
        padding: "20px",
      }}
      transition={{ duration: 0.3, type: "spring", delay: 0.3 }}
      initial={{ width: 0, height: 0, x: "100vw", y: "0", opacity: 0 }}
      animate={!onExit ? { width: "60vw", height: "80vh", x: "20vw", y: "10vh", opacity: 1 } : { width: 0, height: 0, x: "100vw", y: "100vh", opacity: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          paddingRight: "10px",
          flexDirection: "column",
          gap: "20px",
          height: "100%",
          overflowY: "scroll",
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "15px",
            height: "30%",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#165983",
            borderRadius: "15px",
            backgroundClip: "padding-box",
          },
          "&::-webkit-scrollbar": {
            // display: "none",

            width: "4px",
          },
          "&::-webkit-scrollbar-track-piece:start": {
            marginTop: "10px",
          },
          "&::-webkit-scrollbar-track-piece:end": {
            marginBottom: "10px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
          <motion.div style={{ background: "rgba(255, 213, 207, 1)", width: "calc((60vw - 6rem) / 3)", height: "40px", zIndex: 1, position: "absolute", borderRadius: "20px", border: "1px solid rgba(255, 14, 14, 1)" }} initial={{ x: 0 }} animate={{ x: `calc(((60vw - 5rem)/3)*${selectedTab})` }} transition={{ duration: 0.3, type: "spring" }}></motion.div>

          <Typography
            sx={{ zIndex: 999, textAlign: "center", width: "100%", fontSize: "14px", paddingBlock: "10px", "&:hover": { cursor: "pointer" } }}
            onClick={() => {
              setSelectedTab(0);
            }}
          >
            Live Project Intern
          </Typography>
          <Typography
            sx={{ zIndex: 999, textAlign: "center", width: "100%", fontSize: "14px", paddingBlock: "10px", "&:hover": { cursor: "pointer" } }}
            onClick={() => {
              setSelectedTab(1);
            }}
          >
            Summer Internship
          </Typography>
          <Typography
            sx={{ zIndex: 999, textAlign: "center", width: "100%", fontSize: "14px", paddingBlock: "10px", "&:hover": { cursor: "pointer" } }}
            onClick={() => {
              setSelectedTab(2);
            }}
          >
            Full time Employee
          </Typography>
          <IconButton
            width={"2.5rem"}
            sx={{ marginLeft: "0.5rem" }}
            onClick={() => {
              setOnexit(true);
              setTimeout(() => {
                close();
              }, 400);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        {selectedTab === 0 ? <RenderLiveProject /> : selectedTab === 1 ? <RenderSummerIntern /> : <RenderFullTime />}
      </Box>
    </motion.div>
  );
}

const RenderLiveProject = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box>
        <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>What is Live Project?</Typography>
        <Typography sx={{ fontSize: fontSize }}>MBA students are allowed to work on live projects with companies/startups to enhance their MBA learning. They will work, remotely from their campus, alongside their regular academic and co-curricular schedule. You can expect each student to provide 10-12 hours per week</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Mode: </span>Remote/Online
        </Typography>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Expected Compensation: </span>INR 0 - INR 5000 per month
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Usual time commitment: </span>10-12 hours/week&nbsp;
        </Typography>
        <Box sx={{ display: "flex", flex: 1 }}>
          <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>Availability: </Typography>
          <Typography sx={{ fontSize: fontSize }}>&nbsp;1st Year- Jul to Mar, 2nd Year- Jul to Jan</Typography>
        </Box>
      </Box>

      <Typography sx={{ fontSize: fontSize }}>
        <span style={{ fontWeight: 600 }}>Who will apply?: </span> 1st year students show maximum interest. If the project is complex/substantial 2nd years will be interested
      </Typography>

      <Typography sx={{ fontSize: fontSize }}>
        <span style={{ fontWeight: 600 }}>Project Tenure: </span> Min: 1 month, Max: Can be ongoing work on one/mutiple projects.&nbsp;Many students work for 2-6 months if projects are MBA worthy and there is compensation
      </Typography>

      <Box sx={{ border: "1px solid rgba(22, 89, 131, 1)", borderRadius: "20px", padding: "20px" }}>
        <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>Pro hiring tips for you:</Typography>
        <Typography sx={{ fontSize: fontSize }}>Finish hiring Summer Interns early (anywhere between Sep-Dec) for next year's Apr - Jul. For all selected interns, make Live Project mandatory from the time of confirmation till the internship join date. All selected candidates would have then worked for 2-4 months before joining as Summer Intern. A lot of work and tuning to company work culture can get done during this period. Once they join as summer interns, you can identify the most suitable candidates to roll out Pre-Placement Offers (PPO). This ensures the best full-time recruits, whom you can expect to deliver results from day 1. Hire at least 3 Summer Interns for every full-time hire you have in mind. Assume a few may not join (for both Summer Internship and full-time roles) for reasons beyond your control.</Typography>
      </Box>
    </Box>
  );
};

const RenderSummerIntern = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box>
        <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>What is Summer Internship?</Typography>
        <Typography sx={{ fontSize: fontSize }}>All MBA students have to undergo a mandatory 2-3 months full-time Summer Internship between April and August, between their 1st and 2nd year of MBA. Exact month varies from college to college. It is good to float opportunities as early as possible</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Mode: </span>Strictly In-office
        </Typography>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Usual time commitment:</span>40+ hours/week
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flex: 1 }}>
          <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>Expected Compensation: </Typography>
          <Typography sx={{ fontSize: fontSize }}>Min: INR 10,000 per month</Typography>
        </Box>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Who will apply?: </span>1st year students before starting 2nd year
        </Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Project Tenure: </span>Usually 2-3 months
        </Typography>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Availability: </span>April to August
        </Typography>
      </Box>

      <Box sx={{ border: "1px solid rgba(22, 89, 131, 1)", borderRadius: "20px", padding: "20px", marginTop: "20px" }}>
        <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>Pro hiring tips for you:</Typography>
        <Typography sx={{ fontSize: fontSize }}>Finish hiring Summer Interns early (anywhere between Sep-Dec) for next year's Apr - Jul. For all selected interns, make Live Project mandatory from the time of confirmation till the internship join date. All selected candidates would have then worked for 2-4 months before joining as Summer Intern. A lot of work and tuning to company work culture can get done during this period. Once they join as summer interns, you can identify the most suitable candidates to roll out Pre-Placement Offers (PPO). This ensures the best full-time recruits, whom you can expect to deliver results from day 1. Hire at least 3 Summer Interns for every full-time hire you have in mind. Assume a few may not join (for both Summer Internship and full-time roles) for reasons beyond your control.</Typography>
      </Box>
    </Box>
  );
};

const RenderFullTime = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box>
        <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>What is Full-time employment?</Typography>
        <Typography sx={{ fontSize: fontSize }}>After completing MBA program, students look for full time working opportunities. Placements in campus usually takes place from Sep-Dec and students join these companies anywhere between Mar to Jun depending on the course curriculum and timeline. It is advisable to float opportunties as early as possible to find the best talent</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Mode: </span>Strictly In-office
        </Typography>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Usual time commitment:</span>40+ hours/week
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flex: 1 }}>
          <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>Expected Compensation: </Typography>
          <Typography sx={{ fontSize: fontSize }}>Min: INR 40,000 per month</Typography>
        </Box>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Who will apply?: </span> 2nd year student after program completion
        </Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Project Tenure: </span>Like any full time job
        </Typography>
        <Typography sx={{ fontSize: fontSize, flex: 1 }}>
          <span style={{ fontWeight: 600 }}>Availability: </span>Most students are available from mid to end March
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid rgba(22, 89, 131, 1)", borderRadius: "20px", padding: "20px", marginTop: "20px" }}>
        <Typography sx={{ fontSize: fontSize, fontWeight: 600 }}>Pro hiring tips for you:</Typography>
        <Typography sx={{ fontSize: fontSize }}>Finish hiring Summer Interns early (anywhere between Sep-Dec) for next year's Apr - Jul. For all selected interns, make Live Project mandatory from the time of confirmation till the internship join date. All selected candidates would have then worked for 2-4 months before joining as Summer Intern. A lot of work and tuning to company work culture can get done during this period. Once they join as summer interns, you can identify the most suitable candidates to roll out Pre-Placement Offers (PPO). This ensures the best full-time recruits, whom you can expect to deliver results from day 1. Hire at least 3 Summer Interns for every full-time hire you have in mind. Assume a few may not join (for both Summer Internship and full-time roles) for reasons beyond your control.</Typography>
      </Box>
    </Box>
  );
};

export default JobTypes;
