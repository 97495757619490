import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Service from "../../services/httpService";

function ForgotPassword() {
  const service = new Service();
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState("");

  const [error, setError] = useState("");
  const [resend, setResend] = useState(false);
  const [responseError, setResponseError] = useState("");
  const handleResend = async () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regex.test(value)) {
      const body = {
        email: value,
        signup_platform: "CareerCarve",
      };
      try {
        const request = await service.post(`/forgotPassword`, body);

        setResend(true);
        setDisabled(true);
      } catch (e) {
        if (e.response.data.message === "URL already sent") {
          setResend(true);
        }
        setResponseError(e.response.data.message);
      }
    } else {
      setError("Enter a valid email address");
    }
  };

  const handleResendRequest = async () => {
    try {
      const body = {
        email: value,
        signup_platform: "CareerCarve",
      };
      const request = await service.post(`/resendForgetPassword`, body);

      setResend(true);
      setDisabled(true);
    } catch (e) {
      window.alert(e.response.data.message);
    }
  };
  return (
    <Box sx={{ display: "flex", gap: "2rem", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          width: "100%",
          backgroundImage: "url('https://du03hetco8xdw.cloudfront.net/recruiter/cc_login_bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png"
          alt="background"
          style={{
            height: "150px",
            width: "150px",
            // top: "max(30%,200px)",
            // left: "20%",
          }}
        />
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/CareerCarve.png"
          alt="background"
          style={{
            height: "3rem",
            width: "15rem",
            // top: "max(52%,350px)",
            // left: "13%",
          }}
        />
        <p
          style={{
            top: "max(62%, 430px)",

            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>
      {!resend ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            alignItems: "center",
            gap: "2rem",
            padding: "0px 8rem",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "36px", textAlign: "center" }}>Forgot your Password?</Typography>
            <Typography sx={{ fontSize: "16px", color: "#8B8B8B", textAlign: "center", paddingInline: "15%" }}>Enter your email id and we shall send you a verification link to reset your password</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <TextField
              required
              label="Email"
              sx={{ width: "300px" }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setError("");
              }}
              error={error === "" ? false : true}
              helperText={error}
            />
            <Typography sx={{ color: "red", fontSize: "14px", textAlign: "center" }}>{responseError}</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", justifyContent: "center" }}>
              <Button sx={{ background: "linear-gradient(99deg, #2AA8C4 -9.01%, #38C1DF 103.32%)", border: "none", color: "#fff", alignSelf: "center", "&:hover": { cursor: "pointer", background: "linear-gradient(99deg, #2AA8C4 -9.01%, #38C1DF 103.32%)", color: "#fff" } }} onClick={() => handleResend()}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            padding: "0px 8rem",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "36px", textAlign: "center" }}>Reset Password</Typography>
            <Typography sx={{ fontSize: "16px", color: "#8B8B8B", textAlign: "center", paddingInline: "15%" }}>To login to your account, follow the link sent to your mail to reset your password</Typography>
          </Box>
          <Box>
            <Typography sx={{ color: "red", fontSize: "14px", textAlign: "center" }}>{responseError}</Typography>

            <Typography sx={{ fontSize: "16px", color: "#545454", textAlign: "center" }}>Didn't receive the mail?</Typography>
            <Typography sx={{ fontSize: "16px", color: "#165983", textAlign: "center", textDecoration: "underline", "&:hover": { cursor: "pointer" } }} onClick={() => handleResendRequest()}>
              Resend
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ForgotPassword;
