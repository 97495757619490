import React, { useState, useEffect } from "react";

const AddEventButton = () => {
  const CLIENT_ID = "993407988626-g5m9kf82mo7v8gbbfm95bj3npnk69lgn.apps.googleusercontent.com";
  const API_KEY = "AIzaSyBtEtrUYPHT2Q0cGcCvinLuQiB2h8pZ55Y";
  const DISCOVERY_DOC = "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
  const SCOPES = "https://www.googleapis.com/auth/calendar";
  let tokenClient;
  let gapiInited = false;
  let gisInited = false;

  const [authorizeButtonVisibility, setAuthorizeButtonVisibility] = useState("hidden");
  const [signoutButtonVisibility, setSignoutButtonVisibility] = useState("hidden");

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.async = true;
    script1.defer = true;
    script1.src = "https://apis.google.com/js/api.js";
    script1.onload = gapiLoaded;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.async = true;
    script2.defer = true;
    script2.src = "https://accounts.google.com/gsi/client";
    script2.onload = gisLoaded;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  const gapiLoaded = () => {
    window.gapi.load("client", initializeGapiClient);
  };

  const initializeGapiClient = async () => {
    await window.gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;
    maybeEnableButtons();
  };

  const gisLoaded = () => {
    tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: () => {}, // Add a dummy function for now, you can replace it with your actual callback logic
    });
    gisInited = true;
    maybeEnableButtons();
  };

  const maybeEnableButtons = () => {
    if (gapiInited && gisInited) {
      setAuthorizeButtonVisibility("visible");
    }
  };

  const handleAuthClick = () => {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        console.log("resp.error", resp.error);
        throw resp;
      }
      setSignoutButtonVisibility("visible");
      // Update the state if needed
      // setAuthorizeButtonText('Refresh');
      await listUpcomingEvents();
    };

    if (window.gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      tokenClient.requestAccessToken({ prompt: "" });
    }
  };

  const handleSignoutClick = () => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      window.google.accounts.oauth2.revoke(token.access_token);
      window.gapi.client.setToken("");
      // Update the state if needed
      // setAuthorizeButtonText('Authorize');
      setSignoutButtonVisibility("hidden");
    }
  };

  const listUpcomingEvents = async () => {
    let response;
    try {
      const request = {
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      };
      const event = {
        summary: "Google I/O 2015",
        location: "800 Howard St., San Francisco, CA 94103",
        description: "A chance to hear more about Google's developer products.",
        start: {
          dateTime: new Date(),
          timeZone: "Asia/Kolkata",
        },
        end: {
          dateTime: new Date(),
          timeZone: "Asia/Kolkata",
        },
        // attendees: [{ email: "anisk@careercarve.com" }],
      };

      const newRequest = window.gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
      });

      newRequest.execute(function (event) {
        console.log("Event created: " + event.htmlLink);
      });

      response = await window.gapi.client.calendar.events.list(request);
    } catch (err) {
      // Handle error, update state if needed
      console.error(err);
      return;
    }

    const events = response.result.items;
    if (!events || events.length === 0) {
      // Update state if needed
      console.log("No events found.");
      return;
    }

    // Update state with events if needed
    console.log("All events found", events);
    const output = events.reduce((str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`, "Events:\n");
    console.log(output);
  };

  return (
    <div>
      <button id="authorize_button" style={{}} onClick={() => handleAuthClick()}>
        Sync to calender
      </button>
      <pre id="content" style={{ whiteSpace: "pre-wrap" }}></pre>
    </div>
  );
};

export default AddEventButton;
