import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { Close, ContentCopy } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

function MeetingInfo({ meetingLink, close }) {
  const linkRef = useRef(null);
  const [copyText, setCopyText] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(linkRef.current.innerText);
    setCopyText(true);
  };

  useEffect(() => {
    if (copyText) {
      setInterval(() => {
        setCopyText(false);
      }, 2000);
    }
  }, [copyText]);
  return (
    <>
      <Box sx={{ background: "#fff", display: "flex", flexDirection: "column", gap: "20px", width: "40%", height: "auto", borderRadius: "20px", padding: "40px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "24px" }}>Meeting Info</Typography>
          <IconButton onClick={() => close()}>
            <Close sx={{ fontSize: "24px" }} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <a href={meetingLink} target="_blank" style={{ textDecoration: "none" }}>
            <Typography sx={{ color: "#165983", fontSize: "16px" }} ref={linkRef}>
              {meetingLink}
            </Typography>
          </a>
          <IconButton onClick={() => handleCopy()}>
            <ContentCopy sx={{ fontSize: "24px", "&:hover": { color: "#165983" } }} />
          </IconButton>
        </Box>
        <Box>
          <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>Click on the link to join the meeting. Additionally, you can copy the link to send it to other people to invite them to this meeting.</Typography>
        </Box>
        <Button sx={{ color: "#165983", background: "#fff", padding: "3px 15px", borderRadius: "15px", textTransform: "none", width: "150px", textDecoration: "underline", alignSelf: "center" }} onClick={() => close()}>
          Go Back
        </Button>
      </Box>

      <Box sx={{ background: "#1D1D1D", height: "30px", color: "#fff", zIndex: "999", position: "absolute", bottom: 30, right: "42%", borderRadius: "10px", display: copyText ? "" : "none" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px 30px" }}>
          <Typography sx={{ color: "#fff" }}>Copied to clipboard</Typography>
        </Box>
      </Box>
    </>
  );
}

export default MeetingInfo;
