import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import Service from "../../../services/httpService";

function AbsentModal({ absentList, close, allShortlistedStudent, updateShortListedStudent }) {
  const services = new Service();
  const handleMarkAbsent = async () => {
    try {
      let ids = absentList.map((item) => item.id);
      let temp = allShortlistedStudent.map((item) => {
        if (ids.includes(item.id)) {
          return {
            ...item,
            status_name: "Interview missed",
            checked: false,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      await services.put(`/jobs/recruiter/interviewMissed?id=${ids.join(",")}`);
      updateShortListedStudent(temp);
      close();
    } catch (error) {}
  };
  return (
    <Box sx={{ background: "#fff", display: "flex", flexDirection: "column", gap: "20px", width: "50%", height: "auto", borderRadius: "20px", padding: "40px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>
          Are you sure you would like to <span style={{ color: "#FF0E0E" }}>Mark Student(s) Absent</span>?
        </Typography>
        <IconButton onClick={() => close()} sx={{ height: "100%" }}>
          <Close sx={{ fontSize: "24px" }} />
        </IconButton>
      </Box>
      <Box>
        <Typography sx={{ textAlign: "center" }}>You have selected the following students to be marked absent:</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {absentList.map((absent_student) => (
            <Box sx={{ background: "#FAFAFA", padding: "10px" }}>
              <Typography sx={{ color: "#165983", fontSize: "16px", textAlign: "center" }}>{absent_student.student_name}</Typography>
              <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>{absent_student.college_name}</Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>
            Once you mark these students as absent, they will be replaced by new candidates. The new candidates will be sent along with the final Shortlist Set (List 03).
            <br />
            Are you sure you would like to mark them absent?
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button sx={{ color: "#FF0E0E", background: "rgba(255, 14, 14, 0.10)", border: "1px solid #FF0E0E", padding: "8px 15px", width: "fit-content", borderRadius: "15px", marginBlock: "20px", alignSelf: "center", "&:hover": { color: "#FF0E0E", background: "rgba(255, 14, 14, 0.10)" } }} onClick={() => handleMarkAbsent()}>
            Mark Absent
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button sx={{ color: "#165983", background: "#fff", padding: "3px 15px", borderRadius: "15px", textTransform: "none", width: "150px", textDecoration: "underline", alignSelf: "center" }} onClick={() => close()}>
            Go Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AbsentModal;
