import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CalendarMonth, GridView, HeadsetMic, Logout, PersonSearch, WorkOutline, AccountCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Service from "../../services/httpService";
import Cookies from "js-cookie";
// import { getAuth, signOut } from "firebase/auth";
function RecruiterSidenavbar() {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const services = new Service();

  const handleJobPost = async () => {
    // const postJob = await services.post("/jobs/recruiter/jobTable");
    navigate("/Jobs", { state: { job_id: null } });
  };

  const handleLogout = (e) => {
    Cookies.remove("token");

    navigate("/");

    // const auth = getAuth();
    //
    // auth
    //   ?.signOut()
    //   .then((user) => {
    //
    //     navigate("/");
    //   })
    //   .catch((error) => {
    //
    //   });
  };
  return (
    <Box
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      sx={{
        boxShadow: "1px 0px 61px 0px rgba(0, 0, 0, 0.08)",
        display: "flex",
        flexDirection: "column",
        zIndex: "999",
        transition: "width 0.2s ease",
        width: isHovered ? "15%" : "5%",
        height: "100%",
        position: "fixed",
        backgroundColor: "#fff",
        minWidth: "30px",
      }}
    >
      {isHovered ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2em",
            paddingInline: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png" style={{ width: "30px", height: "40px", marginBlock: "1.5rem" }} />
            <img src="https://du03hetco8xdw.cloudfront.net/recruiter/CareerCarve.png" style={{ width: "120px", height: "30px", marginBlock: "1.5rem" }} />
          </Box>
          <Box
            sx={{
              background: "#D0DEE6",
              borderRadius: "20px",
              padding: "10px 20px",
              color: "#165983",
              border: "1px solid #165983",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleJobPost()}
          >
            <Typography
              sx={{
                fontSize: "16px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              + Post a Job
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              background: location.pathname === "/Recruiter" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
              color: "#8B8B8B",
              "&:hover": {
                color: "#000",
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/Recruiter")}
          >
            <GridView sx={{ color: "#8B8B8B" }} />
            <Typography sx={{ fontSize: "16px" }}>Dashboard</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              background: location.pathname === "/Recruiter/Jobs" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Jobs" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
              color: "#8B8B8B",
              "&:hover": {
                color: "#000",
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/Recruiter/Jobs")}
          >
            <WorkOutline sx={{ color: "#8B8B8B" }} />

            <Typography sx={{ fontSize: "16px" }}>Jobs</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              background: location.pathname === "/Recruiter/Shortlist" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Shortlist" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
              color: "#8B8B8B",
              "&:hover": {
                color: "#000",
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/Recruiter/Shortlist")}
          >
            {" "}
            <PersonSearch sx={{ color: "#8B8B8B" }} />
            <Typography sx={{ fontSize: "16px" }}>Shortlisted</Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              gap: "10px",
              background: location.pathname === "/Recruiter/Schedule" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Schedule" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",

              color: "#8B8B8B",
              "&:hover": {
                color: "#000",
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/Recruiter/Schedule")}
          >
            {" "}
            <CalendarMonth sx={{ color: "#8B8B8B" }} />
            <Typography
              sx={{
                fontSize: "16px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Your Schedule
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              background: location.pathname === "/Profile" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Profile" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
              color: "#8B8B8B",
              "&:hover": {
                color: "#000",
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/Profile")}
          >
            {" "}
            <AccountCircle sx={{ color: "#8B8B8B" }} />
            <Typography sx={{ fontSize: "16px" }}>Profile</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              background: location.pathname === "/Recruiter/Support" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Support" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
              color: "#8B8B8B",
              "&:hover": {
                color: "#000",
                cursor: "pointer",
              },
            }}
            onClick={() => handleLogout()}
          >
            {" "}
            <Logout sx={{ color: "#8B8B8B" }} />
            <Typography sx={{ fontSize: "16px" }}>Logout</Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png" style={{ width: "50px", height: "100%", marginBlock: "1rem" }} />
          <Box
            sx={{
              background: "#D0DEE6",
              borderRadius: "50%",
              padding: "10px 15px",
              color: "#165983",
              border: "1px solid #165983",
            }}
          >
            +
          </Box>
          <Box
            sx={{
              background: location.pathname === "/Recruiter" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <GridView sx={{ color: "#8B8B8B" }} />
          </Box>
          <Box
            sx={{
              background: location.pathname === "/Recruiter/Jobs" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Jobs" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <WorkOutline sx={{ color: "#8B8B8B" }} />
          </Box>
          <Box
            sx={{
              background: location.pathname === "/Recruiter/Shortlist" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Shortlist" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <PersonSearch sx={{ color: "#8B8B8B" }} />
          </Box>
          {/* <Box
            sx={{
              background: location.pathname === "/Recruiter/Schedule" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Schedule" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <CalendarMonth sx={{ color: "#8B8B8B" }} />
          </Box> */}
          <Box
            sx={{
              background: location.pathname === "/Profile" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Profile" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <AccountCircle sx={{ color: "#8B8B8B" }} />
          </Box>
          <Box
            sx={{
              background: location.pathname === "/Recruiter/Support" ? "rgba(208, 222, 230, 0.30)" : "",
              borderRight: location.pathname === "/Recruiter/Support" ? "2px solid #165983" : "",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Logout sx={{ color: "#8B8B8B" }} />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default RecruiterSidenavbar;
