import { ArrowBack, ArrowDropDown, Cancel, Close, Done, EditOutlined, HighlightOff, LocationOn, PaymentsOutlined, PeopleAltOutlined } from "@mui/icons-material";
import { Autocomplete, Box, Button, IconButton, InputAdornment, Modal, Step, StepLabel, Stepper, Switch, TextField, Typography } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { City } from "country-state-city";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Color } from "../../services/GlobalStyles";
import Service from "../../services/httpService";
import Loader, { ActivityLoader } from "../Loader/Loader";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import ReviewModal from "./ReviewModal";
import { makeTextBold } from "../../exportedFunctions/htmlParser";
import { get_specialCharReplace } from "../../exportedFunctions/specialCharacterReplace";
import { Colors } from "../../Styles/GlobalStyles";
import JobTypes from "../../components/Modals/JobTypes";
import AreaOfWork from "../../components/Modals/AreaOfWork";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed } = props;

  return (
    <Typography
      style={{
        background: completed ? "#FF725E" : active ? "#fff" : "#E6E6E6",
        padding: "8px 15px",
        borderRadius: "50%",
        color: completed ? "#FFF" : active ? "#FF725E" : "#545454",
        fontSize: "14px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: completed ? "none" : active ? "1px solid #FF725E" : "1px solid #545454",
      }}
    >
      {props.icon}
    </Typography>
  );
}

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: 200,
//       width: 250,
//     },
//   },
// };

// function getStyles(name, personName) {
//   return {
//     fontWeight: personName.indexOf(name) === -1 ? 400 : 600,
//   };
// }

const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#1EB351",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
function Jobs() {
  const [loading, setLoading] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);
  const services = new Service();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobID = queryParams.get("job_id");

  const [job_id, setJobId] = useState(location.state?.job_id ? location.state?.job_id : jobID !== null ? parseInt(jobID) : null);

  // const job_id = location.state.job_id;
  const navigate = useNavigate();
  const { companyName, about_company } = useSelector((store) => store.user);

  const [reviewModal, setReviewModal] = useState(false);
  let comapny = companyName === null ? localStorage.getItem("company") : companyName;
  const steps = ["GETTING STARTED", "CANDIDATE PROFILE", "JOB DESCRIPTION", "SELECTION PROCESS", "Lets talk Numbers", "COMP AND BENEFITS", "REVIEW"];
  const progressSteps = ["GETTING STARTED", "CANDIDATE PROFILE", "JOB DESCRIPTION", "SELECTION PROCESS", "COMP AND BENEFITS", "REVIEW"];

  const [allRoles, setAllRoles] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [allSpecializations, setAllSpecializations] = useState([]);
  const [sampleQuestions, setSampleQuestions] = useState(null);
  const [showSampleQuestions, setShowSampleQuestions] = useState(false);
  const [getJd_data, setGetJd_data] = useState([]);
  const [showDescriptions, setShowDescriptions] = useState(false);
  const [jobTable_data, setJobTable_data] = useState({
    id: null,
    job_type_id: null,
    job_by: null,
    spe_id: null,
    role_id: null,
    openings: null,
    job_mode: null,
    responsibilities: null,
    skill_ids: null,
    is_paid: 1,
    assessments: null,
    fixed_component: null,
    variable_component: null,
    perks: null,
    job_status: "Incomplete",
    last_updated: null,
    remarks: null,
    job_published_time: null,
    job_accepted_time: null,
    is_deleted: 0,
    list_timing: null,
    closed_timing: null,
    jd: null,
    timestamp: "",
  });

  const [othersPaying, setOthersPaying] = useState(false);

  const [job_type_id, setJob_type_id] = useState(null);
  const [jobType, setJobType] = useState(null);

  //validation
  const [formError, setFormError] = useState({
    getting_started: "",
    ideal_candidate: { type_designation: "", select_role: "", select_area_of_management: "", number_of_open_positions: "", location: "" },
    job_description: { key_responsibilities: "", role_proficiencies: "" },
    is_paid: "",
    compensation_and_perks: { fixed_pay: "", variable_pay: "", perks: "" },
  });

  const [publishModal, setPublishModal] = useState(false);

  //BIG DATAD
  const [idealCandidate, setIdealCandidate] = useState([
    {
      label: "Designation",
      placeholder: "Type here...",
      selected: true,
      description: (
        <Typography sx={{ fontSize: "16px", color: "#8E8E8E" }}>
          Select the required “<b>Area of Management</b>” to unlock this section
        </Typography>
      ),
      value: "",
    },
    {
      label: "Select Role",
      selected: false,
      description: (
        <Typography sx={{ fontSize: "16px", color: "#8E8E8E" }}>
          Select the required “<b>Area of Management</b>” to unlock this section
        </Typography>
      ),
      allOptions: allRoles,
      selectedOptions: {
        id: null,
        name: "",
        specialization_id: null,
      },
    },
    {
      label: "Area of Management",
      selected: false,
      description: "",
      allOptions: [],
      selectedOptions: {
        id: null,
        name: "",
      },
    },
    {
      label: "Number of Open Positions",
      selected: false,
      description: (
        <Typography sx={{ fontSize: "16px", color: "#8E8E8E" }}>
          Select the required “<b>Role/Position</b>” to unlock this section{" "}
        </Typography>
      ),
      openings: 1,
    },
    {
      label: "Location",
      selected: false,
      description: (
        <Typography sx={{ fontSize: "16px", color: "#8E8E8E" }}>
          Select the required “<b>Open Positions</b>” to unlock this section
        </Typography>
      ),
      allOptions: City.getCitiesOfCountry("IN"), // City.getCitiesOfCountry("IN")
      inputvalue: "",
      selectedOptions: {
        id: null,
        name: null,
      },
      value: "",
    },
  ]);

  const [jobDescription, setJobDescription] = useState([
    {
      label: "Key Responsibilities",
      description: "This covers the kind of work the candidate will undertake. You can customize it as per your requirements.",
      note: " Write each bullet point in a short and crisp manner in clear words.",
      value: "",
      selected: true,
    },
    {
      label: "Role Proficiencies",
      description: "This covers the kind of work the candidate will undertake. You can customize it as per your requirements.",
      note: " Write each bullet point in a short and crisp manner in clear words.",
      value: "",
      selected: true,
    },
    // {
    //   label: "Skills Required",
    //   description: "This indicates the necessary skills a candidate must posses for this job. You can add more as per your requirements.",
    //   skillRequired: [],
    //   selected: false,
    // },
  ]);

  const [candidateCompetency, setCandidateCompetency] = useState([]);

  const [isPaid, setIsPaid] = useState(null);
  const [hoveredReviewSection, setHoveredReviewSection] = useState(null);
  const [modalItem, setModalItem] = useState(null);
  const reviewSection = [
    {
      label: "Application Details",
      description: "Role, Number of positions, Location",
      icon: "https://du03hetco8xdw.cloudfront.net/recruiter/applications.svg",
      stepIndex: 1,
    },
    {
      label: "Job Description",
      description: "Key Responsibilities, Role Proficiencies",
      icon: "https://du03hetco8xdw.cloudfront.net/recruiter/jobDescription.svg",
      stepIndex: 2,
    },
    {
      label: "Assessment Round",
      description: "Assessment Tests managed by CareerCarve",
      icon: "https://du03hetco8xdw.cloudfront.net/recruiter/assessment.svg",
      stepIndex: 3,
    },
    {
      label: "Pay, Perks and Benefits",
      description: "Fixed & Variable Compensation, Perks & Benefits",
      icon: "https://du03hetco8xdw.cloudfront.net/recruiter/payPerks.svg",
      stepIndex: 4,
    },
  ];

  const [perks, setPerks] = useState([
    {
      label: "Compensation",
      description: "Input information on how you like to incentivize the candidate.",
      fixedPay: null,
      variablePay: null,
      selected: true,
    },
    {
      label: "Perks and Benefits",
      description: "Type in the perks and benefits you would like to provide. This motivates the candidate to constantly perform better.",
      value: "",
      selected: false,
    },
  ]);

  const [selectedStep, setSelectedStep] = useState(0);
  const [progressStep, setProgressStep] = useState(0);
  const [render, setRerender] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [lastUpdated, setLastUpdated] = useState(0);
  const [jobTypeModal, setJobTypeModal] = useState(false);
  const [areaofWork, setAreaofWork] = useState(false);
  const [clickedJobType, setClickedJobType] = useState(0);
  const [changed, setChanged] = useState(false);

  const verifyNext = () => {
    let flag = true;
    if (selectedStep === 0) {
      if (jobType === null) {
        flag = false;
        setFormError((prev) => ({ ...prev, getting_started: "Please select a job type" }));
      }
    } else if (selectedStep === 1) {
      if ([null, ""].includes(idealCandidate[0].value)) {
        flag = false;
        setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, type_designation: "Please enter a designation" } }));
      }
      if (idealCandidate[1].selectedOptions.id === null) {
        flag = false;
        setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, select_role: "Please select a role" } }));
      }
      if (idealCandidate[3].openings === 0) {
        flag = false;
        setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, number_of_open_positions: "Please enter the number of openings" } }));
      }
      if ((jobType !== 1 && idealCandidate[4].value === null) || (jobType !== 1 && idealCandidate[4].value === "")) {
        flag = false;
        setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, location: "Please enter a location" } }));
      }
    } else if (selectedStep === 2) {
      if (["<p><br></p>", ""].includes(jobDescription[0].value)) {
        flag = false;
        setFormError((prev) => ({ ...prev, job_description: { ...prev.job_description, key_responsibilities: "Please enter key responsibilities" } }));
      }
      if (["<p><br></p>", ""].includes(jobDescription[1].value)) {
        flag = false;
        setFormError((prev) => ({ ...prev, job_description: { ...prev.job_description, role_proficiencies: "Please enter role proficiencies" } }));
      }
      // if (jobDescription[2].skillRequired.length === 0) {
      //   flag = false;
      // }
    } else if (selectedStep === 4) {
      if (jobType === 1) {
        if (isPaid === null) {
          flag = false;
          setFormError((prev) => ({ ...prev, is_paid: "Please choose between paid and unpaid" }));
        }
        // else if (perks[0].fixedPay === null) {
        //   flag = false;
        //   setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, fixed_pay: 'Please enter a value' } }));
        // }
      }
    } else if (selectedStep === 5) {
      if (["", null, 0].includes(perks[0].fixedPay) && isPaid === 1) {
        flag = false;

        setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, fixed_pay: "Please enter a Correct value" } }));
      }

      if (isNaN(perks[0].variablePay)) {
        flag = false;

        setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, variable_pay: "Please enter a Correct value" } }));
      }

      if (job_type_id === 2) {
        if (parseInt(perks[0].fixedPay) < 10000) {
          flag = false;

          setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, fixed_pay: "Minimum pay is 10,000" } }));
        }
      } else if (job_type_id === 3) {
        if (parseInt(perks[0].fixedPay) < 400000) {
          flag = false;

          setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, fixed_pay: "Minimum pay is 4,00,000" } }));
        }
      }
    }

    return flag;
  };

  const handleClick = async (state) => {
    setActivityLoading(true);
    if (state === "next") {
      let step = selectedStep;

      if (step === 0) {
        if (verifyNext()) {
          const job_mode = jobType === 1 ? "Remote" : "In-office";
          if (jobType === 1) {
            let temp_ideal = [...idealCandidate];
            temp_ideal[4].value = "Remote";
            setIdealCandidate(temp_ideal);
          } else {
            let temp_ideal = [...idealCandidate];
            temp_ideal[4].value = temp_ideal[4].value === "Remote" ? "" : temp_ideal[4].value;
            setIdealCandidate(temp_ideal);
          }

          if ([null, undefined].includes(job_id)) {
            const postJob = await services.post("/jobs/recruiter/jobTable");

            if (postJob.data.success) {
              let body = {
                //FIXME: get right JOB ID
                job_id: postJob.data.job_id,
                job_type_id: jobType,
                job_mode: jobType === 1 ? "Remote" : "In-office",
                last_updated: lastUpdated > step ? lastUpdated : step,
                job_status: "Incomplete",
              };

              const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);

              setJobId(postJob.data.job_id);
            }
          } else {
            let body = {
              //FIXME: get right JOB ID
              job_id: job_id,
              job_type_id: jobType,
              job_mode: jobType === 1 ? "Remote" : "In-office",
              last_updated: lastUpdated > step ? lastUpdated : step,
              job_status: "Incomplete",
            };

            const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
          }

          setLastUpdated(lastUpdated > step ? lastUpdated : step);
          if (changedModal) {
            if (stepNavigation.future === 5) {
              setSelectedStep(6);
            } else if (stepNavigation.future === 4) {
              setSelectedStep(5);
            } else {
              setSelectedStep(stepNavigation.future);
            }
            setProgressStep(stepNavigation.future);
          } else {
            setSelectedStep(1);
            setProgressStep(1);
          }
        }
      } else if (step === 1) {
        if (verifyNext()) {
          if (changed) {
            try {
              // const assessmentTest = await services.get(`/jobs/assessment?role_ids=${idealCandidate[1].selectedOptions.id}`);
              const assessmentTest = await services.get(`/jobs/recruiter/speTestWeightage?role_id=${idealCandidate[1].selectedOptions.id}`);
              const jd_data_response = await services.get(`/jobs/recruiter/getJd?role_id=${idealCandidate[1].selectedOptions.id}&job_type_id=${jobType}&job_id=${job_id}`);
              let jd_data;
              if (jd_data_response.data.length > 0) {
                jd_data = { ...jd_data_response.data[0], jd: jd_data_response.data[0].jd };
              } else {
                jd_data = { jd: { key_responsibilities: "", role_proficiencies: "" } };
              }

              let temp_JD = [...jobDescription];
              setGetJd_data(jd_data);

              temp_JD[0].value = typeof jd_data.jd.key_responsibilities === "string" ? jd_data.jd.key_responsibilities : "<ul><li>" + jd_data.jd.key_responsibilities.join("</li><li>") + "</li></ul>";
              temp_JD[1].value = typeof jd_data.jd.role_proficiencies === "string" ? jd_data.jd.role_proficiencies : "<ul><li>" + jd_data.jd.role_proficiencies.join("</li><li>") + "</li></ul>";

              let test_data = assessmentTest.data.map((item) => {
                return {
                  ...item,
                  value: jobTable_data.assessments === null ? true : jobTable_data.assessments.split(",").includes(item.test_type_id) ? true : item.is_mandatory === 1 ? true : false,
                };
              });

              test_data = test_data.sort((a, b) => b.is_mandatory - a.is_mandatory);
              let assessmentTests = test_data.filter((item) => item.value === true).map((ele) => ele.assessment_id);

              // setAllowedTests(newAllowedTest);
              setCandidateCompetency(test_data);
              let body = {
                //FIXME: get right JOB ID
                job_id: job_id,
                job_designation: idealCandidate[0].value,
                role_id: idealCandidate[1].selectedOptions.id,
                spe_id: idealCandidate[1].selectedOptions.specialization_id,
                assessments: assessmentTests.join(","),
                openings: idealCandidate[3].openings,
                last_updated: lastUpdated > step ? lastUpdated : step,
                location: idealCandidate[4].value,
                job_status: "Incomplete",
              };

              try {
                const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
              } catch (err) {
                console.error("Putting Error:", err);
              }

              // let temp_candidateCompetency = candidateCompetency;
              // temp_candidateCompetency[3].test = assessmentTest.data[0].name;
              // setCandidateCompetency(temp_tests);
              //FIXME: GO TO NEXT ONLY WHEN SUCCESS
              setLastUpdated(lastUpdated > step ? lastUpdated : step);
              if (changedModal) {
                if (stepNavigation.future === 5) {
                  setSelectedStep(6);
                } else if (stepNavigation.future === 4) {
                  setSelectedStep(5);
                } else {
                  setSelectedStep(stepNavigation.future);
                }
                setProgressStep(stepNavigation.future);
              } else {
                setSelectedStep(2);
                setProgressStep(2);
              }
            } catch (err) {
              console.error("Error in fetching and making assessment:", err);
            }
          } else {
            //FIXME: GO TO NEXT ONLY WHEN SUCCESS
            setLastUpdated(lastUpdated > step ? lastUpdated : step);
            setSelectedStep(2);
            setProgressStep(2);
          }
        }
      } else if (step === 2) {
        if (verifyNext()) {
          let body = {
            //FIXME: get right JOB ID
            job_id: job_id,
            // responsibilities: jobDescription[0].value,
            jd: {
              key_responsibilities: jobDescription[0].value,
              role_proficiencies: jobDescription[1].value,
            },
            // skill_ids: skill_ids.join(","),
            last_updated: lastUpdated > step ? lastUpdated : step,
            job_status: "Incomplete",
          };

          try {
            const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);

            // FIXME: GO TO NEXT ONLY WHEN SUCESS
            setLastUpdated(lastUpdated > step ? lastUpdated : step);
            if (changedModal) {
              if (stepNavigation.future === 5) {
                setSelectedStep(6);
              } else if (stepNavigation.future === 4) {
                setSelectedStep(5);
              } else {
                setSelectedStep(stepNavigation.future);
              }
              setProgressStep(stepNavigation.future);
            } else {
              setSelectedStep(3);
              setProgressStep(3);
            }
          } catch (err) {
            console.error("Putting error:", err);
          }
        }
      } else if (step === 3) {
        if (jobType === 1) {
          let temp_tests = candidateCompetency.filter((item) => item.value === true);

          temp_tests = temp_tests.map((item) => item.assessment_id);

          let body = {
            //FIXME: get right JOB ID
            job_id: job_id,
            assessments: temp_tests.join(","),
            last_updated: lastUpdated > step ? lastUpdated : step,
            job_status: "Incomplete",
          };
          if (changed) {
            try {
              const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);

              if (changedModal) {
                if (stepNavigation.future === 5) {
                  setSelectedStep(6);
                } else if (stepNavigation.future === 4) {
                  setSelectedStep(5);
                } else {
                  setSelectedStep(stepNavigation.future);
                }
                setProgressStep(stepNavigation.future);
              } else {
                setSelectedStep(4);
                setProgressStep(4);
              }
            } catch (err) {
              console.error("Error putting:", err);
            }
          } else {
            setSelectedStep(4);
            setProgressStep(4);
          }
        } else {
          let temp_tests = candidateCompetency.filter((item) => item.value === true);
          temp_tests = temp_tests.map((item) => item.assessment_id);

          setIsPaid(1);
          let body = {
            //FIXME: get right JOB ID
            job_id: job_id,
            is_paid: 1,
            assessments: temp_tests.join(","),
            last_updated: lastUpdated > step ? lastUpdated : step,
            job_status: "Incomplete",
          };
          if (changed) {
            try {
              const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);

              if (changedModal) {
                if (stepNavigation.future === 5) {
                  setSelectedStep(6);
                } else if (stepNavigation.future === 4) {
                  setSelectedStep(5);
                } else {
                  setSelectedStep(stepNavigation.future);
                }
                setProgressStep(stepNavigation.future);
              } else {
                setSelectedStep(5);
                setProgressStep(4);
              }
            } catch (err) {
              console.error("Error Putting:", err);
            }
          } else {
            setSelectedStep(5);
            setProgressStep(4);
          }
        }
      } else if (step === 4) {
        if (verifyNext()) {
          let body = {
            //FIXME: get right JOB ID
            job_id: job_id,
            is_paid: isPaid,
            last_updated: lastUpdated > step ? lastUpdated : step,
            job_status: "Incomplete",
          };
          if (changed) {
            try {
              const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);

              //FIXME: GO TO NEXT ONLY WHEN SUCESS
              setLastUpdated(lastUpdated > step ? lastUpdated : step);
              if (reviewModal) {
                setReviewModal(false);
              } else {
                setSelectedStep(5);
              }
            } catch (err) {
              console.error("Error putting:", err);
            }
          } else {
            //FIXME: GO TO NEXT ONLY WHEN SUCESS
            setLastUpdated(lastUpdated > step ? lastUpdated : step);
            setSelectedStep(5);
          }
        }
        // setProgressStep(2);
      } else if (step === 5) {
        if (verifyNext()) {
          let body = {
            //FIXME: get right JOB ID
            job_id: job_id,
            fixed_component: isPaid === 1 ? parseFloat(perks[0].fixedPay) : undefined,
            variable_component: ![0, null, ""].includes(perks[0].variablePay) ? parseFloat(perks[0].variablePay) : 0,
            perks: perks[1].value,
            last_updated: lastUpdated > step ? lastUpdated : step,
            job_status: "Incomplete",
          };

          if (changed) {
            try {
              const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);

              // FIXME: GO TO NEXT ONLY WHEN SUCESS
              setLastUpdated(lastUpdated > step ? lastUpdated : step);
              if (changedModal) {
                if (stepNavigation.future === 5) {
                  setSelectedStep(6);
                } else if (stepNavigation.future === 4) {
                  setSelectedStep(5);
                } else {
                  setSelectedStep(stepNavigation.future);
                }
                setProgressStep(stepNavigation.future);
              } else {
                setSelectedStep(6);
                setProgressStep(6);
              }
            } catch (err) {
              console.error("Error putting:", err);
            }
          } else {
            // FIXME: GO TO NEXT ONLY WHEN SUCESS
            setLastUpdated(lastUpdated > step ? lastUpdated : step);
            setSelectedStep(6);
            setProgressStep(6);
          }
        }
      } else {
        if (changedModal) {
          if (stepNavigation.future === 5) {
            setSelectedStep(6);
          } else if (stepNavigation.future === 4) {
            setSelectedStep(5);
          } else {
            setSelectedStep(stepNavigation.future);
          }
          setProgressStep(stepNavigation.future);
        } else {
          setSelectedStep(step + 1);
          if (step !== 4) {
            setProgressStep(step + 1);
          }
        }
      }
    } else {
      let step = selectedStep;
      if (jobType === 1) {
        setSelectedStep(step - 1);
        if (step !== 5) {
          setProgressStep(step - 1);
        }
      } else {
        if (step === 5) {
          setSelectedStep(3);
          setProgressStep(3);
        } else {
          setSelectedStep(step - 1);
          setProgressStep(step - 1);
        }
      }
    }
    window.scroll(0, 0);
    if (changedModal) {
      setChangedModal(false);
    }
    setActivityLoading(false);
  };

  const handleJobSelection = (value) => {
    setJobType(value);
    setJob_type_id(value);
    setFormError((prev) => ({ ...prev, getting_started: "" }));
    let temp_idealCandidates = [...idealCandidate];
    if (value === 1) {
      idealCandidate[4].selectedOptions = {
        id: 1,
        name: "Online",
      };
    } else {
      idealCandidate[4].selectedOptions = {
        id: null,
        name: null,
      };
    }
    setIdealCandidate(temp_idealCandidates);
  };

  const handleCandidateChange = async (event, index, newValue) => {
    const { value } = event.target;

    setChanged(true);
    if (index === 0) {
      setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, type_designation: "" } }));
      let temp_candidate = [...idealCandidate];
      temp_candidate[0].value = value;
      setIdealCandidate(temp_candidate);
    } else if (index === 1) {
      setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, select_role: "" } }));
      let temp_candidate = [...idealCandidate];

      if (newValue) {
        temp_candidate[index].selectedOptions = newValue;
        let specialization = allSpecializations.find((item) => item.id === newValue.specialization_id);

        temp_candidate[index + 1].selectedOptions = specialization;
      } else {
        temp_candidate[index].selectedOptions = {
          id: null,
          name: "",
          specialization_id: null,
        };
        temp_candidate[index + 1].selectedOptions = {
          id: null,
          name: "",
        };
      }
      setIdealCandidate(temp_candidate);
      setRerender((prev) => !prev);
    } else if (index === 3) {
      setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, number_of_open_positions: "" } }));
      if (parseInt(value)) {
        let temp_candidates = idealCandidate;
        temp_candidates[3].openings = parseInt(value) === 0 ? 1 : parseInt(value);
        setIdealCandidate(temp_candidates);
        setRerender((prev) => !prev);
      } else {
        let temp_candidates = idealCandidate;
        temp_candidates[3].openings = 1;

        setIdealCandidate(temp_candidates);
        setRerender((prev) => !prev);
      }
    } else if (index === 4) {
      setFormError((prev) => ({ ...prev, ideal_candidate: { ...prev.ideal_candidate, location: "" } }));
      let temp_candidates = idealCandidate;
      temp_candidates[4].value = value;
      setIdealCandidate(temp_candidates);
    }
  };

  const handleOpeningsIncDec = (val) => {
    setChanged(true);
    if (val === "inc") {
      let value = idealCandidate[3].openings + 1;
      let temp_candidates = idealCandidate;
      temp_candidates[3].openings = value;

      setIdealCandidate(temp_candidates);
    } else {
      let value = idealCandidate[3].openings - 1;
      if (value >= 1) {
        let temp_candidates = idealCandidate;
        temp_candidates[3].openings = value;

        setIdealCandidate(temp_candidates);
      }
    }
    setRerender((prev) => !prev);
  };

  const handleJobDescription = (e, index, newValue, check, itemValue) => {
    setChanged(true);
    if (index === 0) {
      //RICh TEXT EDITOR
      let tempvalue = e.replaceAll("<p><br></p><p><br></p>", "<br />");

      setFormError((prev) => ({ ...prev, job_description: { ...prev.job_description, key_responsibilities: "" } }));
      let temp_JD = [...jobDescription];
      temp_JD[index].value = e;
      setJobDescription(temp_JD);
    } else if (index === 1) {
      //RICh TEXT EDITOR
      let tempvalue = e.replaceAll("</p><p>", "<br>");
      setFormError((prev) => ({ ...prev, job_description: { ...prev.job_description, role_proficiencies: "" } }));
      let temp_JD = [...jobDescription];
      temp_JD[index].value = e;
      setJobDescription(temp_JD);
    } else {
      //SKILLS SELECTION
      let temp_JD = JSON.parse(JSON.stringify(jobDescription));
      temp_JD[index].skillRequired = [...temp_JD[index].skillRequired, newValue];

      setJobDescription(temp_JD);
      setInputValue("");
    }
    setRerender((prev) => !prev);
  };

  const handlePostNewSkill = async (e, index) => {
    setChanged(true);
    if (e.key === "Enter") {
      if (!allSkills.includes(inputValue)) {
        const postSkill = await services.post("/jobs/skill", {
          skill_body: [inputValue],
        });

        let temp_JD = JSON.parse(JSON.stringify(jobDescription));
        temp_JD[index].skillRequired = [...temp_JD[index].skillRequired, { id: postSkill.data.skill_ids[0], name: inputValue }];
        let temp_allSkill = allSkills;
        temp_allSkill = [...temp_allSkill, { id: postSkill.data.skill_ids[0], name: inputValue }];
        setAllSkills(temp_allSkill);

        setJobDescription(temp_JD);
        setInputValue("");
      }
    }
  };

  const handleCandidateCompetency = (e, index) => {
    setChanged(true);
    let temp_candidate = candidateCompetency;
    temp_candidate[index].value = e.target.checked;

    setCandidateCompetency(temp_candidate);
    setRerender((prev) => !prev);
  };

  const handlePerksChanges = (e, index, val) => {
    setChanged(true);
    if (index === 0) {
      const { value } = e.target;
      if (!isNaN(value)) {
        let temp_perks = [...perks];
        if (val === "fixed") {
          temp_perks[index].fixedPay = value;
          setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, fixed_pay: "" } }));
        } else {
          temp_perks[index].variablePay = value;
        }

        setPerks(temp_perks);
        setRerender((prev) => !prev);
      } else {
        if (val === "fixed") {
          setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, fixed_pay: "Fixed pay must be a number" } }));
        } else {
          setFormError((prev) => ({ ...prev, compensation_and_perks: { ...prev.compensation_and_perks, variable_pay: "Variable pay must be a number" } }));
        }
      }
    } else {
      let temp_perks = perks;
      temp_perks[index].value = e;
      setPerks(temp_perks);
    }
  };

  const handleReviewSelection = (item, type) => {
    if (type === "hovered") {
      setHoveredReviewSection(item);
      setModalItem(item);
    } else {
      setHoveredReviewSection(null);
    }
  };

  const handleEditSection = (e) => {
    //FIXME: NOT DECIDED WEATHER TO OPEN MODAL OR GO BACK TO THAT SECTION
    e.preventDefault();
    setModalItem(hoveredReviewSection);
    setReviewModal(true);
  };

  const handleSkillDelete = (option) => {
    setChanged(true);
    let temp_JD = JSON.parse(JSON.stringify(jobDescription));
    let temp_allSkills = [...allSkills, option];
    temp_JD[2].skillRequired = temp_JD[2].skillRequired.filter((ele) => ele.id !== option.id);
    setJobDescription(temp_JD);
    setAllSkills(temp_allSkills);
  };

  const [stepNavigation, setStepNavigation] = useState({
    past: null,
    present: null,
    future: null,
  });
  const [changedModal, setChangedModal] = useState(false);
  const handleStepSelection = (label, step_index) => {
    if (changed) {
      setChangedModal(true);
      setStepNavigation((prev) => {
        return {
          ...prev,
          future: step_index,
        };
      });
    } else {
      if (selectedStep > step_index) {
        if (step_index === 5) {
          setSelectedStep(6);
        } else if (step_index === 4) {
          setSelectedStep(5);
        } else {
          setSelectedStep(step_index);
        }
        setProgressStep(step_index);
      } else {
        if (verifyNext()) {
          if (step_index === 5) {
            setSelectedStep(6);
          } else if (step_index === 4) {
            setSelectedStep(5);
          } else {
            setSelectedStep(step_index);
          }
          setProgressStep(step_index);
        }
      }
    }
  };

  const handleData = async (temp_data, roles, skills, specialization) => {
    let data = temp_data[0];
    if (data) data.jd = data.jd; //parsing

    setJobType(data.job_type_id ? data.job_type_id : null);
    setLastUpdated(data.last_updated);

    if (data.last_updated === 3) {
      setProgressStep(4);
      if (data.job_type_id === 1) {
        setSelectedStep(4);
      } else if (data.job_type_id === 2) {
        setSelectedStep(5);
      } else {
        setSelectedStep(5);
      }
    } else if (data.last_updated === null) {
      setProgressStep(0);
      setSelectedStep(0);
    } else if (data.last_updated === 6) {
      setProgressStep(6);
      setSelectedStep(6);
    } else if (data.last_updated === 4) {
      setProgressStep(4);
      setSelectedStep(5);
    } else {
      setProgressStep(data.last_updated + 1);
      setSelectedStep(data.last_updated + 1);
    }

    //Update Ideal Candidate
    // if (data.last_updated > 0) {
    if (data.role_id) {
      let role = roles.filter((role) => role.id === data.role_id);
      let temp_specialization = specialization.find((spe) => spe.id === data.spe_id);
      let temp_idealCandidate = [...idealCandidate];
      temp_idealCandidate[0].value = data.job_designation ?? "";
      temp_idealCandidate[1].selectedOptions = role[0];
      temp_idealCandidate[2].selectedOptions = temp_specialization;
      temp_idealCandidate[3].openings = data.openings;
      temp_idealCandidate[4].value = data.job_mode ? (data.job_mode === "Remote" ? "Remote" : data.location ? data.location : "") : "";

      setIdealCandidate(temp_idealCandidate);
    }

    // }
    //Update JD CANDIDATE
    // if (data.last_updated > 1) {

    let temp_JD = [...jobDescription];

    if (data.skill_id) {
      let skill_ids = data.skill_ids.split(",");
      let skill = skill_ids.map((item) => {
        let temp_skill = skills.find((ele) => ele.id === parseInt(item));
        return temp_skill;
      });

      temp_JD[2].skillRequired = skill;
    }

    if (data.jd) {
      temp_JD[0].value = typeof data.jd.key_responsibilities === "string" ? data.jd.key_responsibilities : data.jd.key_responsibilities.join(" ");
      temp_JD[1].value = typeof data.jd.role_proficiencies === "string" ? data.jd.role_proficiencies : data.jd.role_proficiencies.join(" ");
    }

    setJobDescription(temp_JD);

    // }

    // if (data.last_updated > 2) {

    //UPDATE TEST COMPITENCY
    // const assessmentTest = await services.get(`/jobs/assessment?role_ids=${data.role_id}`);
    if (data.role_id) {
      const assessmentTest = await services.get(`/jobs/recruiter/speTestWeightage?role_id=${data.role_id}`);
      let test_data = assessmentTest.data.map((item) => {
        return {
          ...item,
          value: data.assessments === null ? true : data.assessments.split(",").includes(item.assessment_id.toString()) ? true : item.is_mandatory === 1 ? true : false,
        };
      });

      test_data = test_data.sort((a, b) => b.is_mandatory - a.is_mandatory);

      setCandidateCompetency(test_data);
    }
    // }

    if (data.last_updated > 3) {
      setIsPaid(data.is_paid);
    }

    if (data.last_updated > 4) {
      let temp_perks = perks;
      temp_perks[0].fixedPay = data.fixed_component ? data.fixed_component : 0;
      temp_perks[0].variablePay = data.variable_component ? data.variable_component : 0;
      temp_perks[1].value = data.perks ? data.perks : "";
      setPerks(temp_perks);
    }
  };

  const handlePublish = async () => {
    const body = {
      job_status: "In review",
      job_id: job_id,
    };

    const publishedData = await services.put("/jobs/recruiter/jobTable", body);

    if (publishedData.success) {
      setPublishModal(true);
    }
  };

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      if (Cookies.get("token") === null) navigate("/");
      window.scroll(0, 0);
      let allSkill, allRoles, allSpecializations;
      try {
        allRoles = await services.get("/superAdmin/role");
        let temp_roles = await services.get("/jobs/rolesByTestTypeId?test_type_id=5");

        setAllRoles(temp_roles.data);
      } catch (err) {
        console.error("Error in fetching and settings roles", err);
      }
      try {
        allSkill = await services.get("/jobs/skill");
        setAllSkills(allSkill.data);
      } catch (err) {
        console.error("Error in fetching and setting allSkill:", err);
      }
      try {
        allSpecializations = await services.get("/superAdmin/specialization");
        setAllSpecializations(allSpecializations.data);
      } catch (err) {
        console.error("Error in fetching and setting allSpecializations:", err);
      }
      try {
        const data = await fetchJobTableData();
        if (data) {
          let jd_data = [];
          const role_id = data.data[0].role_id;

          const temp_job_type_id = data.data[0].job_type_id;
          setJob_type_id(temp_job_type_id);
          setJobTable_data(data.data[0]);

          handleData(
            data.data,
            allRoles.data,
            allSkill.data,
            allSpecializations.data
            // jd_data.length ? JSON.parse(jd_data.jd) : null
          );
        }
      } catch (err) {
        console.error("Error in fetching stuff and making stuff:", err);
      }
      setLoading(false);
    };

    getAllData();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, [selectedStep]);
  const fetchJobTableData = async () => {
    try {
      if (![null, undefined, ""].includes(job_id)) {
        const data = await services.get(`/jobs/recruiter/jobTable?job_id=${job_id}`);

        return data;
      }
    } catch (err) {
      console.error("Error in getting jobtable data:", err);
      return [];
    }
  };

  useEffect(() => {
    setChanged(false);
  }, [selectedStep]);

  const handleCandidateReview = (e, index) => {
    if (index === 3) {
      const { value } = e.target;

      if (parseInt(value) > 0 && !isNaN(parseInt(value))) {
        let ideal_candidate = [...idealCandidate];
        ideal_candidate[3].openings = value;
        // setReviewIdealCandidate(ideal_candidate);
      } else {
        let ideal_candidate = [...idealCandidate];
        ideal_candidate[3].openings = 1;
        setIdealCandidate(ideal_candidate);
      }
    } else {
      const { value } = e.target;
      let ideal_candidate = [...idealCandidate];
      ideal_candidate[index].value = value;
      setIdealCandidate(ideal_candidate);
    }
  };

  const RenderEditModal = (e) => {
    const [reviewIdealCandidate, setReviewIdealCandidate] = useState([...idealCandidate]);
    const [reviewJobDescription, setReviewJobDescription] = useState([...jobDescription]);
    const [reviewPerks, setReViewPerks] = useState([...perks]);
    const [reviewCandidateCompitency, setReviewCandidateCompitency] = useState([...candidateCompetency]);
    const handleSave = async (e) => {
      if (modalItem === 1) {
        let body = {
          //FIXME: get right JOB ID
          job_id: job_id,
          job_designation: reviewIdealCandidate[0].value,
          role_id: reviewIdealCandidate[1].selectedOptions.id,
          spe_id: reviewIdealCandidate[1].selectedOptions.specialization_id,
          // assessments: newAllowedTest.join(","),
          openings: reviewIdealCandidate[3].openings,
          location: reviewIdealCandidate[4].value,
          job_status: "Incomplete",
        };
        try {
          const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
          setIdealCandidate(reviewIdealCandidate);
          setReviewModal(false);
        } catch (err) {}
      } else if (modalItem === 2) {
        let body = {
          //FIXME: get right JOB ID
          job_id: job_id,
          // responsibilities: jobDescription[0].value,
          jd: {
            key_responsibilities: reviewJobDescription[0].value,
            role_proficiencies: reviewJobDescription[1].value,
          },
          // skill_ids: skill_ids.join(","),

          job_status: "Incomplete",
        };

        try {
          const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
          setJobDescription(reviewJobDescription);
          setReviewModal(false);
        } catch (err) {}
      } else if (modalItem === 3) {
        let temp_tests = candidateCompetency.filter((item) => item.value === true);

        temp_tests = temp_tests.map((item) => item.assessment_id);
        let body = {
          //FIXME: get right JOB ID
          job_id: job_id,
          assessments: temp_tests.join(","),

          job_status: "Incomplete",
        };

        try {
          const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
          setCandidateCompetency(reviewCandidateCompitency);
          setReviewModal(false);
        } catch (error) {}
      } else if (modalItem === 4) {
        if (isPaid === 1) {
          if (jobType === 2) {
            if (parseFloat(reviewPerks[0].fixedPay) > 10000) {
              let body = {
                job_id: job_id,
                fixed_component: isPaid === 1 ? parseFloat(reviewPerks[0].fixedPay) : undefined,
                variable_component: reviewPerks[0].variablePay ? parseFloat(reviewPerks[0].variablePay) : 0,
                perks: reviewPerks[1].value,
                job_status: "Incomplete",
              };
              try {
                const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
                setPerks(reviewPerks);

                setReviewModal(false);
              } catch (error) {}
            }
          } else if (jobType === 3) {
            if (parseFloat(reviewPerks[0].fixedPay) > 400000) {
              let body = {
                job_id: job_id,
                fixed_component: isPaid === 1 ? parseFloat(reviewPerks[0].fixedPay) : undefined,
                variable_component: reviewPerks[0].variablePay ? parseFloat(reviewPerks[0].variablePay) : 0,
                perks: reviewPerks[1].value,
                job_status: "Incomplete",
              };
              try {
                const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
                setPerks(reviewPerks);

                setReviewModal(false);
              } catch (error) {}
            }
          } else {
            let body = {
              job_id: job_id,
              fixed_component: isPaid === 1 ? parseFloat(reviewPerks[0].fixedPay) : undefined,
              variable_component: reviewPerks[0].variablePay ? parseFloat(reviewPerks[0].variablePay) : 0,
              perks: reviewPerks[1].value,
              job_status: "Incomplete",
            };
            try {
              const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
              setPerks(reviewPerks);

              setReviewModal(false);
            } catch (error) {}
          }
        } else {
          let body = {
            //FIXME: get right JOB ID
            job_id: job_id,
            fixed_component: isPaid === 1 ? parseFloat(reviewPerks[0].fixedPay) : undefined,
            variable_component: reviewPerks[0].variablePay ? parseFloat(reviewPerks[0].variablePay) : 0,
            perks: reviewPerks[1].value,
            job_status: "Incomplete",
          };
          try {
            const putJOBUPdates = await services.put("/jobs/recruiter/jobTable", body);
            setPerks(reviewPerks);

            setReviewModal(false);
          } catch (error) {}
        }
      }
    };

    // YOYOYO
    if (modalItem === 1) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "20px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              height: "90%",
              padding: "20px",
              borderRadius: "20px",
              overflowY: "scroll",
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "15px",
                height: "30%",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#165983",
                borderRadius: "15px",
                backgroundClip: "padding-box",
              },
              "&::-webkit-scrollbar": {
                // display: "none",

                width: "4px",
              },
              "&::-webkit-scrollbar-track-piece:start": {
                marginTop: "10px",
              },
              "&::-webkit-scrollbar-track-piece:end": {
                marginBottom: "10px",
              },
            }}
          >
            <div>
              <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Who is your Ideal Candidate?</Typography>
            </div>
            {reviewIdealCandidate.map((item, index) => {
              if (index === 3) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                    </Box>
                    <Box>
                      <TextField
                        type="text"
                        sx={{
                          width: "300px",
                          "&:hover": {
                            boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                            borderRadius: "20px",
                            border: "none",
                          },
                          textAlign: "center",
                        }}
                        onFocus={() => {
                          setReviewIdealCandidate((prev) => {
                            return prev.map((item, i) => {
                              if (index === i) {
                                return {
                                  ...item,
                                  selected: true,
                                };
                              } else {
                                return {
                                  ...item,
                                  selected: false,
                                };
                              }
                            });
                          });
                        }}
                        onChange={(e) => {
                          let temp = [...reviewIdealCandidate];

                          if (parseInt(e.target.value) > 0 && !isNaN(e.target.value)) {
                            temp[index].openings = e.target.value;
                            setReviewIdealCandidate(temp);
                          } else {
                            temp[index].openings = 1;
                            setReviewIdealCandidate(temp);
                          }
                        }}
                        value={item.openings}
                        InputProps={{
                          startAdornment: (
                            <button
                              style={{
                                background: "#D0DEE6",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                paddingInline: "18px",
                                marginRight: "10px",
                                border: "none",
                                borderRadius: "20px 0px 0px 20px",
                              }}
                              onClick={() => {
                                let temp = [...reviewIdealCandidate];
                                if (temp[index].openings - 1 > 0) {
                                  temp[index].openings = temp[index].openings - 1;
                                  setReviewIdealCandidate(temp);
                                }
                              }}
                            >
                              <Typography>-</Typography>
                            </button>
                          ),
                          endAdornment: (
                            <button
                              style={{
                                background: "#D0DEE6",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                paddingInline: "18px",
                                border: "none",
                                borderRadius: "0px 20px 20px 0px",
                              }}
                              onClick={() => {
                                let temp = [...reviewIdealCandidate];
                                temp[index].openings = temp[index].openings + 1;
                                setReviewIdealCandidate(temp);
                              }}
                            >
                              <Typography>+</Typography>
                            </button>
                          ),
                          style: {
                            height: "50px", // Adjust the height as needed
                            padding: "0px",
                            borderRadius: "20px",
                            textAlign: "center",
                            border: "none",
                          },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />
                      <Typography sx={{ width: "100%", textAlign: "center" }}>{formError.ideal_candidate.number_of_open_positions}</Typography>
                    </Box>
                  </Box>
                );
              } else if (index === 1) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                    </Box>

                    <Autocomplete
                      options={allRoles}
                      disabled={index === 1 ? false : true}
                      popupIcon={index === 1 ? <ArrowDropDown /> : null}
                      sx={{ width: "300px" }}
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      value={item.selectedOptions.id === null ? null : item.selectedOptions}
                      onChange={(event, newValue) => {
                        let temp = [...reviewIdealCandidate];
                        temp[index].selectedOptions = newValue;
                        let specialization = allSpecializations.find((item) => item.id === newValue.specialization_id);
                        temp[index + 1].selectedOptions = specialization;
                        setReviewIdealCandidate(temp);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                );
              } else if (index === 0) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                    </Box>
                    <TextField
                      placeholder={item.placeholder}
                      sx={{ width: "300px" }}
                      onFocus={() => {
                        setReviewIdealCandidate((prev) => {
                          return prev.map((item, i) => {
                            if (index === i) {
                              return {
                                ...item,
                                selected: true,
                              };
                            } else {
                              return {
                                ...item,
                                selected: false,
                              };
                            }
                          });
                        });
                      }}
                      value={item.value}
                      onChange={(e) => {
                        let temp = [...reviewIdealCandidate];
                        temp[index].value = e.target.value;
                        setReviewIdealCandidate(temp);
                      }}
                    ></TextField>
                  </Box>
                );
              } else if (index === 2) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                    </Box>
                    <Box>
                      <Autocomplete
                        options={item.allOptions}
                        onOpen={() => {
                          setReviewIdealCandidate((prev) => {
                            return prev.map((item, i) => {
                              if (index === i) {
                                return {
                                  ...item,
                                  selected: true,
                                };
                              } else {
                                return {
                                  ...item,
                                  selected: false,
                                };
                              }
                            });
                          });
                        }}
                        disabled={index === 2 ? true : jobType === 1 ? true : false}
                        popupIcon={index === 4 ? <ArrowDropDown /> : null}
                        sx={{ width: "300px" }}
                        clearIcon={null}
                        getOptionLabel={(option) => option.name}
                        value={item.selectedOptions.id === null ? null : item.selectedOptions}
                        onChange={(event, newValue) => {
                          let temp = [...reviewIdealCandidate];
                          temp[index].selectedOptions = newValue;
                          setReviewIdealCandidate(temp);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />

                      {/* <Autocomplete
                        options={item.allOptions}
                        filterOptions={(options) => {
                          return inputValue === "" ? options : options.filter((ele) => ele.name.startsWith(item.inputValue));
                        }}
                        inputValue={item.inputvalue}
                        onOpen={() => {
                          setIdealCandidate((prev) => {
                            return prev.map((item, i) => {
                              if (index === i) {
                                return {
                                  ...item,
                                  selected: true,
                                };
                              } else {
                                return {
                                  ...item,
                                  selected: false,
                                };
                              }
                            });
                          });
                        }}
                        onInputChange={(e, newInputValue) => {
                          let temp = [...idealCandidate];
                          temp[index].inputvalue = newInputValue;
                          setIdealCandidate(temp);
                        }}
                        disabled={index === 2 ? true : jobType === 1 ? true : false}
                        popupIcon={index === 4 ? <ArrowDropDown /> : null}
                        sx={{ width: "300px" }}
                        clearIcon={null}
                        getOptionLabel={(option) => option.name}
                        value={item.selectedOptions.id === null ? null : item.selectedOptions}
                        onChange={(event, newValue) => {
                          handleCandidateChange(event, index, newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      /> */}
                      {index === 4 && <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.ideal_candidate.location}</Typography>}
                    </Box>
                  </Box>
                );
              } else if (index === 4) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                    </Box>
                    <Box>
                      {/* <Autocomplete
                        options={item.allOptions}
                        onOpen={() => {
                          setReviewIdealCandidate((prev) => {
                            return prev.map((item, i) => {
                              if (index === i) {
                                return {
                                  ...item,
                                  selected: true,
                                };
                              } else {
                                return {
                                  ...item,
                                  selected: false,
                                };
                              }
                            });
                          });
                        }}
                        disabled={index === 2 ? true : jobType === 1 ? true : false}
                        popupIcon={index === 4 ? <ArrowDropDown /> : null}
                        sx={{ width: "300px" }}
                        clearIcon={null}
                        getOptionLabel={(option) => option.name}
                        value={item.selectedOptions.id === null ? null : item.selectedOptions}
                        onChange={(event, newValue) => {
                          let temp = [...reviewIdealCandidate];
                          temp[index].selectedOptions = newValue;
                          setReviewIdealCandidate(temp);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      /> */}
                      <TextField
                        type="text"
                        sx={{
                          width: "300px",
                          "&:hover": {
                            boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                            borderRadius: "20px",
                            border: "none",
                          },
                          textAlign: "center",
                        }}
                        onFocus={() => {
                          setReviewIdealCandidate((prev) => {
                            return prev.map((item, i) => {
                              if (index === i) {
                                return {
                                  ...item,
                                  selected: true,
                                };
                              } else {
                                return {
                                  ...item,
                                  selected: false,
                                };
                              }
                            });
                          });
                        }}
                        onChange={(e) => {
                          let temp = [...reviewIdealCandidate];
                          temp[4].value = e.target.value;
                          setReviewIdealCandidate(temp);
                        }}
                        value={item.value}
                      />

                      {index === 4 && <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.ideal_candidate.location}</Typography>}
                    </Box>
                  </Box>
                );
              }
            })}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              height: "10%",
              boxShadow: "0px -6px 10px 0px rgba(0, 0, 0, 0.04)",
            }}
          >
            <button style={{ border: "1px solid #165983", color: "#165983", background: "#fff", borderRadius: "15px", padding: "10px 40px" }} onClick={() => setReviewModal(false)}>
              Cancel
            </button>
            <button
              style={{
                background: "#165983",
                color: "#fff",
                padding: "10px 40px",
                borderRadius: "15px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      );
    } else if (modalItem === 2) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "20px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              height: "90%",
              padding: "20px",
              borderRadius: "20px",
              overflowY: "scroll",
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "15px",
                height: "30%",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#165983",
                borderRadius: "15px",
                backgroundClip: "padding-box",
              },
              "&::-webkit-scrollbar": {
                // display: "none",

                width: "4px",
              },
              "&::-webkit-scrollbar-track-piece:start": {
                marginTop: "10px",
              },
              "&::-webkit-scrollbar-track-piece:end": {
                marginBottom: "10px",
              },
            }}
          >
            <div>
              <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Review Job Description</Typography>
            </div>
            {reviewJobDescription.map((item, index) => (
              <Box
                sx={{
                  borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                  paddingLeft: "20px",
                }}
              >
                <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}> {item.description}</Typography>
                <ReactQuill
                  theme="snow"
                  style={{ marginBottom: "20px" }}
                  value={item.value}
                  onChange={(e) => {
                    let temp = [...reviewJobDescription];
                    temp[index].value = e;
                    setReviewJobDescription(temp);
                  }}
                  modules={{
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                  onFocus={() => {
                    setReviewJobDescription((prev) => {
                      return prev.map((item, i) => {
                        if (index === i) {
                          return {
                            ...item,
                            selected: true,
                          };
                        } else {
                          return {
                            ...item,
                            selected: false,
                          };
                        }
                      });
                    });
                  }}
                />

                <Box sx={{ marginTop: "20px" }}></Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              height: "10%",
              boxShadow: "0px -6px 10px 0px rgba(0, 0, 0, 0.04)",
            }}
          >
            <button style={{ border: "1px solid #165983", color: "#165983", background: "#fff", borderRadius: "15px", padding: "10px 40px" }} onClick={() => setReviewModal(false)}>
              Cancel
            </button>
            <button
              style={{
                background: "#165983",
                color: "#fff",
                padding: "10px 40px",
                borderRadius: "15px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      );
    } else if (modalItem === 3) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "20px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              height: "90%",
              padding: "20px",
              borderRadius: "20px",
              overflowY: "scroll",
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "15px",
                height: "30%",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#165983",
                borderRadius: "15px",
                backgroundClip: "padding-box",
              },
              "&::-webkit-scrollbar": {
                // display: "none",

                width: "4px",
              },
              "&::-webkit-scrollbar-track-piece:start": {
                marginTop: "10px",
              },
              "&::-webkit-scrollbar-track-piece:end": {
                marginBottom: "10px",
              },
            }}
          >
            <div>
              <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Selection Process</Typography>
            </div>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
              <Box>
                <Typography sx={{ fontSize: "24px" }}>Round - 1: Assessments managed by CareerCarve</Typography>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>The below are the recommended tests that checks the first few layers of the competency and gives you a list of top performing candidates.</Typography>
              </Box>
              {reviewCandidateCompitency.map((item, index) => (
                // item.id === 1 ? (
                //   <></>
                // ) :
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <CustomSwitch
                    disabled={item.is_mandatory === 1 ? true : false}
                    checked={item.value}
                    onChange={(e) => {
                      let temp = [...reviewCandidateCompitency];
                      temp[index].value = e.target.checked;
                      setReviewCandidateCompitency(temp);
                    }}
                  />
                  <Box>
                    <Typography sx={{ fontSize: "16px" }}>{item.name}</Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#797979",
                        textDecoration: "underline",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setSampleQuestions(item);
                        setShowSampleQuestions(true);
                      }}
                    >
                      View Sample Questions
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box>
              <Typography sx={{ fontSize: "24px" }}>Round-2: Final Interview</Typography>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>CareerCarve will provide you with a set of shortlists depending on your requirement so all you have to do is take final interviews to fill that position.</Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", marginTop: "24px" }}>
                {/* <CustomSwitch checked={true} disabled />
          <Box>
            <Typography sx={{ fontSize: "16px" }}>
              Final Interview Round
            </Typography>
          </Box> */}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              height: "10%",
              boxShadow: "0px -6px 10px 0px rgba(0, 0, 0, 0.04)",
            }}
          >
            <button style={{ border: "1px solid #165983", color: "#165983", background: "#fff", borderRadius: "15px", padding: "10px 40px" }} onClick={() => setReviewModal(false)}>
              Cancel
            </button>
            <button
              style={{
                background: "#165983",
                color: "#fff",
                padding: "10px 40px",
                borderRadius: "15px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      );
    } else if (modalItem === 4) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "20px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              height: "90%",
              padding: "20px",
              borderRadius: "20px",
              overflowY: "scroll",
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "15px",
                height: "30%",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#165983",
                borderRadius: "15px",
                backgroundClip: "padding-box",
              },
              "&::-webkit-scrollbar": {
                // display: "none",

                width: "4px",
              },
              "&::-webkit-scrollbar-track-piece:start": {
                marginTop: "10px",
              },
              "&::-webkit-scrollbar-track-piece:end": {
                marginBottom: "10px",
              },
            }}
          >
            <div>
              <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Compensation and Perks</Typography>
            </div>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
              {reviewPerks.map((item, index) => {
                if (index === 0) {
                  if (isPaid === 1) {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "24px",
                          borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                          paddingLeft: "20px",
                        }}
                      >
                        <Box>
                          <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.description}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "32px" }}>
                          <Box>
                            <Typography sx={{ fontSize: "16px", marginBottom: "8px" }}>Fixed Component</Typography>
                            <TextField
                              required
                              autoFocus
                              error={jobType === 2 && parseFloat(reviewPerks[0].fixedPay) < 10000 ? true : jobType === 3 && parseFloat(reviewPerks[0].fixedPay) < 400000 ? true : false}
                              helperText={jobType === 2 && parseFloat(reviewPerks[0].fixedPay) < 10000 ? "Fixed pay must be more than 10,000" : jobType === 3 && parseFloat(reviewPerks[0].fixedPay) < 400000 ? "Fixed pay must be more than 4,00,000" : false}
                              value={item.fixedPay}
                              sx={{ width: "300px" }}
                              onFocus={() => {
                                setReViewPerks((prev) => {
                                  return prev.map((item, i) => {
                                    if (index === i) {
                                      return {
                                        ...item,
                                        selected: true,
                                      };
                                    } else {
                                      return {
                                        ...item,
                                        selected: false,
                                      };
                                    }
                                  });
                                });
                              }}
                              onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                  let temp = [...reviewPerks];
                                  temp[index].fixedPay = e.target.value;
                                  setReViewPerks(temp);
                                }
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Typography>&#8377;</Typography>
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <Typography
                                    sx={{
                                      color: "#8B8B8B",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {jobType === 3 ? "/annum" : "/month"}
                                  </Typography>
                                ),
                                style: {
                                  height: "50px", // Adjust the height as needed
                                },
                              }}
                            />
                            <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.compensation_and_perks.fixed_pay}</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: "16px", marginBottom: "8px" }}>Variable Component (optional)</Typography>
                            <TextField
                              sx={{ width: "300px" }}
                              onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                  let temp = [...reviewPerks];
                                  temp[index].variablePay = e.target.value;
                                  setReViewPerks(temp);
                                } else {
                                  let temp = [...reviewPerks];
                                  temp[index].variablePay = 0;
                                  setReViewPerks(temp);
                                }
                              }}
                              value={item.variablePay}
                              error={[null, undefined, 0].includes(item.variablePay) ? false : [NaN].includes(parseInt(item.variablePay)) ? true : false}
                              helperText={[null, undefined, 0].includes(item.variablePay) ? "" : [NaN].includes(parseInt(item.variablePay)) ? "Variable pay must be number" : ""}
                              onFocus={() => {
                                setReViewPerks((prev) => {
                                  return prev.map((item, i) => {
                                    if (index === i) {
                                      return {
                                        ...item,
                                        selected: true,
                                      };
                                    } else {
                                      return {
                                        ...item,
                                        selected: false,
                                      };
                                    }
                                  });
                                });
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Typography>&#8377;</Typography>
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <Typography
                                    sx={{
                                      color: "#8B8B8B",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {jobType === 3 ? "/annum" : "/month"}
                                  </Typography>
                                ),
                                style: {
                                  height: "50px", // Adjust the height as needed
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                } else {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                        paddingLeft: "20px",
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.description}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: "32px" }}>
                        <ReactQuill
                          theme="snow"
                          value={item.value}
                          onChange={(e) => {
                            let temp = [...reviewPerks];
                            temp[index].value = e;
                            setReViewPerks(temp);
                          }}
                          modules={{
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          style={{ width: "100%", height: "auto" }}
                          onFocus={() => {
                            setReViewPerks((prev) => {
                              return prev.map((item, i) => {
                                if (index === i) {
                                  return {
                                    ...item,
                                    selected: true,
                                  };
                                } else {
                                  return {
                                    ...item,
                                    selected: false,
                                  };
                                }
                              });
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  );
                }
              })}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              height: "10%",
              boxShadow: "0px -6px 10px 0px rgba(0, 0, 0, 0.04)",
            }}
          >
            <button style={{ border: "1px solid #165983", color: "#165983", background: "#fff", borderRadius: "15px", padding: "10px 40px" }} onClick={() => setReviewModal(false)}>
              Cancel
            </button>
            <button
              style={{
                background: "#165983",
                color: "#fff",
                padding: "10px 40px",
                borderRadius: "15px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      {loading ? (
        <Loader show={loading} />
      ) : (
        <Box sx={{ overflowX: "hidden" }}>
          <ActivityLoader show={activityLoading} />
          {selectedStep !== 6 ? (
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "10px" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px", ":hover": { cursor: "pointer" } }} onClick={() => navigate("/Recruiter")}>
                <img src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png" style={{ width: "30px", height: "30px", marginBlock: "1.5rem" }} />
                <img src="https://du03hetco8xdw.cloudfront.net/recruiter/CareerCarve.png" style={{ width: "120px", height: "20px", marginBlock: "1.5rem" }} />
              </Box>
              <IconButton onClick={() => setCloseModal(true)}>
                <HighlightOff sx={{ fontSize: "30px" }} />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}
          <div
            style={{
              // height: "100vh",
              // width: "100vw",
              overflow: "auto",
              padding: selectedStep === 6 ? "0rem" : "2rem 7rem",
              display: "flex",
              flexDirection: "column",
              gap: selectedStep === 6 ? "0rem" : "42px",
              // height: "fit-content",
            }}
          >
            {selectedStep !== 0 && selectedStep !== 6 ? (
              <Stepper alternativeLabel activeStep={progressStep} connector={<ColorlibConnector />}>
                {progressSteps.map((label, step_index) => (
                  <Step key={label} onClick={() => handleStepSelection(label, step_index)} sx={{ "&:hover": { cursor: "pointer", textAlign: "center" } }}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: progressStep === step_index ? "#FF725E" : "#000",
                        }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <></>
            )}
            {/* YOYOYO */}

            <Carousel
              animation="slide"
              autoPlay={false}
              swipe={false}
              index={selectedStep}
              height={[6].includes(selectedStep) ? "100vh" : [1, 4, 0].includes(selectedStep) ? "130vh" : "200vh"}
              onChange={() => setRerender((prev) => !prev)}
              navButtonsAlwaysInvisible={true}
              // interval={3000}
              indicators={false}
              duration={700}
              cycleNavigation={false}
              fullHeightHover={false}

              // activeIndicatorIconButtonProps={{
              //   style: {
              //     color: "#1C8EA8",
              //   },
              // }}
            >
              {steps.map((item, step_index) => {
                if (step_index === 0) {
                  return (
                    <div
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <div>
                        <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Let's Get Started</Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            color: "#8B8B8B",
                          }}
                        >
                          Who are you looking to hire?
                        </Typography>
                      </div>
                      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "5px", "&:hover": { cursor: "pointer" } }} onClick={() => setJobTypeModal(true)}>
                        <HelpOutlineIcon />
                        <Typography sx={{ textAlign: "right", fontSize: "16px", color: Colors.primary }}>Need help figuring out what's best?</Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box>
                          <Box
                            sx={{
                              border: "1px solid #DEDEDE",
                              borderRadius: "30px",
                              padding: "20px 30px",
                              width: "350px",
                              "&:hover": {
                                cursor: "pointer",
                                border: "1px solid rgba(22, 89, 131, 0.50)",
                                boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                              },
                              background: jobType === 1 ? "#165983" : "#fff",
                            }}
                            onClick={() => handleJobSelection(1)}
                          >
                            <img alt="live_project" src="https://du03hetco8xdw.cloudfront.net/recruiter/Jobs_live_project.svg" style={{ height: "250px", width: "100%" }} />
                            <Typography
                              sx={{
                                fontSize: "24px",
                                color: jobType === 1 ? "#fff" : "#165983",
                                marginTop: "20px",
                                textAlign: "center",
                              }}
                            >
                              Live project Intern
                            </Typography>
                            <Typography sx={{ textAlign: "center", color: jobType === 1 ? "#fff" : Color.neutralMidGrey, fontSize: "16px" }}>Remote Job</Typography>
                          </Box>
                          <Typography
                            sx={{ color: Colors.primary, fontSize: "14px", textAlign: "center", textDecoration: "underline", fontStyle: "italic", mt: 2, "&:hover": { cursor: "pointer" } }}
                            onClick={() => {
                              setClickedJobType(0);
                              setJobTypeModal(true);
                            }}
                          >
                            Live Project pro hiring tips
                          </Typography>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              border: "1px solid #DEDEDE",
                              borderRadius: "30px",
                              padding: "20px 30px",
                              width: "350px",
                              "&:hover": {
                                cursor: "pointer",
                                border: "1px solid rgba(22, 89, 131, 0.50)",
                                boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                              },
                              background: jobType === 2 ? "#165983" : "#fff",
                            }}
                            onClick={() => handleJobSelection(2)}
                          >
                            <img alt="summer_intern" src="https://du03hetco8xdw.cloudfront.net/recruiter/jobs_summer_intern.svg" style={{ height: "250px", width: "100%" }} />
                            <Typography
                              sx={{
                                fontSize: "24px",
                                color: jobType === 2 ? "#fff" : "#165983",
                                marginTop: "20px",
                                textAlign: "center",
                              }}
                            >
                              Summer Intern
                            </Typography>
                            <Typography sx={{ textAlign: "center", color: jobType === 2 ? "#fff" : Color.neutralMidGrey, fontSize: "16px" }}>In-Office Job</Typography>
                          </Box>

                          <Typography
                            sx={{ color: Colors.primary, fontSize: "14px", textAlign: "center", textDecoration: "underline", fontStyle: "italic", mt: 2, "&:hover": { cursor: "pointer" } }}
                            onClick={() => {
                              setClickedJobType(1);
                              setJobTypeModal(true);
                            }}
                          >
                            Summer Internship pro hiring tips
                          </Typography>
                        </Box>

                        <Box>
                          <Box
                            sx={{
                              border: "1px solid #DEDEDE",
                              borderRadius: "30px",
                              padding: "20px 30px",
                              width: "350px",
                              "&:hover": {
                                cursor: "pointer",
                                border: "1px solid rgba(22, 89, 131, 0.50)",
                                boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                              },
                              background: jobType === 3 ? "#165983" : "#fff",
                            }}
                            onClick={() => handleJobSelection(3)}
                          >
                            <img alt="full_timer" src="https://du03hetco8xdw.cloudfront.net/recruiter/jobs_full_time.svg" style={{ height: "250px", width: "100%" }} />
                            <Typography
                              sx={{
                                fontSize: "24px",
                                color: jobType === 3 ? "#fff" : "#165983",
                                marginTop: "20px",
                                textAlign: "center",
                              }}
                            >
                              Full Time Employee
                            </Typography>
                            <Typography sx={{ textAlign: "center", color: jobType === 3 ? "#fff" : Color.neutralMidGrey, fontSize: "16px" }}>In-Office Job</Typography>
                          </Box>

                          <Typography
                            sx={{ color: Colors.primary, fontSize: "14px", textAlign: "center", textDecoration: "underline", fontStyle: "italic", mt: 2, "&:hover": { cursor: "pointer" } }}
                            onClick={() => {
                              setClickedJobType(2);
                              setJobTypeModal(true);
                            }}
                          >
                            Full-time employment&nbsp; pro hiring tips
                          </Typography>
                        </Box>
                      </Box>
                      <Typography sx={{ color: "red", width: "100%", textAlign: "center" }}>{formError.getting_started}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <button
                          style={{
                            background: jobType === null ? "#8B8B8B" : "#165983",
                            color: "#fff",
                            padding: "10px 40px",
                            borderRadius: "15px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          // disabled={jobType === null ? true : false}
                          onClick={() => handleClick("next")}
                        >
                          Next
                        </button>
                      </Box>
                    </div>
                  );
                } else if (step_index === 1) {
                  return (
                    <div
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: "40px",
                      }}
                    >
                      <div>
                        <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Who is your Ideal Candidate?</Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            color: "#8B8B8B",
                          }}
                        >
                          Using this information, our AI will generate a job description that matches the candidate profile.
                        </Typography>
                        {jobType === 1 && (
                          <Typography sx={{ color: Colors.primary, fontSize: "14px", textAlign: "right", textDecoration: "underline", fontStyle: "italic", marginBlock: 2, "&:hover": { cursor: "pointer" } }} onClick={() => setAreaofWork(true)}>
                            What types of projects can MBA's work on?
                          </Typography>
                        )}
                      </div>

                      {idealCandidate.map((item, index) => {
                        if (index === 3) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                                paddingLeft: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "40%",
                                  "&:hover": { cursor: "pointer" },
                                }}
                              >
                                <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  type="text"
                                  sx={{
                                    width: "300px",
                                    "&:hover": {
                                      boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                                      borderRadius: "20px",
                                      border: "none",
                                    },
                                    textAlign: "center",
                                  }}
                                  onFocus={() => {
                                    setIdealCandidate((prev) => {
                                      return prev.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                  onChange={(e) => {
                                    handleCandidateChange(e, index);
                                  }}
                                  value={item.openings}
                                  InputProps={{
                                    startAdornment: (
                                      <button
                                        style={{
                                          background: "#D0DEE6",
                                          height: "100%",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          display: "flex",
                                          paddingInline: "18px",
                                          marginRight: "10px",
                                          border: "none",
                                          borderRadius: "20px 0px 0px 20px",
                                        }}
                                        onClick={() => handleOpeningsIncDec("dec")}
                                      >
                                        <Typography>-</Typography>
                                      </button>
                                    ),
                                    endAdornment: (
                                      <button
                                        style={{
                                          background: "#D0DEE6",
                                          height: "100%",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          display: "flex",
                                          paddingInline: "18px",
                                          border: "none",
                                          borderRadius: "0px 20px 20px 0px",
                                        }}
                                        onClick={() => handleOpeningsIncDec("inc")}
                                      >
                                        <Typography>+</Typography>
                                      </button>
                                    ),
                                    style: {
                                      height: "50px", // Adjust the height as needed
                                      padding: "0px",
                                      borderRadius: "20px",
                                      textAlign: "center",
                                      border: "none",
                                    },
                                  }}
                                  inputProps={{ style: { textAlign: "center" } }}
                                />
                                <Typography sx={{ color: "red", width: "100%", textAlign: "center" }}>{formError.ideal_candidate.number_of_open_positions}</Typography>
                              </Box>
                            </Box>
                          );
                        } else if (index === 1) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                                paddingLeft: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "40%",
                                  "&:hover": { cursor: "pointer" },
                                }}
                              >
                                <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                              </Box>
                              <Box>
                                <Autocomplete
                                  options={allRoles}
                                  onOpen={() => {
                                    setIdealCandidate((prev) => {
                                      return prev.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                  disabled={index === 1 ? false : true}
                                  popupIcon={index === 1 ? <ArrowDropDown /> : null}
                                  sx={{ width: "300px" }}
                                  clearIcon={null}
                                  getOptionLabel={(option) => option.name}
                                  value={item.selectedOptions.id === null ? null : item.selectedOptions}
                                  onChange={(event, newValue) => {
                                    handleCandidateChange(event, index, newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.ideal_candidate.select_role}</Typography>
                              </Box>
                            </Box>
                          );
                        } else if (index === 0) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                                paddingLeft: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "40%",
                                  "&:hover": { cursor: "pointer" },
                                }}
                              >
                                <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  placeholder={item.placeholder}
                                  sx={{ width: "300px" }}
                                  onFocus={() => {
                                    setIdealCandidate((prev) => {
                                      return prev.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                  value={item.value}
                                  onChange={(e) => handleCandidateChange(e, index)}
                                ></TextField>
                                <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.ideal_candidate.type_designation}</Typography>
                              </Box>
                            </Box>
                          );
                        } else if (index === 2) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                                paddingLeft: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "40%",
                                  "&:hover": { cursor: "pointer" },
                                }}
                              >
                                <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                              </Box>
                              <Box>
                                <Autocomplete
                                  options={item.allOptions}
                                  inputValue={item.inputvalue}
                                  includeInputInList
                                  onOpen={() => {
                                    setIdealCandidate((prev) => {
                                      return prev.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                  onInputChange={(e, newInputValue) => {
                                    let temp = [...idealCandidate];
                                    temp[index].inputvalue = newInputValue;
                                    setIdealCandidate(temp);
                                  }}
                                  disabled={index === 2 ? true : jobType === 1 ? true : false}
                                  popupIcon={index === 4 ? <ArrowDropDown /> : null}
                                  sx={{ width: "300px" }}
                                  clearIcon={null}
                                  getOptionLabel={(option) => option.name}
                                  value={item?.selectedOptions?.id === null ? null : item.selectedOptions}
                                  onChange={(event, newValue) => {
                                    handleCandidateChange(event, index, newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                {index === 4 && <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.ideal_candidate.location}</Typography>}
                              </Box>
                            </Box>
                          );
                        } else if (index === 4 && jobType !== 1) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                                paddingLeft: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "40%",
                                  "&:hover": { cursor: "pointer" },
                                }}
                              >
                                <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  type="text"
                                  sx={{
                                    width: "300px",
                                    "&:hover": {
                                      boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                                      borderRadius: "20px",
                                      border: "none",
                                    },
                                    textAlign: "center",
                                  }}
                                  onFocus={() => {
                                    setIdealCandidate((prev) => {
                                      return prev.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                  onChange={(e) => {
                                    handleCandidateChange(e, index);
                                  }}
                                  value={item.value}
                                />
                                {index === 4 && <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.ideal_candidate.location}</Typography>}
                              </Box>
                            </Box>
                          );
                        }
                      })}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <IconButton
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            "&:hover": { background: "#E6E6E6" },
                          }}
                          onClick={() => handleClick("prev")}
                        >
                          <ArrowBack />
                        </IconButton>
                        <button
                          style={{
                            background: "#165983",
                            color: "#fff",
                            padding: "10px 40px",
                            borderRadius: "15px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick("next")}
                        >
                          Next
                        </button>
                      </Box>
                    </div>
                  );
                } else if (step_index === 2) {
                  return (
                    <div
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: "60px",
                      }}
                    >
                      <div>
                        <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Review Job Description</Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            color: "#8B8B8B",
                          }}
                        >
                          Our AI has generated the following job description that closely matches the candidate profile requirement. You can customize it as per your requirements.
                        </Typography>
                      </div>
                      {jobDescription.map((item, index) => (
                        <Box
                          sx={{
                            borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                            paddingLeft: "20px",
                            height: "100%",
                          }}
                        >
                          {index !== 2 && (
                            <>
                              <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                            </>
                          )}
                          <Box sx={{ marginTop: "20px" }}>
                            {index === 0 ? (
                              <>
                                {jobType === 1 && (
                                  <Typography sx={{ color: Colors.primary, fontSize: "14px", textAlign: "right", textDecoration: "underline", marginBlock: 2, "&:hover": { cursor: "pointer" } }} onClick={() => setAreaofWork(true)}>
                                    What types of projects can MBA's work on?
                                  </Typography>
                                )}
                                <ReactQuill
                                  theme="snow"
                                  value={item.value}
                                  onChange={(e) => handleJobDescription(e, index, "", 3, item.value)}
                                  modules={{
                                    clipboard: {
                                      matchVisual: false,
                                    },
                                  }}
                                  onFocus={() => {
                                    setJobDescription((prev) => {
                                      return prev.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                />
                                <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.job_description.key_responsibilities}</Typography>
                              </>
                            ) : index === 1 ? (
                              <>
                                <ReactQuill
                                  theme="snow"
                                  value={item.value}
                                  onChange={(e) => handleJobDescription(e, index, "", 4)}
                                  modules={{
                                    clipboard: {
                                      matchVisual: false,
                                    },
                                  }}
                                  onFocus={() => {
                                    setJobDescription((prev) => {
                                      return prev.map((item, i) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            selected: true,
                                          };
                                        } else {
                                          return {
                                            ...item,
                                            selected: false,
                                          };
                                        }
                                      });
                                    });
                                  }}
                                />
                                <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.job_description.role_proficiencies}</Typography>
                              </>
                            ) : (
                              // <Box>
                              //   <Autocomplete
                              //   value={null}
                              //   options={allSkills.filter((ele) => !item.skillRequired.find((skill) => skill.id === ele.id))}
                              //   filterSelectedOptions={true}
                              //   onOpen={() => {
                              //     setJobDescription((prev) => {
                              //       return prev.map((item, i) => {
                              //         if (index === i) {
                              //           return {
                              //             ...item,
                              //             selected: true,
                              //           };
                              //         } else {
                              //           return {
                              //             ...item,
                              //             selected: false,
                              //           };
                              //         }
                              //       });
                              //     });
                              //   }}
                              //   // disableCloseOnSelect={true}
                              //   inputValue={inputValue}
                              //   getOptionLabel={(option) => option.name}
                              //   renderInput={(params) => <TextField {...params} label="Choose an option" />}
                              //   sx={{ width: "300px", marginBottom: "20px" }}
                              //   onKeyDown={(e) => handlePostNewSkill(e, index)}
                              //   onInputChange={(e, value) => {
                              //     setInputValue(value);
                              //
                              //   }}
                              //   onChange={(e, newValue) => handleJobDescription(e, index, newValue)}
                              //   noOptionsText="Add Skill"
                              // />

                              //    <Box
                              //   sx={{
                              //     display: "flex",
                              //     flexWrap: "wrap",
                              //     gap: "30px",
                              //   }}
                              // >
                              //   {item.skillRequired.map((option) => {
                              //     return (
                              //       <Box
                              //         style={{
                              //           padding: "10px 20px",
                              //           border: "1px solid #165983",
                              //           borderRadius: "25px",
                              //           background: "#D0DEE6",
                              //           display: "flex",
                              //           gap: "20px",
                              //           alignItems: "center",
                              //         }}
                              //       >
                              //         <Typography>{option.name}</Typography>
                              //         <IconButton
                              //           sx={{
                              //             border: "1px solid #8B8B8B",
                              //             padding: "3px",
                              //           }}
                              //           onClick={() => handleSkillDelete(option)}
                              //         >
                              //           <Close
                              //             sx={{
                              //               fontSize: "18px",
                              //               color: "#8B8B8B",
                              //             }}
                              //           />
                              //         </IconButton>
                              //       </Box>
                              //     );
                              //   })}
                              // </Box>
                              // </Box>
                              <></>
                            )}
                          </Box>
                        </Box>
                      ))}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <IconButton
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            "&:hover": { background: "#E6E6E6" },
                          }}
                          onClick={() => handleClick("prev")}
                        >
                          <ArrowBack />
                        </IconButton>
                        <button
                          style={{
                            background: "#165983",
                            color: "#fff",
                            padding: "10px 40px",
                            borderRadius: "15px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick("next")}
                        >
                          Next
                        </button>
                      </Box>
                    </div>
                  );
                } else if (step_index === 3) {
                  return (
                    <div
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: "60px",
                        marginTop: "20px",
                      }}
                    >
                      <div>
                        <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Selection Process</Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            color: "#8B8B8B",
                          }}
                        >
                          Save the hassle of assessing all applicants and use our Assessment tests to instantly identify top candidates for an interview directly.
                        </Typography>
                      </div>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                        <Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "24px", flex: 1 }}>Round-1: Assessments managed by CareerCarve</Typography>

                            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                              <Typography sx={{ color: "#165983", fontSize: "16px", fontWeight: 500, textDecoration: "underline", "&:hover": { cursor: "pointer" } }} onClick={() => setShowDescriptions((prev) => !prev)}>
                                How many candidates will I interview?
                              </Typography>
                              <Box sx={{ position: "absolute", background: "#fff", border: "1px solid black", width: "min(70%, 850px)", display: showDescriptions ? "" : "none", marginTop: "30px", padding: "1rem", zIndex: 999, boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)", borderRadius: "20px" }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                  <Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>Why choose assessments managed by CareerCarve?</Typography>
                                      <IconButton onClick={() => setShowDescriptions(false)}>
                                        <Cancel sx={{ fontSize: "20px" }} />
                                      </IconButton>
                                    </Box>
                                    <Typography sx={{ fontSize: "12px", marginLeft: "15px", marginBlock: "0.1rem 1rem" }}>CareerCarve helps you find the best talent by pre-vetting candidates and assessing their fit for your specific role. This ensures you get top qualified candidates that closely match your needs.</Typography>
                                  </Box>

                                  <Box>
                                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>What kind of assessments are considered for this role?</Typography>

                                    <Typography sx={{ fontSize: "12px", marginLeft: "15px", marginBlock: "0.1rem 1rem" }}>Candidates are assessed through 5 layers: role preparedness, quantitative, logical & verbal abilities, and workplace scenario response. Some assessments are mandatory, others optional.</Typography>
                                  </Box>

                                  <Box>
                                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>How is a candidate's competency measured?</Typography>

                                    <Typography sx={{ fontSize: "12px", marginLeft: "15px", marginBlock: "0.1rem 1rem" }}>Beyond scoring, we assess effort and drive through test variety, improvement tracking, and timely completion. This ensures we identify candidates truly committed to the opportunity.</Typography>
                                  </Box>

                                  <Box>
                                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>How is the shortlisting process done?</Typography>

                                    <Typography sx={{ fontSize: "12px", marginLeft: "15px", marginBlock: "0.1rem 1rem" }}>CareerCarve sends you shortlists in 3 phases after a significant number of candidates have completed their tests. While you will receive an update through email, the students will be notified about the same and will await their interview schedule.</Typography>
                                  </Box>

                                  <Box>
                                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>Will I be able to see who applied to my job?</Typography>

                                    <Typography sx={{ fontSize: "12px", marginLeft: "15px", marginBlock: "0.1rem 1rem" }}>Yes, as soon as we release the first set of shortlists, you will start seeing the list of applicants. If you find any potential matches, you can directly shortlist them. You must interview all shortlists in order to complete the hiring process.</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>The below are the recommended tests that checks the first few layers of the competency and gives you a list of top performing candidates.</Typography>
                        </Box>
                        {candidateCompetency.length > 0 ? (
                          candidateCompetency.map((item, index) => (
                            // item.id === 1 ? (
                            //   <></>
                            // ) :
                            <Box sx={{ display: "flex", gap: "20px" }} title={item.is_mandatory === 1 ? "This assessment test is an essential part for the CareerCarve team to shortlist the best candidates for the final interview of your job post and thus cannot be skipped." : ""}>
                              <CustomSwitch disabled={item.is_mandatory === 1 ? true : false} checked={item.value} onChange={(e) => handleCandidateCompetency(e, index)} />
                              <Box>
                                <Typography sx={{ fontSize: "16px" }}>{item.name.includes("SJT") ? "Situational Judgement Test" : item.name}</Typography>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    color: "#797979",
                                    textDecoration: "underline",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={() => {
                                    setSampleQuestions(item);
                                    setShowSampleQuestions(true);
                                  }}
                                >
                                  View Sample Questions
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "24px" }}>Round-2: Final Interview</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>CareerCarve will provide you with a set of shortlists depending on your requirement so all you have to do is take final interviews to fill that position.</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", marginTop: "24px" }}>
                          {/* <CustomSwitch checked={true} disabled />
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>
                          Final Interview Round
                        </Typography>
                      </Box> */}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <IconButton
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            "&:hover": { background: "#E6E6E6" },
                          }}
                          onClick={() => handleClick("prev")}
                        >
                          <ArrowBack />
                        </IconButton>
                        <button
                          style={{
                            background: "#165983",
                            color: "#fff",
                            padding: "10px 40px",
                            borderRadius: "15px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick("next")}
                        >
                          Next
                        </button>
                      </Box>
                    </div>
                  );
                } else if (step_index === 4) {
                  return (
                    <div
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: "40px",
                      }}
                    >
                      <div>
                        <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Let's Talk Numbers!</Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            color: "#8B8B8B",
                          }}
                        >
                          Select if you would like to incentivize the employee
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                          <Typography sx={{ color: Colors.primary, fontSize: "16px", textDecoration: "underline", fontStyle: "italic", "&:hover": { cursor: "pointer" } }} onClick={() => setOthersPaying((prev) => !prev)}>
                            What are others paying?
                          </Typography>

                          <Box sx={{ position: "absolute", zIndex: 999, display: othersPaying ? "" : "none", padding: "10px 20px 8px 20px", mt: "24px", border: "1px solid black", width: "30vw", background: "#fff" }}>
                            <RenderPerksDescription jobType={jobType} close={() => setOthersPaying(false)} />
                          </Box>
                        </Box>
                      </div>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "40px",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            border: "1px solid #DEDEDE",
                            borderRadius: "20px",
                            padding: "24px 40px",
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid rgba(22, 89, 131, 0.50)",
                              boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                            },
                            background: isPaid === 1 ? "#165983" : "#fff",
                          }}
                          onClick={() => {
                            setFormError((prev) => ({ ...prev, is_paid: "" }));
                            setIsPaid(1);
                            setChanged(true);
                          }}
                        >
                          <img src="https://du03hetco8xdw.cloudfront.net/recruiter/paid.svg" style={{ height: "200px", width: "100%" }} />
                          <Typography
                            sx={{
                              color: isPaid === 1 ? "#fff" : "#165983",
                              fontSize: "24px",
                              textAlign: "center",
                            }}
                          >
                            Paid
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            border: "1px solid #DEDEDE",
                            borderRadius: "20px",
                            padding: "24px 40px",
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid rgba(22, 89, 131, 0.50)",
                              boxShadow: "6px 15px 33px 0px rgba(22, 89, 131, 0.10)",
                            },
                            background: isPaid === 0 ? "#165983" : "#fff",
                          }}
                          onClick={() => {
                            setFormError((prev) => ({ ...prev, is_paid: "" }));
                            setIsPaid(0);
                            setChanged(true);
                          }}
                        >
                          <img src="https://du03hetco8xdw.cloudfront.net/recruiter/unpaid.svg" style={{ height: "200px", width: "100%" }} />
                          <Typography
                            sx={{
                              color: isPaid === 0 ? "#fff" : "#165983",
                              fontSize: "24px",
                              textAlign: "center",
                            }}
                          >
                            Unpaid
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <IconButton
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            "&:hover": { background: "#E6E6E6" },
                          }}
                          onClick={() => handleClick("prev")}
                        >
                          <ArrowBack />
                        </IconButton>
                        <button
                          style={{
                            background: isPaid === null ? "#8B8B8B" : "#165983",
                            color: "#fff",
                            padding: "10px 40px",
                            borderRadius: "15px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick("next")}
                          disabled={isPaid === null ? true : false}
                        >
                          Next
                        </button>
                      </Box>
                    </div>
                  );
                } else if (step_index === 5) {
                  return (
                    <div
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: "60px",
                      }}
                    >
                      <div>
                        <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Compensation and Perks</Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textAlign: "center",
                            color: "#8B8B8B",
                          }}
                        >
                          This allows the candidate to know what exactly they should expect for the work they would do.
                        </Typography>
                      </div>

                      <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                        {perks.map((item, index) => {
                          if (index === 0) {
                            if (isPaid === 1) {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "24px",
                                    borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                                      {index === 0 ? (
                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                          <Typography sx={{ color: Colors.primary, fontSize: "16px", textDecoration: "underline", fontStyle: "italic", "&:hover": { cursor: "pointer" } }} onClick={() => setOthersPaying((prev) => !prev)}>
                                            What are others paying?
                                          </Typography>

                                          <Box sx={{ position: "absolute", zIndex: 999, display: othersPaying ? "" : "none", padding: "10px 20px 8px 20px", mt: "24px", border: "1px solid black", width: "30vw", background: "#fff" }}>
                                            <RenderPerksDescription jobType={jobType} close={() => setOthersPaying(false)} />
                                          </Box>
                                        </Box>
                                      ) : (
                                        <> </>
                                      )}
                                    </Box>
                                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.description}</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", gap: "32px" }}>
                                    <Box>
                                      <Typography sx={{ fontSize: "16px", marginBottom: "8px" }}>Fixed Component</Typography>
                                      <TextField
                                        required
                                        autoFocus
                                        value={item.fixedPay}
                                        sx={{ width: "300px" }}
                                        onFocus={() => {
                                          setPerks((prev) => {
                                            return prev.map((item, i) => {
                                              if (index === i) {
                                                return {
                                                  ...item,
                                                  selected: true,
                                                };
                                              } else {
                                                return {
                                                  ...item,
                                                  selected: false,
                                                };
                                              }
                                            });
                                          });
                                        }}
                                        onChange={(e) => handlePerksChanges(e, index, "fixed")}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <Typography>&#8377;</Typography>
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <Typography
                                              sx={{
                                                color: "#8B8B8B",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {jobType === 3 ? "/annum" : "/month"}
                                            </Typography>
                                          ),
                                          style: {
                                            height: "50px", // Adjust the height as needed
                                          },
                                        }}
                                      />
                                      <Typography sx={{ color: "red", width: "100%", textAlign: "left" }}>{formError.compensation_and_perks.fixed_pay}</Typography>
                                    </Box>
                                    <Box>
                                      <Typography sx={{ fontSize: "16px", marginBottom: "8px" }}>Variable Component (optional)</Typography>
                                      <TextField
                                        sx={{ width: "300px" }}
                                        onChange={(e) => handlePerksChanges(e, index, "variable")}
                                        value={item.variablePay}
                                        onFocus={() => {
                                          setPerks((prev) => {
                                            return prev.map((item, i) => {
                                              if (index === i) {
                                                return {
                                                  ...item,
                                                  selected: true,
                                                };
                                              } else {
                                                return {
                                                  ...item,
                                                  selected: false,
                                                };
                                              }
                                            });
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <Typography>&#8377;</Typography>
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <Typography
                                              sx={{
                                                color: "#8B8B8B",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {jobType === 3 ? "/annum" : "/month"}
                                            </Typography>
                                          ),
                                          style: {
                                            height: "50px", // Adjust the height as needed
                                          },
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            }
                          } else {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "24px",
                                  borderLeft: item.selected ? "2px solid #165983" : "2px solid #E6E6E6",
                                  paddingLeft: "20px",
                                }}
                              >
                                <Box>
                                  <Typography sx={{ fontSize: "24px" }}>{item.label}</Typography>
                                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.description}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", gap: "32px" }}>
                                  <ReactQuill
                                    theme="snow"
                                    value={item.value}
                                    onChange={(e) => handlePerksChanges(e, index)}
                                    style={{ width: "100%", height: "100%" }}
                                    modules={{
                                      clipboard: {
                                        matchVisual: false,
                                      },
                                    }}
                                    onFocus={() => {
                                      setPerks((prev) => {
                                        return prev.map((item, i) => {
                                          if (index === i) {
                                            return {
                                              ...item,
                                              selected: true,
                                            };
                                          } else {
                                            return {
                                              ...item,
                                              selected: false,
                                            };
                                          }
                                        });
                                      });
                                    }}
                                  />
                                </Box>
                              </Box>
                            );
                          }
                        })}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <IconButton
                          sx={{
                            padding: "10px",
                            border: "1px solid #000",
                            "&:hover": { background: "#E6E6E6" },
                          }}
                          onClick={() => handleClick("prev")}
                        >
                          <ArrowBack />
                        </IconButton>
                        <button
                          style={{
                            background: "#165983",
                            color: "#fff",
                            padding: "10px 40px",
                            borderRadius: "15px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick("next")}
                        >
                          Next
                        </button>
                      </Box>
                    </div>
                  );
                } else if (step_index === 6) {
                  return (
                    <Box sx={{ display: "flex", overflow: "hidden", height: "100vh" }}>
                      <Box
                        sx={{
                          boxShadow: "-5px 0px 70px 0px rgba(0, 0, 0, 0.05) inset",
                          padding: "0px 24px",
                          width: "30%",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px", ":hover": { cursor: "pointer" } }} onClick={() => navigate("/Recruiter")}>
                          <img src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png" style={{ width: "30px", height: "30px", marginBlock: "1.5rem" }} />
                          <img src="https://du03hetco8xdw.cloudfront.net/recruiter/CareerCarve.png" style={{ width: "120px", height: "20px", marginBlock: "1.5rem" }} />
                        </Box>
                        <Typography sx={{ fontSize: "24px" }}>All Sections</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#7E7E7E" }}>Click on any section to edit</Typography>
                        {/* // <Button onClick={() => handleClick("prev")}>BAck</Button> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                            marginTop: "30px",
                          }}
                        >
                          {reviewSection.map((item, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                padding: "10px",

                                justifyContent: "space-between",
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onMouseOver={() => handleReviewSelection(item.stepIndex, "hovered")}
                              onMouseOut={() => handleReviewSelection(item.stepIndex, "mouseout")}
                              onClick={(e) => handleEditSection(e)}
                            >
                              <Box
                                sx={{ display: "flex" }}

                                // onClick={() => handleReviewSelection(item)}
                              >
                                <img src={item.icon} style={{ height: "fit-content" }} />
                                <Box sx={{ marginLeft: "12px" }}>
                                  <Typography sx={{ fontSize: "20px" }}>{item.label}</Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "#7E7E7E",
                                      width: "80%",
                                    }}
                                  >
                                    {item.description}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                {hoveredReviewSection === item.stepIndex ? (
                                  <EditOutlined
                                    sx={{
                                      color: "#165983",
                                      "&:hover": { cursor: "pointer" },
                                    }}
                                  />
                                ) : (
                                  <Done sx={{ color: "#1EBE70" }} />
                                )}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box sx={{ width: "70%", display: "flex", flexDirection: "column" }}>
                        <Box
                          sx={{
                            width: "100%",
                            padding: "3rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "32px",
                            overflow: "scroll",
                            zIndex: "10",
                            height: "90%",
                            scrollbarWidth: "none", // For Firefox
                            "&::-webkit-scrollbar": {
                              display: "none", // For Chrome, Safari, and Edge
                            },
                          }}
                        >
                          <IconButton onClick={() => setCloseModal(true)} sx={{ position: "absolute", top: 0, right: 10, "&:hover": { cursor: "pointer" } }}>
                            <HighlightOff sx={{ fontSize: "30px" }} />
                          </IconButton>
                          {jobTable_data.remarks !== null && jobTable_data.remarks !== "" && (
                            <Box sx={{ padding: "15px", background: "rgba(255, 14, 14, 0.05)", border: "1px solid #FF0E0E", boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)", borderRadius: "20px" }}>
                              <Typography sx={{ color: "#FF0E0E", fontSize: "16px" }}>Reason for placing On-Hold</Typography>
                              <Typography sx={{ fontSize: "14px" }}>{get_specialCharReplace(jobTable_data.remarks.remarks[jobTable_data.remarks.remarks.length - 1].remark)}</Typography>
                            </Box>
                          )}
                          <Box>
                            <Typography sx={{ fontSize: "32px" }}>{comapny}</Typography>
                            <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                              {jobType === 1 ? "Live Project Intern" : jobType === 2 ? "Summer Intern" : "Full Time Employee"} | {idealCandidate[0].value} | {idealCandidate[1].selectedOptions.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                            }}
                          >
                            <Box sx={{ display: "flex", gap: "20px" }}>
                              <LocationOn sx={{ color: "#545454" }} />
                              <Typography sx={{ fontSize: "16px", color: "#545454" }}>{idealCandidate[4].value}</Typography>
                            </Box>
                            {(![0, null, ""].includes(perks[0].fixedPay) || ![0, null, ""].includes(perks[0].variablePay)) && (
                              <Box sx={{ display: "flex", gap: "20px" }}>
                                <PaymentsOutlined sx={{ color: "#545454" }} />
                                <Box sx={{ display: "flex", gap: "8px" }}>
                                  {![0, null, ""].includes(perks[0].fixedPay) && (
                                    <Typography sx={{ fontSize: "16px", color: "#545454" }}>
                                      &#8377;{perks[0].fixedPay}
                                      {jobType === 3 ? "/annum" : "/month"}
                                    </Typography>
                                  )}
                                  {![0, null, ""].includes(perks[0].variablePay) && (
                                    <Typography sx={{ fontSize: "16px", color: "#545454" }}>
                                      | Variable: &#8377;{perks[0].variablePay}
                                      {jobType === 3 ? "/annum" : "/month"}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            )}
                            <Box sx={{ display: "flex", gap: "20px" }}>
                              <PeopleAltOutlined sx={{ color: "#545454" }} />
                              <Typography sx={{ fontSize: "16px", color: "#545454" }}>Hiring {idealCandidate[3].openings} candidates</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ width: "100%", wordBreak: "break-word" }}>
                            <Typography sx={{ fontSize: "24px" }}>Key Responsibilities</Typography>
                            <p>{makeTextBold(jobDescription[0].value)}</p>
                          </Box>
                          <Box sx={{ width: "100%", wordBreak: "break-word" }}>
                            <Typography sx={{ fontSize: "24px" }}>Role Proficiencies</Typography>
                            <p>{makeTextBold(jobDescription[1].value)}</p>
                          </Box>

                          <Box>
                            <Typography sx={{ fontSize: "24px" }}>Selection Process</Typography>
                            <Typography sx={{ fontSize: "16px", marginBottom: "10px", color: "#8B8B8B" }}>Round - 1: Assessments managed by CareerCarve</Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
                              {candidateCompetency
                                .filter((item) => item.value === true)
                                .map((option, option_index) => {
                                  return (
                                    <Box
                                      sx={{
                                        width: candidateCompetency.filter((item) => item.value === true).length % 2 === 0 ? "48%" : candidateCompetency.filter((item) => item.value === true).length - 1 === option_index ? "100%" : "48%",
                                        border: "1px solid #E6E6E6",
                                        marginBottom: "1%",
                                        padding: "15px",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <Typography sx={{ textAlign: "center", fontSize: "16px" }}>{option.name.includes("SJT") ? "Situational Judgement Test" : option.name}</Typography>
                                      <Typography
                                        sx={{
                                          textAlign: "center",
                                          color: "#797979",
                                          textDecoration: "underline",
                                          fontSize: "16px",
                                          "&:hover": {
                                            cursor: "pointer",
                                          },
                                        }}
                                        onClick={() => {
                                          setSampleQuestions(option);
                                          setShowSampleQuestions(true);
                                        }}
                                      >
                                        View Sample Questions
                                      </Typography>
                                    </Box>
                                  );
                                })}
                            </Box>
                          </Box>
                          {![null, ""].includes(perks[1].value) && (
                            <Box sx={{ width: "100%", wordBreak: "break-word" }}>
                              <Typography sx={{ fontSize: "24px", marginBottom: "10px" }}>Perks and Benefits</Typography>
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
                                <Typography>{makeTextBold(perks[1].value)}</Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ padding: "20px", width: "100%", display: "flex", justifyContent: "flex-end", boxShadow: "0px -6px 67px 0px rgba(0, 0, 0, 0.13)" }}>
                          <Button
                            onClick={() => handlePublish()}
                            sx={{
                              background: "#1EBE70",
                              color: "#fff",
                              padding: "8px 20px",
                              borderRadius: "15px",
                              "&:hover": {
                                background: "#1EBE70",
                                color: "#fff",
                              },
                            }}
                          >
                            Publish
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                }
              })}
            </Carousel>

            <Modal open={reviewModal} onClose={() => setReviewModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "rgba(0,0,0,0.08)",
                  width: "75%",
                  height: "70%",
                  border: "none",
                  padding: "10px",
                  borderRadius: "20px",
                  scrollbarWidth: "1px",
                }}
              >
                <RenderEditModal />
              </Box>
            </Modal>
            <Modal open={changedModal} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClose={() => setChangedModal(false)}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "rgba(0,0,0,0.08)",
                  padding: "30px",
                  borderRadius: "15px",
                }}
              >
                <Typography sx={{ fontSize: "24px" }}>You have some Unsaved Changes </Typography>
                <Box sx={{ display: "flex", gap: "40px", justifyContent: "center", marginTop: "20px" }}>
                  <button
                    style={{
                      padding: "10px 20px",
                      border: "1px solid #000",
                      borderRadius: "15px",
                      "&:hover": { background: "#E6E6E6" },
                    }}
                    onClick={() => setChangedModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      background: "#165983",
                      color: "#fff",
                      padding: "10px 40px",
                      borderRadius: "15px",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClick("next")}
                  >
                    Save
                  </button>
                </Box>
              </Box>
            </Modal>

            <Modal open={publishModal} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClose={() => setPublishModal(false)}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "rgba(0,0,0,0.08)",
                  padding: "30px",
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "min(50vw,700px)",
                }}
              >
                <img src="https://du03hetco8xdw.cloudfront.net/recruiter/Review+Svg.svg" style={{ width: "500px", height: "300px" }} />
                <Typography sx={{ fontSize: "24px", textAlign: "center" }}>Review Under Process </Typography>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B", textAlign: "center" }}>
                  Your Job Post is <span style={{ color: "red" }}>under review</span> . You will be notified via email once it goes live.{" "}
                </Typography>
                {[null, undefined, ""].includes(about_company) && (
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B", textAlign: "center" }}>
                    In the meantime,<span style={{ color: "#165983" }}> tell us a little more about your company</span> that can help increase the number of applications for your job post.
                  </Typography>
                )}
                {[null, undefined, ""].includes(about_company) && (
                  <Button
                    onClick={() => navigate("/Profile")}
                    sx={{
                      background: "#1EBE70",
                      color: "#fff",
                      padding: "8px 20px",
                      borderRadius: "15px",
                      marginBlock: "15px",
                      "&:hover": {
                        background: "#1EBE70",
                        color: "#fff",
                      },
                    }}
                  >
                    Complete Profile
                  </Button>
                )}
                {[null, undefined, ""].includes(about_company) ? (
                  <Button
                    sx={{ color: "#165983", background: "#fff", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", "&:hover": { background: "#fff", color: "#165983" }, textDecoration: "underline" }}
                    onClick={() => {
                      navigate("/Recruiter");
                    }}
                  >
                    Go to Dashboard
                  </Button>
                ) : (
                  <Button
                    sx={{ color: "#165983", border: "1px solid #165983", background: "#fff", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", "&:hover": { background: "#fff", color: "#165983" }, marginBlock: "10px" }}
                    onClick={() => {
                      navigate("/Recruiter");
                    }}
                  >
                    Go to Dashboard
                  </Button>
                )}
              </Box>
            </Modal>

            <Modal open={closeModal} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClose={() => setCloseModal(false)}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "rgba(0,0,0,0.08)",
                  padding: "30px",
                  borderRadius: "15px",
                  display: "flex",
                  width: "40vw",
                  flexDirection: "column",
                  gap: "10px",
                  // align,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography sx={{ fontSize: "24px" }}>
                    Are you sure you would like to <span style={{ color: "red" }}>exit</span>
                  </Typography>
                  <IconButton onClick={() => setCloseModal(false)}>
                    <HighlightOff sx={{ fontSize: "30px" }} />
                  </IconButton>
                </Box>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                  Your information for this job posting has been saved until this point. You will be able to complete this posting at your convenience, however, we recommend you<span style={{ color: "#165983" }}> complete it now.</span>
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Are you sure you would like to close and head to the dashboard?</Typography>
                <Button
                  sx={{ background: "#165983", color: "#fff", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", "&:hover": { color: "#fff", background: "#165983" } }}
                  onClick={() => {
                    setCloseModal(false);
                  }}
                >
                  Continue Posting
                </Button>
                <Button
                  sx={{ color: "#165983", background: "#fff", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", textDecoration: "underline", "&:hover": { background: "#fff", color: "#165983" } }}
                  onClick={() => {
                    navigate("/Recruiter");
                  }}
                >
                  Head to Dashboard
                </Button>
              </Box>
            </Modal>

            <Modal open={jobTypeModal} onClose={() => setJobTypeModal(false)}>
              <JobTypes close={() => setJobTypeModal(false)} tab={clickedJobType} />
            </Modal>
            <Modal open={areaofWork} onClose={() => setAreaofWork(false)}>
              <AreaOfWork close={() => setAreaofWork(false)} />
            </Modal>

            <Modal open={showSampleQuestions} onClose={() => setShowSampleQuestions(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ViewSampleQuestion close={() => setShowSampleQuestions(false)} sampleQuestion={sampleQuestions} />
            </Modal>
          </div>
        </Box>
      )}
    </>
  );
}

export default Jobs;

const RenderPerksDescription = ({ jobType, close }) => {
  switch (jobType) {
    case 1:
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontSize: "14px", color: Colors.primary, textDecoration: "underline", fontWeight: 600 }}>MBA Compensation Trends of Academic Year 2024-25</Typography>
            <IconButton onClick={() => close()}>
              <Close />
            </IconButton>
          </Box>
          <Typography sx={{ color: Colors.NeturalMidGrey, fontSize: "12px", textAlign: "justify" }}>
            Leverage hiring trends and college requirement insights to strategically <br /> attract the perfect talent for this role.
          </Typography>

          <Box sx={{ paddingInline: "20px", mt: "10px" }}>
            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>Less than 10% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 5000/month & above</Typography>
            </Box>
            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>60% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 3000/month </Typography>
            </Box>

            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>20% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 2000/month</Typography>
            </Box>

            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>Other 10% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 0/month</Typography>
            </Box>
          </Box>
        </Box>
      );
    case 2:
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "14px", color: Colors.primary, textDecoration: "underline", fontWeight: 600 }}>MBA Compensation Trends of Academic Year 2024-25</Typography>
            <IconButton onClick={() => close()}>
              <Close />
            </IconButton>
          </Box>

          <Typography sx={{ color: Colors.NeturalMidGrey, fontSize: "12px", textAlign: "justify" }}>Leverage hiring trends and college requirement insights to strategically attract the perfect talent for this role.</Typography>

          <Box sx={{ paddingInline: "20px", mt: "10px" }}>
            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>Less than 10% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 60,000/month & above</Typography>
            </Box>
            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>60% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 20,000 - 60,000/month</Typography>
            </Box>

            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>20% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 10,000 - 20,000/month</Typography>
            </Box>

            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>Other 10% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>Less than INR 10,000/month</Typography>
            </Box>
          </Box>
        </Box>
      );
    case 3:
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontSize: "14px", color: Colors.primary, textDecoration: "underline", fontWeight: 600 }}>MBA Compensation Trends of Academic Year 2024-25</Typography>
            <IconButton onClick={() => close()}>
              <Close />
            </IconButton>
          </Box>

          <Typography sx={{ color: Colors.NeturalMidGrey, fontSize: "12px", textAlign: "justify" }}>Leverage hiring trends and college requirement insights to strategically attract the perfect talent for this role.</Typography>

          <Box sx={{ paddingInline: "20px", mt: "10px" }}>
            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>Less than 10% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 18L CTC/annum & above</Typography>
            </Box>
            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>60% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 10-18 L CTC/annum</Typography>
            </Box>

            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>20% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>INR 5-10 L CTC/annum</Typography>
            </Box>

            <Box sx={{ display: "flex", mb: "5px" }}>
              <Typography sx={{ flex: 1, fontSize: "12px" }}>Other 10% of employers</Typography>
              <Typography sx={{ flex: 1, fontSize: "12px", color: Colors.NeturalMidGrey }}>Less than INR 5L CTC/annum</Typography>
            </Box>
          </Box>
        </Box>
      );

    default:
      break;
  }
};

const ViewSampleQuestion = ({ sampleQuestion, close }) => {
  let scenario;
  let question;
  let options;
  let data;
  if (sampleQuestion.test_type_id === 5) {
    scenario = sampleQuestion.questions.que;

    data = sampleQuestion.questions.questions.map((item, index) => {
      let options = Object.keys(item).filter((item) => item.includes("opt"));
      options = options.map((opt, index) => {
        return {
          value: item[opt],
          text: opt,
        };
      });
      return {
        index: item.index,
        question: item.question,
        options: options,
      };
    });
  } else {
    let optionsObject = Object.keys(sampleQuestion.questions);
    optionsObject = optionsObject.filter((item) => item.includes("opt"));
    options = optionsObject.map((item) => {
      return {
        value: sampleQuestion.questions[item],
        text: item,
      };
    });
  }

  const randomIndex = Math.floor(Math.random() * 4);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [readMore, setReadMore] = useState(false);

  const handleNext = () => {
    if (sampleQuestion.test_type_id === 5) {
      if (selectedIndex === data.length - 1) {
        close();
      } else {
        setSelectedIndex((prev) => prev + 1);
      }
    }
  };

  return (
    <Box sx={{ width: "60vw", background: "#fff", borderRadius: "20px", padding: "40px 30px", display: "flex", flexDirection: "column", gap: "30px" }}>
      <Box>
        {sampleQuestion.test_type_id === 5 ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px" }}>
                  Scenario: {scenario.length > 150 && !readMore ? `${scenario.slice(0, 150)}...` : `${scenario}`} <span onClick={() => setReadMore((prev) => !prev)}>{readMore ? "Read less" : "Read more"}</span>
                </Typography>
              </Box>
              <IconButton onClick={() => close()}>
                <Close />
              </IconButton>
            </Box>
            <Typography>Question: {data[selectedIndex].question}</Typography>
            <Box sx={{ gap: "20px", display: "flex", flexDirection: "column" }}>
              {data[selectedIndex].options.map((option, index) => (
                <Box
                  sx={{
                    borderRadius: "20px",
                    padding: "16px",
                    border: "1px solid black",
                    backgroundColor: randomIndex === index ? "rgba(245, 165, 54, 0.50)" : "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "16px" }}>{option.value}</Typography>
                  </Box>
                  {randomIndex === index && <Typography sx={{ fontSize: "14px", ml: "10px" }}>Clear Response</Typography>}
                </Box>
              ))}
            </Box>

            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBlock: "15px",
                }}
              >
                <button
                  style={{
                    border: "1px solid #1C8EA8",
                    padding: "12px 80px",
                    background: "#fff",
                    color: "#1C8EA8",
                    fontSize: "16px",
                    borderRadius: "20px",
                  }}
                >
                  Skip
                </button>
                <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                  <Typography sx={{ fontSize: "14px", color: "#969696" }}>
                    <span style={{ fontSize: "16px", color: "#1C8EA8" }}>14:27</span> for <span style={{ color: "#1C8EA8" }}>27</span>
                    more questions
                  </Typography>
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "500",
                    }}
                  >
                    End Test
                  </Typography>
                </Box>
                <button
                  style={{
                    background: "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                    padding: "12px 80px",
                    fontSize: "16px",
                    borderRadius: "20px",
                    border: "none",
                    color: "#fff",
                  }}
                  onClick={() => handleNext()}
                >
                  Next
                </button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "18px" }}>Question: {sampleQuestion.questions.que}</Typography>
              <IconButton onClick={() => close()}>
                <Close />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {options.map((option, index) => (
                <Box
                  sx={{
                    borderRadius: "20px",
                    padding: "16px",
                    border: "1px solid black",
                    backgroundColor: randomIndex === index ? "rgba(245, 165, 54, 0.50)" : "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "16px" }}>{option.value}</Typography>
                  </Box>
                  {randomIndex === index && <Typography sx={{ fontSize: "14px", ml: "10px" }}>Clear Response</Typography>}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBlock: "15px",
              }}
            >
              <button
                style={{
                  border: "1px solid #1C8EA8",
                  padding: "12px 80px",
                  background: "#fff",
                  color: "#1C8EA8",
                  fontSize: "16px",
                  borderRadius: "20px",
                }}
              >
                Skip
              </button>
              <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <Typography sx={{ fontSize: "14px", color: "#969696" }}>
                  <span style={{ fontSize: "16px", color: "#1C8EA8" }}>14:27</span> for <span style={{ color: "#1C8EA8" }}>27</span>
                  more questions
                </Typography>
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "500",
                  }}
                >
                  End Test
                </Typography>
              </Box>
              <button
                style={{
                  background: "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                  padding: "12px 80px",
                  fontSize: "16px",
                  borderRadius: "20px",
                  border: "none",
                  color: "#fff",
                }}
              >
                Next
              </button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
