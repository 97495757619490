import { Box, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Send, UnfoldMore, Close, CalendarTodayTwoTone } from "@mui/icons-material";
import { socket } from "../../../Socket";
import { useDispatch, useSelector } from "react-redux";
import { setChatPage } from "../../../store/reducers";
import TimeService from "../../../exportedFunctions/epochTime";
import { AnimatePresence, motion } from "framer-motion";
function StudentChatModal({ selectedStudent, close, rescheduleMeeting }) {
  const { user_id } = useSelector((store) => store.user);
  const time = new TimeService();
  const chat_section = useRef(null);
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [onExit, setOnexit] = useState(false);
  const handleMessageSend = async () => {
    if (!["", null].includes(textValue)) {
      const data = {
        text: textValue,
        user_id: user_id,
        student_job_map_id: selectedStudent.id,
      };
      socket.emit("sendJobMessage", data);
      setTextValue("");
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (chat_section.current) {
      chat_section.current.scrollTop = chat_section.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    socket.connect();

    socket.emit("findJobRoom", { student_job_map_id: selectedStudent.id }, (error) => {});

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    function receiveMessage(value) {
      setMessages(value);
    }
    socket.on("receiveJobMessage", receiveMessage);

    return () => {
      socket.off("receiveJobMessage", receiveMessage);
    };
  }, [messages]);

  useEffect(() => {
    function foundPreviousMessages(value) {
      let messages = value.chat?.messages ? value.chat?.messages : [];
      setMessages(messages);
      setLoading(false);
    }
    socket.on("foundJobRoom", foundPreviousMessages);

    return () => {
      socket.off("foundJobRoom", foundPreviousMessages);
    };
  }, []);

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      handleMessageSend();
    }
  };

  const handleExpand = () => {
    setOnexit(true);
    setTimeout(() => {
      close();
      dispatch(
        setChatPage({
          chatPage: true,
          chat_id: selectedStudent.id,
        })
      );
    }, 500);
  };
  return (
    <AnimatePresence>
      <motion.div style={{ background: "#fff", display: "flex", flexDirection: "column", gap: "20px", borderRadius: "20px", padding: "20px 20px", minWidth: "400px", position: "absolute" }} transition={{ duration: 0.3, type: "spring", delay: 0.1 }} initial={{ width: 0, height: 0, x: "100vw", y: "100vh", opacity: 0 }} animate={!onExit ? { width: "28vw", height: "70vh", x: "71vw", y: "30vh", opacity: 1 } : { width: 0, height: 0, x: "100vw", y: "100vh", opacity: 0 }}>
        <div style={{ background: "#F7F7F7", padding: "10px", display: "flex", justifyContent: "space-between", height: "20%" }}>
          <div>
            <Typography sx={{ color: "#165983", fontSize: "20px" }}>{selectedStudent.student_name}</Typography>
            <Typography sx={{ color: "#8B8B8B" }}>{selectedStudent.college}</Typography>
          </div>
          <div>
            <IconButton sx={{ background: "#fff", color: "#165983", marginRight: 2 }} onClick={() => rescheduleMeeting()}>
              <CalendarTodayTwoTone fontSize="20px" />
            </IconButton>
            <IconButton sx={{ transform: "rotate(-50deg)", background: "#fff", color: "#165983", marginRight: 2 }} onClick={() => handleExpand()}>
              <UnfoldMore fontSize="20px" />
            </IconButton>
            <IconButton
              onClick={() => {
                setOnexit(true);
                setTimeout(() => {
                  close();
                }, 400);
              }}
            >
              <Close fontSize="20px" />
            </IconButton>
          </div>
        </div>
        {loading ? (
          <Box sx={{ height: "70%" }}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={210} height={120} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", float: "right" }} width={210} height={120} />
          </Box>
        ) : (
          <Box
            ref={chat_section}
            sx={{
              padding: "10px",
              overflowY: "scroll",
              height: "100%",
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "15px",
                height: "30%",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#165983",
                borderRadius: "15px",
                backgroundClip: "padding-box",
              },
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track-piece:start": {
                marginTop: "10px",
              },
              "&::-webkit-scrollbar-track-piece:end": {
                marginBottom: "10px",
              },
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {messages.length > 0 &&
              messages.map((message) => (
                <div style={{ display: "flex", justifyContent: message.send_by === user_id ? "end" : "" }}>
                  <div style={{ padding: "10px", borderRadius: "10px", border: "1px solid #CCCCCC", maxWidth: "60%", background: message.send_by === user_id ? "linear-gradient(0deg, rgba(22, 89, 131, 0.20) 0%, rgba(22, 89, 131, 0.20) 100%), #FFF" : "" }}>
                    <Typography sx={{ fontSize: "12px" }}>{message.text}</Typography>

                    <Typography sx={{ fontSize: "9px", color: "#8B8B8B", textAlign: "right" }}>
                      {time.convertEpochToDateMonth(message.timestamp)}, {time.convertEpochToTime(message.timestamp)}
                    </Typography>
                  </div>
                </div>
              ))}
          </Box>
        )}

        <div style={{ display: "grid", gridTemplateColumns: "6fr 1fr", alignItems: "center", gap: "10px", maxHeight: "100px" }}>
          <Box
            sx={{
              paddingBlock: "5px",
              overflowY: "auto",
              maxHeight: "100%",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <TextField
              onChange={(e) => handleTextChange(e)}
              autoFocus={true}
              onKeyDown={(e) => handleKeyDown(e)}
              fullWidth
              multiline
              variant="outlined"
              sx={{
                fontSize: "16px",
                color: "#545454",
                borderRadius: "15px 15px 15px 15px",
                boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                border: "1.5px solid #165983",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "red",
                },
              }}
              InputProps={{
                sx: {
                  padding: "5px 8px",
                  borderRadius: "30px",
                  // boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)",
                  fontSize: "12px",
                },
              }}
              value={textValue}
            />
          </Box>

          <IconButton sx={{ background: "linear-gradient(0deg, rgba(22, 89, 131, 0.10) 0%, rgba(22, 89, 131, 0.10) 100%), #FFF", padding: "10px", width: "fit-content" }} onClick={() => handleMessageSend()}>
            <Send sx={{ color: "#165983", fontSize: "20px" }} />
          </IconButton>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default StudentChatModal;
