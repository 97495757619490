import { Close, Done, InfoOutlined, TempleBuddhistOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

function HiringStatusModal({ allStudents, close, handleAcceptReject }) {
  const [acceptedStudentList, SetAcceptedStudentList] = useState(allStudents.filter((item) => item.status_name === "Selected"));
  const [pendingStudents, setPendingStudents] = useState(allStudents.filter((item) => !["Selected", "Not selected", "Interview missed"].includes(item.status_name)));
  const [rejectedStudentList, SetRejectedStudentList] = useState(allStudents.filter((item) => item.status_name === "Not selected"));

  const [viewAll, setViewAll] = useState({
    selected: false,
    pending: false,
    rejected: false,
  });

  const handleViewAll = (type) => {
    let temp = { ...viewAll };
    temp[type] = !temp[type];
    setViewAll(temp);
  };

  return (
    <Box
      sx={{
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "50%",
        height: "60vh",
        borderRadius: "20px",
        padding: "40px",
        overflowY: "scroll",
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
          borderRadius: "15px",
          height: "30%",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#165983",
          borderRadius: "15px",
          backgroundClip: "padding-box",
        },
        "&::-webkit-scrollbar": {
          // display: "none",

          width: "4px",
        },
        "&::-webkit-scrollbar-track-piece:start": {
          marginTop: "10px",
        },
        "&::-webkit-scrollbar-track-piece:end": {
          marginBottom: "10px",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ color: "#165983", fontSize: "16px" }}>Student Selected</Typography>

        <IconButton onClick={() => close()} sx={{ height: "100%" }}>
          <Close sx={{ fontSize: "24px" }} />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {acceptedStudentList.length > 0 && (
          <Box sx={{ "&:hover": { cursor: "pointer" } }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "#1EBE70", fontSize: "16px" }}>Accepted</Typography>
              {acceptedStudentList.length > 2 && (
                <Typography sx={{ color: "#1EBE70", fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => handleViewAll("selected")}>
                  {viewAll["selected"] ? "View less" : "View all"}
                </Typography>
              )}
            </Box>
            <Box>
              {!viewAll["selected"]
                ? acceptedStudentList.slice(0, 2).map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #1EBE70", background: "rgba(30, 190, 112, 0.10)", alignItems: "center" }}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <Done sx={{ color: "#1EBE70" }} />
                    </Box>
                  ))
                : acceptedStudentList.map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #1EBE70", background: "rgba(30, 190, 112, 0.10)", alignItems: "center" }}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <Done sx={{ color: "#1EBE70" }} />
                    </Box>
                  ))}
            </Box>
          </Box>
        )}

        {pendingStudents.length > 0 && (
          <Box sx={{}}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "#F5A536", fontSize: "16px" }}>Pending</Typography>
              {pendingStudents.length > 2 && (
                <Typography sx={{ color: "#F5A536", fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => handleViewAll("pending")}>
                  {viewAll["pending"] ? "View less" : "View all"}
                </Typography>
              )}
            </Box>
            <Box>
              {!viewAll["pending"]
                ? pendingStudents.slice(0, 2).map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #F5A536", background: "rgba(245, 165, 54, 0.1)", marginBlock: "4px" }}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <InfoOutlined sx={{ color: "#F5A536" }} />
                    </Box>
                  ))
                : pendingStudents.map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #F5A536", background: "rgba(245, 165, 54, 0.1)", marginBlock: "4px" }}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <InfoOutlined sx={{ color: "#F5A536" }} />
                    </Box>
                  ))}
            </Box>
          </Box>
        )}

        {rejectedStudentList.length > 0 && (
          <Box sx={{ "&:hover": { cursor: "pointer" } }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "#FF0E0E", fontSize: "16px" }}>Rejected</Typography>
              {rejectedStudentList.length > 2 && <Typography sx={{ color: "#FF0E0E", fontSize: "16px", "&:hover": { cursor: "pointer" } }}> {viewAll["rejected"] ? "View less" : "View all"}</Typography>}
            </Box>
            <Box>
              {viewAll["rejected"]
                ? rejectedStudentList.map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #FF0E0E", background: "rgba(255, 14, 14, 0.10)" }} onClick={()=>handleAcceptReject(item)}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <Close sx={{ color: "#FF0E0E" }} />
                    </Box>
                  ))
                : rejectedStudentList.slice(0, 2).map((item) => (
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", border: "1px solid #FF0E0E", background: "rgba(255, 14, 14, 0.10)" }} onClick={()=>handleAcceptReject(item)}>
                      <Box>
                        <Typography sx={{ fontSize: "16px" }}>{item.student_name}</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{item.college_name}</Typography>
                      </Box>
                      <Close sx={{ color: "#FF0E0E" }} />
                    </Box>
                  ))}
            </Box>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{ color: "#fff", border: "1px solid #1EBE70", background: "#1EBE70", padding: "8px 15px", borderRadius: "15px", textTransform: "none", alignSelf: "center", "&:hover": { background: "#1EBE70", color: "#fff" } }}
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default HiringStatusModal;
