import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Service from "../../../services/httpService";
import { Close, UnfoldMore } from "@mui/icons-material";

function HiredModal({ close, job_id, job }) {
  const [loading, setLoading] = useState(true);
  const [hiredStudent, setHiredStudent] = useState([]);
  const services = new Service();

  const fetChJobData = async () => {
    setLoading(true);
    const studentGet = await services.get(`/jobs/recruiter/interviewApplicants?job_id=${job_id}`);

    const student = studentGet.get_shortlisted_student.filter((stu) => stu.status_name === "Selected");
    setHiredStudent(student);
    setLoading(false);
  };
  useEffect(() => {
    fetChJobData();
  }, []);
  return (
    <Box sx={{ width: "70vw", padding: "20px", borderRadius: "20px", background: "#fff" }}>
      <Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontSize: "24px", marginBlock: "10px", textAlign: "center", width: "100%" }}>
              {job.job_type_id === 1 ? "Live project Intern" : job.job_type_id === 2 ? "Summer intern" : "Full time Employee"} | {job.job_designation}{" "}
            </Typography>

            <IconButton sx={{ width: "fit-content" }} onClick={() => close()}>
              <Close />
            </IconButton>
          </Box>

          <Typography sx={{ fontSize: "18px", marginBlock: "10px" }}>Hired Candidates</Typography>
          <Typography sx={{ fontSize: "16px", marginBlock: "10px" }}>The acceptance mail has been sent to </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid #545454", paddingBlock: "16px" }}>
            <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#545454", fontSize: "16px" }}>Name</Typography>
              <UnfoldMore sx={{ color: "#545454" }} />
            </Box>

            <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#545454", fontSize: "16px" }}>University</Typography>
              <UnfoldMore sx={{ color: "#545454" }} />
            </Box>
            <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#545454", fontSize: "16px" }}>Email</Typography>
              <UnfoldMore sx={{ color: "#545454" }} />
            </Box>
            <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#545454", fontSize: "16px" }}>Contact Number</Typography>
              <UnfoldMore sx={{ color: "#545454" }} />
            </Box>
            <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#545454", fontSize: "16px" }}>Interview Insight</Typography>
              <UnfoldMore sx={{ color: "#545454" }} />
            </Box>
          </Box>
          {loading ? (
            <Loader />
          ) : (
            <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {hiredStudent.map((student) => (
                <>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                      <Typography sx={{ color: "#165983", fontSize: "16px" }}>{student.student_name}</Typography>
                    </Box>

                    <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                      <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.college}</Typography>
                    </Box>
                    <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                      <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.student_email_id.length > 17 ? `${student.student_email_id.slice(0, 20)}...` : student.student_email_id}</Typography>
                    </Box>
                    <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                      <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.student_phone_number}</Typography>
                    </Box>
                    <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                      <Typography sx={{ color: "#1EBE70", fontSize: "16px" }}>Hired</Typography>
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default HiredModal;

const Loader = () => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Skeleton width="100%" height="60px" sx={{ borderRadius: "15px" }} />
    <Skeleton width="100%" height="60px" sx={{ borderRadius: "15px" }} />
    <Skeleton width="100%" height="60px" sx={{ borderRadius: "15px" }} />
  </Box>
);
