import { ChatOutlined } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Service from "../services/httpService";
import { setChatPage, setCompanyDetails, setUser } from "../store/reducers";

function Navbar({ title, description }) {
  const { name, companyName, user_id, company_image, designation } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const services = new Service();
  const dispatch = useDispatch();
  useEffect(() => {
    const getUserDetails = async () => {
      const token = Cookies.get("token");
      if ([null, "null", undefined, "undefined"].includes(token)) {
        navigate("/");
      } else {
        const headers = {
          Authorization: `Bearer ${Cookies.get("token")}`,
        };
        if (user_id === null) {
          try {
            setLoading(true);
            const preUser = await services.get("/user/userDetail", { headers });
            localStorage.setItem("company", preUser.userDetail.company_name);

            if (preUser.success) {
              const getComapnyData = await services.get(`/jobs/recruiter/companyById?company_id=${preUser.userDetail.company_id}`);

              dispatch(
                setCompanyDetails({
                  website: getComapnyData.data.website_url ? getComapnyData.data.website_url : "",
                  linkedin: getComapnyData.data.linkedin ? getComapnyData.data.linkedin : "",
                  about_company: getComapnyData.data.about ? getComapnyData.data.about : "",
                  location: getComapnyData.data.location ? getComapnyData.data.location : "",
                  industry: getComapnyData.data.industry ? getComapnyData.data.industry : "",
                })
              );
            }

            dispatch(
              setUser({
                name: `${preUser.userDetail.first_name} ${preUser.userDetail.last_name}`,
                userType: preUser.user_type,
                user_id: preUser.userDetail.user_id,
                email: preUser.userDetail.email_id,
                companyName: preUser.userDetail.company_name,
                designation: preUser.userDetail.recruiter_designation,
                ph_no: preUser.userDetail.ph_no,
                company_id: preUser.userDetail.company_id,
                company_image: preUser.userDetail.company_image,
              })
            );
            setLoading(false);
          } catch (error) {
            Cookies.remove("token");
            navigate("/");
          }
        } else {
          setLoading(false);
        }
      }
    };
    getUserDetails();
  }, []);

  const handleChatOption = () => {
    dispatch(
      setChatPage({
        chatPage: true,
      })
    );
  };
  return !loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E6E6E6",
        padding: "1rem",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "32px", fontWeight: "500" }}>{title}</Typography>
        {description && <Typography sx={{ fontSize: "24", fontWeight: "500" }}>{description}</Typography>}
      </Box>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <IconButton style={{ color: "#165983", background: "#F2F2F2" }} onClick={() => handleChatOption()}>
          <ChatOutlined />
        </IconButton>
        <Box>
          <Typography sx={{ fontSize: "16px", textAlign: "right" }}>{name}</Typography>
          <Typography sx={{ fontSize: "14px", textAlign: "right", color: "#878787" }}>
            {designation}, {companyName}
          </Typography>
        </Box>
        {/* <AccountCircle sx={{ fontSize: "50px" }} /> */}
        <Box
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={() => {
            navigate("/profile");
          }}
        >
          {[null, undefined, ""].includes(company_image) ? (
            <Box sx={{ background: "#165983", borderRadius: "50%", height: "100%", width: "50px", display: "flex", alignItems: "center", justifyContent: "center", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/Profile")}>
              <Typography style={{ fontSize: "32px", textAlign: "center", color: "#fff", fontWeight: "500" }}>{companyName ? companyName[0].toUpperCase() : "A"}</Typography>
            </Box>
          ) : (
            <img src={company_image} style={{ height: 60, width: 60, borderRadius: "50%" }} />
          )}
        </Box>
      </Box>
    </div>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Skeleton height={60} width={300} sx={{ borderRadius: "15px" }} />
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Skeleton height={80} width={60} sx={{ borderRadius: "50%" }} />
        <Box>
          <Skeleton height={30} width={160} sx={{ borderRadius: "15px" }} />
          <Skeleton height={30} width={160} sx={{ borderRadius: "15px" }} />
        </Box>
        <Skeleton height={80} width={60} sx={{ borderRadius: "50%" }} />
      </Box>
    </Box>
  );
}

export default Navbar;
