import { Button, CircularProgress, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const MIN_DIM = 150;

function ImageCropper({ handleImageUpload, imageUrl, close, loading }) {
  const imageRef = useRef(null);

  const canvasRef = useRef(null);

  const [imagesrc, setImagesrc] = useState(imageUrl);
  const [crop, setCrop] = useState();

  const handleSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.addEventListener("load", () => {
      const imageUrl = reader.result?.toString() || "";

      setImagesrc(imageUrl);
    });

    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.target;
    const cropWidthInPercent = (MIN_DIM / width) * 100;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      1,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleSave = (image, canvas, crop) => {
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
    ctx.restore();
  };
  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const dataUrl = URL.createObjectURL(blob);
        setImagesrc(dataUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
        throw error; // Re-throw for error handling
      }
    };

    if (imageUrl !== "") {
      fetchImageData();
    }
  }, []);
  return (
    <motion.div style={{ background: "#fff", position: "absolute", padding: "1rem", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "20px", width: "44vw", height: "70vh", x: "28vw", y: "15vh" }}>
      <div style={{ flex: 6 }}>
        {imagesrc ? (
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
            <ReactCrop crop={crop} circularCrop keepSelection aspect={1} minWidth={MIN_DIM} onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}>
              <img ref={imageRef} src={imagesrc} alt="image" style={{ maxHeight: "50vh" }} onLoad={(e) => onImageLoad(e)} />
            </ReactCrop>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px", alignItems: "center" }}>
            <AccountCircleIcon sx={{ fontSize: "150px", color: "#8B8B8B" }} />
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: 2 }}>
        <label>
          <Typography sx={{ background: "#165983", color: "#fff", padding: "8px 10px", borderRadius: "15px", width: "fit-content", "&:hover": { cursor: "pointer" } }}>Upload New Image</Typography>
          <input type="file" accept="image/*" onChange={(e) => handleSelectFile(e)} style={{ display: "none" }} />
        </label>

        <div style={{ display: "flex", gap: "20px" }}>
          <Button onClick={() => close()} style={{ textTransform: "none", color: "#165983", background: "#fff", padding: "5px 20px", borderRadius: "15px", width: "fit-content", border: "1px solid #165983" }}>
            {" "}
            Cancel
          </Button>
          <Button
            onClick={async () => {
              handleSave(imageRef.current, canvasRef.current, convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height));
              const dataURL = canvasRef.current.toDataURL();

              handleImageUpload(dataURL);
            }}
            disabled={imagesrc === "" ? true : false}
            sx={{ background: "#165983", color: "#fff", padding: "5px 20px", borderRadius: "15px", width: "120px", "&.Mui-disabled": { background: "#8B8B8B", color: "#fff", border: "1px solid #8B8B8B" }, "&:hover": { background: "#165983", color: "#fff" } }}
          >
            {loading ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : "Save"}
          </Button>
        </div>
      </div>
      {crop && <canvas ref={canvasRef} style={{ objectFit: "contain", width: 150, height: 150, display: "none" }}></canvas>}
    </motion.div>
  );
}

export default ImageCropper;
