import { Close, Done, Star, StarOutline } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { get_specialCharReplace, send_specialCharReplace } from "../../../exportedFunctions/specialCharacterReplace";
import Service from "../../../services/httpService";

function HiringCandidate({ selectedStudent, close, updateList, handleViewProfile, selectedJobs, updateShortListedData, allStudents }) {
  const [student, setStudent] = useState(selectedStudent);
  const [rejectionRemark, setRejectionRemark] = useState("");
  const hiredStudent = allStudents.filter((item) => item.status_name === "Selected");
  const pendingDetails = allStudents.filter((item) => !["Selected", "Not Selected", "Interview missed"].includes(item.status_name));

  const services = new Service();
  const handleReject = () => {
    setStudent((prev) => {
      return {
        ...prev,
        status_name: "Not selected",
      };
    });
  };

  const handleAccept = () => {
    setStudent((prev) => {
      return {
        ...prev,
        status_name: "Selected",
      };
    });
  };

  const handleSaveAndNext = async () => {
    if (student.status_name !== "Selected" && student.rejection_remark === "") {
      setRejectionRemark("Rejection remark is required");
      return;
    } else {
      let studentData;
      if (student.status_name === "Selected") {
        studentData = {
          id: student.id,
          is_accepted: 1,
        };
      } else {
        studentData = {
          id: student.id,
          is_accepted: 0,
          rejection_remark: send_specialCharReplace(student.rejection_remark),
        };
      }

      const body = {
        job_id: selectedJobs.id,
        student_list_data: [studentData],
      };

      const putRequest = await services.put(`/jobs/recruiter/accRejStudent`, body);

      updateShortListedData(student.id, student);
      close();
    }
  };
  return (
    <Box sx={{ background: "#fff", display: "flex", flexDirection: "column", gap: "20px", width: "45%", height: "auto", borderRadius: "20px", padding: "40px" }}>
      <>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ color: "#165983", fontSize: "16px" }}>
            View Hired Details {hiredStudent.length}/{selectedJobs.openings}
          </Typography>

          <Typography sx={{ color: "#165983", fontSize: "16px", textAlign: "center" }}>View Pending Details: {pendingDetails.length}</Typography>

          <IconButton onClick={() => close()} sx={{ height: "100%" }}>
            <Close sx={{ fontSize: "24px" }} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Typography sx={{ fontSize: "20px", fontWeight: "" }}>{student.student_name}</Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <Typography style={{ color: "#8B8B8B", fontSize: "20px" }}>{student.college} | </Typography>
                <Typography sx={{ color: "#165983", fontSize: "14px", textDecoration: "underline", "&:hover": { cursor: "pointer" } }} onClick={() => handleViewProfile(student)}>
                  View Profile
                </Typography>
              </Box>
            </Box>
            <Box>
              {[1, 2, 3, 4, 5].map((item) => {
                if (student.interview_note.rating >= item) {
                  return <Star sx={{ color: "#F5A536" }} />;
                } else {
                  return <StarOutline sx={{ color: "#F5A536" }} />;
                }
              })}
            </Box>
          </Box>
          {![null, "", undefined].includes(student.interview_note.note) && (
            <Box>
              <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>Interview Notes</Typography>
              <Typography sx={{ overflowWrap: "break-word" }}>{get_specialCharReplace(student.interview_note.note)}</Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <Button sx={{ color: "#FF0E0E", border: "1px solid #FF0E0E", background: "rgba(255, 14, 14, 0.10)", padding: "8px 15px", borderRadius: "15px", textTransform: "none", width: "150px", "&:hover": { background: "rgba(255, 14, 14, 0.10)", color: "#FF0E0E" } }} onClick={() => handleReject()} disabled={student.status_name === "Not selected"}>
              Reject
            </Button>
            <Button sx={{ color: "#fff", border: "1px solid #1EBE70", background: "#1EBE70", padding: "3px 15px", borderRadius: "15px", textTransform: "none", width: "150px", "&:hover": { background: "#1EBE70", color: "#fff" } }} onClick={() => handleAccept()}>
              {student.status_name === "Selected" ? <Done /> : "Accept"}
            </Button>
          </Box>

          {(student.status_name === "Not selected" || student.status_name === "Selected") && (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px", flexDirection: "column", gap: "12px" }}>
              {student.status_name === "Not selected" && (
                <Box>
                  <Typography sx={{ fontSize: "16px" }}>Please input the reason for rejecting this candidate</Typography>
                  <TextField
                    value={[null, ""].includes(student.rejection_remark) ? "" : get_specialCharReplace(student.rejection_remark)}
                    sx={{ padding: 0, m: 0 }}
                    multiline
                    error={[null, ""].includes(rejectionRemark) ? false : true}
                    helperText={rejectionRemark}
                    placeholder="Describe reason to proceed..."
                    fullWidth
                    InputProps={{
                      style: { padding: "0.4rem", fontSize: "14px" },
                    }}
                    onChange={(e) => {
                      let temp = { ...student };
                      temp.rejection_remark = e.target.value;
                      setStudent(temp);
                    }}
                  />
                  <Typography sx={{ fontSize: "12px", color: "#8B8B8B" }}>Character count {[null, undefined].includes(student.rejection_remark) ? 0 : student.rejection_remark.length}/1000</Typography>
                </Box>
              )}
              <Button disabled={student.status === "rejected" ? ([null, ""].includes(student.rejection_remark) ? true : false) : false} sx={{ color: "#fff", border: "1px solid #165983", background: "#165983", padding: "8px 15px", borderRadius: "15px", textTransform: "none", width: "150px", alignSelf: "center", "&:hover": { background: "#165983", color: "#fff" } }} onClick={() => handleSaveAndNext()}>
                Save and Next
              </Button>
            </Box>
          )}
        </Box>
      </>
    </Box>
  );
}

export default HiringCandidate;
