import { HighlightOff, LocationOn, PaymentsOutlined, PeopleAltOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { makeTextBold } from "../../../exportedFunctions/htmlParser";
import { useSelector } from "react-redux";

function ViewPost({ jobDetails, close, allRoles, allSpecializations }) {
  const { comapnyName } = useSelector((store) => store.user);
  const jobItem = jobDetails.find((item) => item.showOptions === true);
  
  return (
    <div style={{ background: "#fff", height: "100%", width: "40%", borderRadius: "20px 0px 0px 20px", padding: "3rem 1rem 1rem 2rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          paddingRight: "1rem",
          overflow: "scroll",
          zIndex: "10",
          height: "100%",
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "15px",
            height: "30%",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#165983",
            borderRadius: "15px",
            backgroundClip: "padding-box",
          },
          "&::-webkit-scrollbar": {
            // display: "none",

            width: "4px",
          },
          "&::-webkit-scrollbar-track-piece:start": {
            marginTop: "10px",
          },
          "&::-webkit-scrollbar-track-piece:end": {
            marginBottom: "10px",
          },
        }}
      >
        <IconButton onClick={() => close()} sx={{ position: "absolute", top: 2, right: 10, "&:hover": { cursor: "pointer" } }}>
          <HighlightOff sx={{ fontSize: "30px" }} />
        </IconButton>
        {jobItem.job_status === "On hold" && jobItem.remarks !== null && jobItem.remarks !== "" && (
          <Box sx={{ padding: "15px", background: "rgba(255, 14, 14, 0.05)", border: "1px solid #FF0E0E", boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)", borderRadius: "20px" }}>
            <Typography sx={{ color: "#FF0E0E", fontSize: "16px" }}>Reason for placing On-Hold</Typography>
            <Typography sx={{ fontSize: "14px" }}>{jobItem.remarks.remarks[jobItem.remarks.remarks.length - 1].remark}</Typography>
          </Box>
        )}
        <Box>
          <Typography sx={{ fontSize: "32px" }}>{jobItem.job_designation}</Typography>
          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
            {jobItem.job_type_id === 1 ? "Live Project Intern" : jobItem.job_type_id === 2 ? "Summer Intern" : "Full Time Employee"} | {allRoles.find((ele) => ele.id === jobItem.role_id).name} | {allSpecializations.find((ele) => ele.id === jobItem.spe_id).name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <LocationOn sx={{ color: "#545454" }} />
            <Typography sx={{ fontSize: "16px", color: "#545454" }}>{jobItem.location}</Typography>
          </Box>
          {(![0, null, ""].includes(jobItem.variable_component) || ![0, null, ""].includes(jobItem.fixed_component)) && (
            <Box sx={{ display: "flex", gap: "20px" }}>
              <PaymentsOutlined sx={{ color: "#545454" }} />
              <Box sx={{ display: "flex", gap: "8px" }}>
                <Typography sx={{ fontSize: "16px", color: "#545454" }}>
                  &#8377;{jobItem.fixed_component}
                  {jobItem.job_type_id === 3 ? "/annum" : "/month"}
                </Typography>
                {![0, null, ""].includes(jobItem.variable_component) && (
                  <Typography sx={{ fontSize: "16px", color: "#545454" }}>
                    | Variable: &#8377;{jobItem.variable_component}
                    {jobItem.job_type_id.job_type_id === 3 ? "/annum" : "/month"}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", gap: "20px" }}>
            <PeopleAltOutlined sx={{ color: "#545454" }} />
            <Typography sx={{ fontSize: "16px", color: "#545454" }}>Hiring {jobItem.openings} candidates</Typography>
          </Box>
        </Box>
        <Box>
          <Typography sx={{ fontSize: "24px" }}>Key Responsibilities</Typography>
          <p>{makeTextBold(jobItem.jd.key_responsibilities)}</p>
        </Box>
        <Box>
          <Typography sx={{ fontSize: "24px" }}>Role Proficiencies</Typography>
          <p>{makeTextBold(jobItem.jd.role_proficiencies)}</p>
        </Box>

        <Box>
          <Typography sx={{ fontSize: "24px" }}>Selection Process</Typography>
          <Typography sx={{ fontSize: "16px", marginBottom: "10px", color: "#8B8B8B" }}>Round - 1: Assessments managed by CareerCarve</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
            {jobItem.assessment_name?.split(",").map((item, option_index) => (
              <Box
                sx={{
                  width: jobItem.assessment_name?.split(",").length % 2 === 0 ? "48%" : jobItem.assessment_name?.split(",").length - 1 === option_index ? "100%" : "48%",
                  border: "1px solid #E6E6E6",
                  marginBottom: "1%",
                  padding: "15px",
                  borderRadius: "15px",
                }}
              >
                <Typography sx={{ textAlign: "center", fontSize: "16px" }}>{item}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {![null, ""].includes(jobItem.perks) && (
          <Box>
            <Typography sx={{ fontSize: "24px", marginBottom: "10px" }}>Perks and Benefits</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
              <Typography>{makeTextBold(jobItem.perks)}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default ViewPost;
