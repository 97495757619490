// A function to replace special characters in a string for safe transmission or storage
export const send_specialCharReplace = (text) => {
  const replacedText = text
    .replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
    .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
    .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
    .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]" //removed because this doesnt create a problem
    .replace(/\\/g, "||") // Replace all backslashes (\) with the string "||"
    .replace(/&amp;/g, "&"); // Replace all occurrences of the string "&amp;" with an ampersand (&)
  // .replace(/&/g, "&amp;"); // Replace all ampersands (&) with the string "&amp;"
  // Return the modified string
  return replacedText;
};

// A function to replace the special characters back to their original form
export const get_specialCharReplace = (text) => {
  const replacedText = text
    .replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
    .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
    .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
    .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
    .replace(/\|\|/g, "\\") // Replace all occurrences of the string "||" with a backslash (\)
    .replace(/&amp;/g, "&"); // Replace all occurrences of the string "&amp;" with an ampersand (&)
  // Return the modified string
  return replacedText;
};
