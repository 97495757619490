import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Service from "../../services/httpService";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

function CreateNewPassword() {
  const [formInputs, setFormInputs] = useState({
    password: "",
    confirmPassword: "",
  });

  const services = new Service();
  const { email, token } = useParams();

  const [formError, setFormError] = useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleFormInputs = (e) => {
    setFormError({ password: "", confirmPassword: "" });
    const { name, value } = e.target;
    let tempValue = { ...formInputs };
    tempValue[name] = value;
    setFormInputs(tempValue);
  };

  const validate = () => {
    let flag = true;
    if (formInputs.password === "") {
      setFormError((prev) => {
        return {
          ...prev,
          password: "Enter a valid password",
        };
      });
      flag = false;
    }

    if (formInputs.confirmPassword === "") {
      setFormError((prev) => {
        return {
          ...prev,
          confirmPassword: "Confirm password is required",
        };
      });
      flag = false;
    } else if (formInputs.confirmPassword !== formInputs.password) {
      setFormError((prev) => {
        return {
          ...prev,
          confirmPassword: "Password did not match",
        };
      });
      flag = false;
    }

    return flag;
  };
  const handleSetPassword = async (e) => {
    if (validate()) {
      const body = {
        email: email,
        token: token,
        password: formInputs.password,
      };
      try {
        const postPassword = await services.post("/setPassword", body);

        Cookies.set("token", postPassword.headers.jwt, {
          expires: 182.5,
        });

        if (postPassword.status === 200) {
          navigate("/Jobs", { state: { job_id: null } });
        }
      } catch (err) {
        console.error("Error in setting password", err);
      }
    }
  };
  return (
    <Box sx={{ display: "flex", gap: "2rem", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          width: "100%",
          backgroundImage: "url('https://du03hetco8xdw.cloudfront.net/recruiter/cc_login_bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png"
          alt="background"
          style={{
            height: "150px",
            width: "150px",
            // top: "max(30%,200px)",
            // left: "20%",
          }}
        />
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/CareerCarve.png"
          alt="background"
          style={{
            height: "3rem",
            width: "15rem",
            // top: "max(52%,350px)",
            // left: "13%",
          }}
        />
        <p
          style={{
            top: "max(62%, 430px)",

            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "10%",
          gap: "1.5rem",
          padding: "0px 8rem",
        }}
      >
        <Typography sx={{ fontSize: "36px" }}>Set Password</Typography>
        <TextField type="password" label="Password" name="password" onChange={(e) => handleFormInputs(e)} helperText={formError.password} error={formError.password === "" ? false : true} sx={{ width: "70%" }} value={formInputs.password} />

        <TextField type="password" label="Confirm Password" name="confirmPassword" onChange={(e) => handleFormInputs(e)} helperText={formError.confirmPassword} error={formError.confirmPassword === "" ? false : true} sx={{ width: "70%" }} value={formInputs.confirmPassword} />
        <Button
          sx={{
            background: "linear-gradient(99deg, #2AA8C4 -9.01%, #38C1DF 103.32%)",
            color: "#fff",
            width: "70%",
            padding: "10px 20px",
            borderRadius: "15px",
          }}
          onClick={(e) => handleSetPassword()}
        >
          Set Password
        </Button>
      </Box>
    </Box>
  );
}

export default CreateNewPassword;
