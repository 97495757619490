import { Cancel, Close, Search, Star, StarOutline } from "@mui/icons-material";
import { UnfoldMore } from "@mui/icons-material/";
import { Autocomplete, Box, Button, Checkbox, IconButton, Modal, Skeleton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar";
import TimeService from "../../exportedFunctions/epochTime";
import Service from "../../services/httpService";
import AbsentModal from "./Modals/AbsentModal";
import DetailModal from "./Modals/DetailModal";
import MeetingInfo from "./Modals/MeetingInfo";
import NewHiringModal from "./Modals/NewHiringModal";
import ScheduleMeeting from "./Modals/ScheduleMeeting";
import StudentChatModal from "./Modals/StudentChatModal";
import RecruiterSidenavbar from "./RecruiterSidenavbar";
import HiringCandidate from "./Modals/HiringCandidate";
import SelectMultipleStudent from "./Modals/SelectMultipleStudent";
import HiringStatusModal from "./Modals/HiringStatusModal";

function Shortlists() {
  const [loading, setLoading] = useState(true);
  const [allRoles, setAllRoles] = useState([]);
  const [allSpecializations, setAllSpecializations] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState(null);
  const [shortListedStudents, setShortListedStudents] = useState([]);
  const [newListStudents, setNewListStudents] = useState(0);
  const [textValue, setTextValue] = useState("");
  const [filter, setFilter] = useState("all");
  const [pendingInterviews, setPendingInterviews] = useState(0);
  const [estimatedCount, setEstiamtedCount] = useState(0);
  const [filteredStudent, setFilteredStudent] = useState([]);
  const [disableResultButton, setDisableResultButton] = useState(true);
  const [absentList, setAbsentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [meetingInfoModal, setMeetingInfoModal] = useState(false);
  const [hiringCandidateModal, setHiringCandidateModal] = useState(false);
  const [scheduleMeeting, setScheduleMeeting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [absentModal, setAbsentModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const [viewPastActivity, setViewPastActivity] = useState(false);
  const [showDescriptions, setShowDescriptions] = useState(false);
  const [showHiringModal, setShowHiringModal] = useState(false);
  const services = new Service();
  const time = new TimeService();
  const location = useLocation();
  const [studentListLoader, setStudentListLoader] = useState(false);
  const [hireSingleStudent, setHireSingleStudent] = useState(false);
  const [notSchedule, setNotSchedule] = useState(false);
  const [selectMultipleStudentModal, setSelectMultipleStudentModal] = useState(false);
  const [completeProcess, setCompleteProcess] = useState(false);

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      const allPostedJobs = await services.get("/jobs/recruiter/allJobTable");
      const queryParams = new URLSearchParams(location.search);
      const job_id = queryParams.get("job_id");
      const hiring_modal = queryParams.get("hiring_modal");
      const roles = await services.get("/superAdmin/role");
      const specialization = await services.get("/superAdmin/specialization");
      setAllRoles(roles.data);
      setAllSpecializations(specialization.data);
      let filteredJobs = allPostedJobs.data.filter((item) => item.job_status === "Accept" || item.job_status === "Paused" || item.job_status === "Completed");
      setAllJobs(filteredJobs);
      if (filteredJobs.length > 0) {
        if ([null, undefined].includes(job_id)) {
          setSelectedJobs(filteredJobs[0]);
        } else {
          let temp_job = filteredJobs.findIndex((item) => item.id === parseInt(job_id));

          if ([null, undefined, -1].includes(temp_job)) {
            setSelectedJobs(filteredJobs[0]);
          } else {
            setSelectedJobs(filteredJobs[temp_job]);
          }
        }
      }

      setLoading(false);
    };

    getAllData();
  }, []);

  useEffect(() => {
    const getStudentData = async () => {
      if (selectedJobs !== null) {
        try {
          setStudentListLoader(true);

          const studentGet = await services.get(`/jobs/recruiter/interviewApplicants?job_id=${selectedJobs.id}`);
          const estimatedCount = await services.get(`/jobs/recruiter/estimatedUserCount?job_id=${selectedJobs.id}`);

          setEstiamtedCount(estimatedCount.estimated_Count);
          let newList = 1;
          let tempData = studentGet.get_shortlisted_student.map((stu) => {
            if (stu.list_number > newList) {
              newList = stu.list_number;
            }
            return {
              ...stu,
              checked: false,
              status_name: stu.status_name === "Waitlisted" ? "Shortlisted" : stu.status_name,
            };
          });
          setNewListStudents(newList);

          setShortListedStudents(tempData);
          setFilteredStudent(tempData);
          setStudentListLoader(false);
          setFilter("all");
          setTextValue("");
        } catch (error) {}
      }
    };

    getStudentData();
  }, [selectedJobs, refresh]);

  useEffect(() => {
    if (selectedJobs !== null && shortListedStudents.length > 0) {
      let count = shortListedStudents.filter((stu) => stu.status_name === "Interview scheduled" && new Date(parseInt(stu.interview_time) + 1800 * 1000) > new Date()).length;

      setPendingInterviews(count);

      // let scheduledInterview = shortListedStudents.filter((stu) => stu.status_name === "Interview scheduled").length;

      // if (scheduledInterview > 0) {
      //   console.log("Check 1");
      //   let allScheduled = shortListedStudents.filter((stu) => stu.status_name === "Interview scheduled" && new Date(parseInt(stu.interview_time) + 1800 * 1000) > new Date()).length; // Scheduled and Not Interviewd
      //   let date = new Date(selectedJobs.test_timing);
      //   date.setDate(date.getDate() + 1);
      //   let testCloseDate = date.toISOString().split("T")[0];
      //   const todayDate = new Date().toISOString().split("T")[0];
      //   if (allScheduled > 0) {
      //     console.log("Check 2");

      //     setDisableResultButton(true);
      //   } else if (todayDate >= testCloseDate) {
      //     console.log("Check 3");

      //     setDisableResultButton(false);
      //   } else {
      //     console.log("Check 4");

      //     setDisableResultButton(true);
      //   }
      // } else {
      //   setDisableResultButton(true);
      // }

      let scheduledInterview = shortListedStudents.filter((stu) => stu.status_name === "Selected").length;
      if (scheduledInterview > 0) {
        setDisableResultButton(false);
      } else {
        setDisableResultButton(true);
      }
    }
  }, [shortListedStudents, selectedJobs]);

  useEffect(() => {
    if (filter === "all") {
      let temp = [...shortListedStudents];

      if (!["", null].includes(textValue)) {
        temp = temp.filter((item) => item.student_name.toLowerCase().includes(textValue.toLowerCase()) || item.college.toLowerCase().includes(textValue.toLowerCase()) || item.student_email_id.toLowerCase().includes(textValue.toLowerCase()));
      }
      setFilteredStudent(temp);
    } else if (filter === "scheduled") {
      let temp = shortListedStudents.filter((item) => item.status_name === "Interview scheduled");
      if (!["", null].includes(textValue)) {
        temp = temp.filter((item) => item.student_name.toLowerCase().includes(textValue.toLowerCase()) || item.college.toLowerCase().includes(textValue.toLowerCase()) || item.student_email_id.toLowerCase().includes(textValue.toLowerCase()));
      }
      setFilteredStudent(temp);
    } else if (filter === "completed") {
      let temp = shortListedStudents.filter((item) => item.status_name === "Interview scheduled" && new Date(parseInt(item.interview_time) + 1800 * 1000) < new Date());
      if (!["", null].includes(textValue)) {
        temp = temp.filter((item) => item.student_name.toLowerCase().includes(textValue.toLowerCase()) || item.college.toLowerCase().includes(textValue.toLowerCase()) || item.student_email_id.toLowerCase().includes(textValue.toLowerCase()));
      }
      setFilteredStudent(temp);
    } else if (filter === "not_scheduled") {
      let temp = shortListedStudents.filter((item) => item.status_name === "Shortlisted");
      if (!["", null].includes(textValue)) {
        temp = temp.filter((item) => item.student_name.toLowerCase().includes(textValue.toLowerCase()) || item.college.toLowerCase().includes(textValue.toLowerCase()) || item.student_email_id.toLowerCase().includes(textValue.toLowerCase()));
      }
      setFilteredStudent(temp);
    }
  }, [filter, selectedJobs, textValue]);

  const handleMeetingInfo = (student) => {
    setSelectedStudent(student);
    setMeetingInfoModal(true);
  };

  const handleScheduleMeeting = (student) => {
    setSelectedStudent(student);
    setScheduleMeeting(true);
  };

  const handleCheckbox = (e, student, index) => {
    if (e.target.checked) {
      let temp = [...shortListedStudents];
      temp[index].checked = e.target.checked;
      setShortListedStudents(temp);
      let absentIds = absentList.map((item) => item.id);
      if (!absentIds.includes(student.id)) {
        let temp_absentList = [...absentList, student];
        setAbsentList(temp_absentList);
      }
    } else {
      let temp = [...shortListedStudents];
      temp[index].checked = e.target.checked;
      setShortListedStudents(temp);
      let temp_absentList = absentList.filter((item) => item.id !== student.id);
      setAbsentList(temp_absentList);
    }
  };

  const handleShareoption = async (student) => {
    try {
      if (
        navigator.share &&
        navigator.canShare({
          title: `${student.student_name}`,
          text: `${student.student_name}`,
          url: student.meeting_link,
        })
      ) {
        navigator.share({
          title: `${student.student_name}`,
          text: `${student.student_name}`,
          url: student.meeting_link,
        });
      }
    } catch (error) {
      window.alert(error.message);
    }
  };

  const checkCheckBoxDisabled = (student) => {
    let flag = false;
    if (student.status_name === "Interview missed") flag = true;
    if ([null, ""].includes(student.meeting_link)) flag = true;

    if (new Date(parseInt(student.interview_time) + 1800 * 1000) > new Date()) flag = true;

    return flag;
  };

  const handleMarkAbsent = (index, student) => {
    let temp = [...shortListedStudents];
    temp[index].checked = true;
    setShortListedStudents(temp);
    let temp_absentList = [student];
    setAbsentList(temp_absentList);
    setAbsentModal(true);
  };

  const handleCompleteProcess = () => {
    const body = {
      job_id: selectedJobs.id,
    };
    console.log("NDjkhs", body, selectedJobs);
    const post = services.put("/jobs/recruiter/jobComplete", body);

    let temp = { ...selectedJobs };
    temp["job_status"] = "Completed";
    setSelectedJobs(temp);

    let tempAllJobs = allJobs.map((item) => {
      if (item.job_id === selectedJobs.id) {
        return { ...item, job_status: "Completed" };
      } else {
        return { ...item };
      }
    });
    setAllJobs(tempAllJobs);
    setCompleteProcess(false);
  };
  return (
    <Box sx={{ minWidth: "1000px" }}>
      <RecruiterSidenavbar />

      {loading ? (
        <ShortListLoader />
      ) : (
        <>
          <Box
            sx={{
              padding: "2rem 9rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Navbar title="Shortlists" />
            {allJobs.length > 0 ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", flex: 6 }}>
                    <Typography sx={{ fontSize: "16px" }}>Job Posting</Typography>
                    <Autocomplete
                      options={allJobs}
                      value={selectedJobs}
                      getOptionLabel={(option) => {
                        let type = option.job_type_id === 1 ? "Live Project Intern" : option.job_type_id === 2 ? "Summer Intern" : "Full Time Employee";

                        return `${option.job_designation} | ${type}`;
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      includeInputInList
                      sx={{ width: "400px" }}
                      size="small"
                      clearIcon={null}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSelectedJobs(newValue);
                          setShowDescriptions(false);
                        }
                      }}
                    />

                    <Box sx={{ display: "flex", gap: "24px" }}>
                      {allJobs.length > 1 && <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>Suggestions</Typography>}

                      {allJobs.filter((item) => item.id !== selectedJobs.id).length > 3
                        ? allJobs
                            .filter((item) => item.id !== selectedJobs.id)
                            .slice(0, 3)
                            .map((job) => (
                              <Typography sx={{ color: "#165983", fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => setSelectedJobs(job)}>
                                {job.job_designation}
                              </Typography>
                            ))
                        : allJobs
                            .filter((item) => item.id !== selectedJobs.id)
                            .map((job) => (
                              <Typography sx={{ color: "#165983", fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => setSelectedJobs(job)}>
                                {job.job_designation}
                              </Typography>
                            ))}
                    </Box>
                  </Box>
                  <Box sx={{ flex: 2 }}>
                    {shortListedStudents.length > 0 && selectedJobs.job_status !== "Completed" && (
                      <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "flex-end" }}>
                        {/* {disableResultButton ? (
                          <Typography sx={{ fontSize: "16px" }}>
                            To start accepting/rejecting students,
                            <span style={{ color: "#FF725E" }}> Complete {pendingInterviews} </span>more interviews{" "}
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: "16px" }}>
                            Looks like you've completed all the interviews, make your
                            <span style={{ color: "#1EBE70" }}> final hiring decision now</span>
                          </Typography>
                        )} */}
                        <Button
                          sx={{
                            color: "#fff",
                            border: "1px solid #165983",
                            background: "#165983",
                            padding: "8px 15px",
                            borderRadius: "15px",
                            textTransform: "none",
                            width: "fit-content",
                            "&:hover": { background: "#165983", color: "#fff" },
                            "&.Mui-disabled": {
                              background: "#8B8B8B",
                              color: "#fff",
                              border: "1px solid #8B8B8B",
                            },
                          }}
                          onClick={() => setCompleteProcess(true)}
                          disabled={disableResultButton}
                        >
                          Complete Hiring Process
                        </Button>
                        {/* <Typography sx={{ fontSize: "16px", color: "#8B8B8B", textAlign: "right" }}>You can now start accepting or rejecting students</Typography> */}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "24px" }}>
                    {selectedJobs.job_type_id === 1 ? "Live Project Intern" : selectedJobs.job_type_id === 2 ? "Summer Intern" : "Full Time Employee"}: {selectedJobs.job_designation} | {allRoles.find((ele) => ele.id === selectedJobs.role_id).name} | {allSpecializations.find((ele) => ele.id === selectedJobs.spe_id).name}
                  </Typography>

                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ color: "#999999", fontSize: "16px", flex: 1 }}>Click on a candidate to view candidate details</Typography>
                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                      <Typography sx={{ color: "#165983", fontSize: "16px", fontWeight: 500, textDecoration: "underline", "&:hover": { cursor: "pointer" } }} onClick={() => setShowDescriptions((prev) => !prev)}>
                        How many candidates will I interview?
                      </Typography>
                      <Box sx={{ position: "absolute", background: "#fff", border: "1px solid black", width: "min(50%, 700px)", display: showDescriptions ? "" : "none", marginTop: "30px", padding: "1rem", zIndex: 999, boxShadow: "6px 15px 33px 0px rgba(0, 0, 0, 0.10)", borderRadius: "20px" }}>
                        <Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>How is the shortlisting process done?</Typography>
                            <IconButton onClick={() => setShowDescriptions(false)}>
                              <Cancel />
                            </IconButton>
                          </Box>
                          <Typography sx={{ fontSize: "14px", marginLeft: "15px", marginBlock: "0.5rem 2rem" }}>CareerCarve sends you shortlists in 3 phases after a significant number of candidates have completed their tests. While you will receive an update through email, the students will be notified about the same and will await their interview schedule.</Typography>
                        </Box>

                        <Box>
                          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Will I be able to see who applied to my job?</Typography>
                          <Typography sx={{ fontSize: "14px", marginLeft: "15px", marginTop: "0.5rem" }}>Yes, as soon as we release the first set of shortlists, you will start seeing the list of applicants. If you find any potential matches, you can directly shortlist them. You must interview all shortlists in order to complete the hiring process.</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <TextField
                  sx={{
                    borderRadius: "50px",
                    width: "100%",
                    fontSize: "20px",
                  }}
                  onChange={(e) => setTextValue(e.target.value)}
                  InputProps={{
                    startAdornment: <Search sx={{ marginRight: "10px" }} />,
                    sx: { borderRadius: "30px", height: "40px" },
                  }}
                  placeholder="Search for student/interview details"
                />

                {studentListLoader ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton sx={{ width: "100%", height: "200px", m: 0 }}></Skeleton>
                    <Skeleton sx={{ width: "100%", height: "200px", m: 0 }}></Skeleton>
                  </div>
                ) : (
                  <Box style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                    {selectedJobs.job_status === "Completed" ? (
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
                        <div>
                          {filteredStudent.filter((item) => item.status_name === "Selected").length > 0 ? (
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                              <Box sx={{ display: "flex", alignItems: "center", gap: "20px", borderBottom: "1px solid #545454", paddingBlock: "16px" }}>
                                <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                  <Typography sx={{ color: "#545454", fontSize: "16px" }}>Name</Typography>
                                  <UnfoldMore sx={{ color: "#545454" }} />
                                </Box>

                                <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                  <Typography sx={{ color: "#545454", fontSize: "16px" }}>University</Typography>
                                  <UnfoldMore sx={{ color: "#545454" }} />
                                </Box>
                                <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                  <Typography sx={{ color: "#545454", fontSize: "16px" }}>Email</Typography>
                                  <UnfoldMore sx={{ color: "#545454" }} />
                                </Box>
                                <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                  <Typography sx={{ color: "#545454", fontSize: "16px" }}>Contact Number</Typography>
                                  <UnfoldMore sx={{ color: "#545454" }} />
                                </Box>
                                <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                  <Typography sx={{ color: "#545454", fontSize: "16px" }}>Interview Insight</Typography>
                                  <UnfoldMore sx={{ color: "#545454" }} />
                                </Box>
                              </Box>
                              <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                {filteredStudent
                                  .filter((item) => item.status_name === "Selected")
                                  .map((student) => (
                                    <>
                                      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                        <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                          <Typography
                                            sx={{ color: "#165983", fontSize: "16px", textDecoration: "underline", "&:hover": { cursor: "pointer" } }}
                                            onClick={() => {
                                              setSelectedStudent(student);
                                              setDetailModal(true);
                                            }}
                                          >
                                            {student.student_name}
                                          </Typography>
                                        </Box>

                                        <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                          <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.college}</Typography>
                                        </Box>
                                        <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                          <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.student_email_id}</Typography>
                                        </Box>
                                        <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                          <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.student_phone_number}</Typography>
                                        </Box>
                                        <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
                                          <Typography sx={{ color: "#1EBE70", fontSize: "16px" }}>Hired</Typography>
                                        </Box>
                                      </Box>
                                    </>
                                  ))}
                              </Box>
                              <Box onClick={() => setViewPastActivity((prev) => !prev)}>
                                <Typography
                                  sx={{
                                    color: "#165983",
                                    textDecoration: "underline",

                                    textAlign: "right",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                >
                                  View Past Activity
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <Box>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                <img src="https://du03hetco8xdw.cloudfront.net/recruiter/nojob.svg" style={{ width: "50vw", height: "50vh" }} />
                                {shortListedStudents.filter((item) => item.status_name === "Selected").length > 0 ? (
                                  <Typography sx={{ textAlign: "center", fontSize: "24px" }}>
                                    <span style={{ color: "#165983" }}>Oops! No student found.</span> Trying Changing Filter
                                  </Typography>
                                ) : (
                                  <Typography>No student Hired</Typography>
                                )}
                              </div>
                            </Box>
                          )}
                        </div>
                        {viewPastActivity && (
                          <div style={{ padding: "10px", border: "1px solid #8B8B8B", boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)", borderRadius: "20px" }}>
                            {filteredStudent.length > 0 ? (
                              <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                      <Typography sx={{ color: "#545454", fontSize: "16px" }}>Name</Typography>
                                      <UnfoldMore sx={{ color: "#545454" }} />
                                    </Box>

                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Typography sx={{ color: "#545454", fontSize: "16px" }}>Interview Insight</Typography>
                                      <UnfoldMore sx={{ color: "#545454" }} />
                                    </Box>
                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>Interview Notes</Typography>
                                      <UnfoldMore sx={{ color: "#545454" }} />
                                    </Box>
                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>Interview Action</Typography>
                                      <UnfoldMore sx={{ color: "#545454" }} />
                                    </Box>
                                  </Box>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                  {filteredStudent
                                    .filter((item) => ![null, undefined, ""].includes(item.meeting_link))
                                    .map((student, index) => (
                                      <Box sx={{ display: "flex", gap: "20px", paddingBlock: "8px", borderRadius: "15px", background: student.status_name === "Interview missed" ? "rgba(0,0,0,0.05)" : "", alignItems: "center" }}>
                                        {/* <Checkbox value={student.checked} onChange={(e) => handleCheckbox(e, student, index)} disabled={student.status_name === "Interview missed" || [null, ""].includes(student.meeting_link)} /> */}
                                        <Box sx={{ width: "100%" }}>
                                          <div style={{ display: "flex", gap: "10px" }}>
                                            <Typography
                                              sx={{ color: "#165983", fontSize: "16px", width: "fit-content" }}
                                              // onClick={() => {
                                              //   setSelectedStudent(student);
                                              //   setDetailModal(true);
                                              // }}
                                            >
                                              {student.student_name}
                                            </Typography>
                                          </div>
                                          <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.college_name}</Typography>
                                          <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.college}</Typography>
                                        </Box>
                                        {student.interview_time === "Not Scheduled" ? (
                                          <Typography sx={{ width: "100%", color: "#545454", fontSize: "16px", textAlign: "center" }}>Not Scheduled</Typography>
                                        ) : (
                                          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                            <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>
                                              {time.convertEpochToDate(parseInt(student.interview_time))} {time.convertEpochToTime(parseInt(student.interview_time))}
                                            </Typography>
                                            {student.status_name === "Interview missed" ? <Typography sx={{ color: "#FF0E0E", fontSize: "16px", textAlign: "center", "&:hover": { cursor: "pointer" } }}>Student Absent</Typography> : <></>}
                                          </Box>
                                        )}
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          {student.interview_note.note === null ? (
                                            <Button
                                              sx={{
                                                color: "#165983",
                                                fontSize: "16px",
                                                textDecoration: "underline",
                                                textTransform: "none",
                                                "&:hover": { cursor: "pointer", background: "none", textDecoration: "underline" },
                                                "&.Mui-disabled": {
                                                  color: "#71797E",
                                                },
                                              }}
                                              onClick={() => {
                                                setSelectedStudent(student);
                                                setDetailModal(true);
                                              }}
                                              disabled={student.status_name === "Interview missed"}
                                            >
                                              Add Interview Notes
                                            </Button>
                                          ) : (
                                            <Box sx={{ display: "flex" }}>
                                              {[1, 2, 3, 4, 5].map((item) => {
                                                if (student.interview_note.rating >= item) {
                                                  return <Star sx={{ color: "#F5A536" }} />;
                                                } else {
                                                  return <StarOutline sx={{ color: "#F5A536" }} />;
                                                }
                                              })}
                                            </Box>
                                          )}
                                        </Box>

                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                          {[null, ""].includes(student.meeting_link) ? (
                                            <Button sx={{ color: "#165983", border: "1px solid #165983", background: "#fff", padding: "10px 15px", height: "fit-content", borderRadius: "15px", textTransform: "none", width: "150px" }} onClick={() => handleScheduleMeeting(student)}>
                                              Confirm Shortlist
                                            </Button>
                                          ) : (
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                              <Button
                                                sx={{
                                                  color: "#fff",
                                                  border: "1px solid #165983",
                                                  background: "#165983",
                                                  borderRadius: "15px",
                                                  textTransform: "none",
                                                  width: "150px",
                                                  padding: "10px 15px",
                                                  height: "fit-content",
                                                  "&:hover": { background: "#165983", color: "#fff" },
                                                  "&.Mui-disabled": {
                                                    background: "#8B8B8B",
                                                    color: "#fff",
                                                    border: "1px solid #8B8B8B",
                                                  },
                                                }}
                                                onClick={() => handleMeetingInfo(student)}
                                                disabled={student.status_name === "Interview missed"}
                                              >
                                                Meeting Info
                                              </Button>
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>
                                    ))}
                                </div>
                              </Box>
                            ) : (
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                <img src="https://du03hetco8xdw.cloudfront.net/recruiter/nojob.svg" style={{ width: "50vw", height: "50vh" }} />
                                <Typography>
                                  Looks like you <span style={{ color: "#165983" }}>haven't posted any job</span>
                                </Typography>
                                <Button sx={{ color: "#fff", background: "#165983", padding: "8px 30px", borderRadius: "18px", "&:hover": { color: "#fff", background: "#165983" } }}>Post new Job</Button>
                              </div>
                            )}
                          </div>
                        )}
                      </Box>
                    ) : (
                      <div>
                        {shortListedStudents.length > 0 ? (
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {pendingInterviews === 0 ? (
                                  <Typography sx={{ fontSize: "16px" }}>
                                    <span style={{ color: "#1EBE70" }}>All interviews have been completed. </span>You are all set to declare your final hiring decision.
                                  </Typography>
                                ) : (
                                  <Typography sx={{ fontSize: "16px" }}>
                                    Interview status:<span style={{ color: "#FF725E" }}>{pendingInterviews}</span> out of <span>{shortListedStudents.filter((item) => item.status_name === "Interview scheduled").length}</span> interview pending
                                  </Typography>
                                )}
                                <Box>
                                  <Typography sx={{ color: "#165983", fontSize: "16px", fontWeight: 500, textDecoration: "underline", "&:hover": { cursor: "pointer" } }} onClick={() => setShowHiringModal(true)}>
                                    View Hiring Status
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  padding: "10px",
                                  display: "flex",
                                  borderRadius: "15px",
                                  justifyContent: "space-between",
                                  boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                                  gap: "2.5rem",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "100%",
                                    background: filter === "all" ? "#FFD5CF" : "",
                                    padding: "8px",
                                    borderRadius: "10px",
                                    "&:hover": { cursor: "pointer" },
                                  }}
                                  onClick={() => setFilter("all")}
                                >
                                  <Typography sx={{ textAlign: "center" }}>All</Typography>
                                </Box>
                                <Box sx={{ width: "100%", background: filter === "scheduled" ? "#FFD5CF" : "", padding: "8px", borderRadius: "10px", "&:hover": { cursor: "pointer" } }} onClick={() => setFilter("scheduled")}>
                                  <Typography sx={{ textAlign: "center" }}>Scheduled</Typography>
                                </Box>{" "}
                                <Box sx={{ width: "100%", background: filter === "not_scheduled" ? "#FFD5CF" : "", padding: "8px", borderRadius: "10px", "&:hover": { cursor: "pointer" } }} onClick={() => setFilter("not_scheduled")}>
                                  <Typography sx={{ textAlign: "center" }}>Not Scheduled</Typography>
                                </Box>{" "}
                              </Box>

                              {absentList.length > 0 && (
                                <Box sx={{ display: "flex", gap: "20px" }}>
                                  <Button sx={{ color: "#FF0E0E", background: "rgba(255, 14, 14, 0.10)", border: "1px solid #FF0E0E", padding: "8px 15px", width: "fit-content", borderRadius: "15px", textTransform: "none", "&:hover": { background: "rgba(255, 14, 14, 0.10)" } }} onClick={() => setAbsentModal(true)}>
                                    Mark Selected Student Absent{" "}
                                  </Button>

                                  <Button sx={{ color: "#1EBE70", background: "background: rgba(30, 190, 112, 0.1)", border: "1px solid #1EBE70", padding: "8px 20px", width: "fit-content", borderRadius: "15px", textTransform: "none", "&:hover": { background: "background: rgba(30, 190, 112, 0.1)" } }} onClick={() => setSelectMultipleStudentModal(true)}>
                                    Accept
                                  </Button>
                                </Box>
                              )}
                            </div>
                            {filteredStudent.length > 0 ? (
                              <div>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "20px", marginLeft: "5%" }}>
                                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <Typography sx={{ color: "#545454", fontSize: "16px" }}>Name</Typography>
                                    <UnfoldMore sx={{ color: "#545454" }} />
                                  </Box>

                                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography sx={{ color: "#545454", fontSize: "16px" }}>Interview Insight</Typography>
                                    <UnfoldMore sx={{ color: "#545454" }} />
                                  </Box>
                                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>Interview Notes</Typography>
                                    <UnfoldMore sx={{ color: "#545454" }} />
                                  </Box>
                                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>Interview Action</Typography>
                                    <UnfoldMore sx={{ color: "#545454" }} />
                                  </Box>
                                </Box>
                                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                  {filteredStudent
                                    .filter((item) => !["Selected", "Not selected"].includes(item.status_name))
                                    .map((student, index) => (
                                      <Box sx={{ display: "flex", gap: "20px", paddingBlock: "8px", borderRadius: "15px", background: student.status_name === "Interview missed" ? "rgba(0,0,0,0.05)" : "", alignItems: "center" }}>
                                        <Checkbox value={student.checked} onChange={(e) => handleCheckbox(e, student, index)} disabled={checkCheckBoxDisabled(student)} />
                                        <Box sx={{ width: "100%" }}>
                                          <div style={{ display: "flex", gap: "10px" }}>
                                            <Typography
                                              sx={{ color: "#165983", fontSize: "16px", "&:hover": { cursor: "pointer" }, width: "fit-content" }}
                                              onClick={() => {
                                                if (student.status_name !== "Interview missed") {
                                                  setSelectedStudent(student);
                                                  setDetailModal(true);
                                                }
                                              }}
                                            >
                                              {student.student_name}
                                            </Typography>
                                            {student.list_number === newListStudents && <Typography sx={{ fontSize: "12px", width: "fit-content", background: "rgba(245, 165, 54, 0.40)", border: "0.745px solid #F5A536", padding: "2px 8px", borderRadius: "20px" }}>New</Typography>}
                                          </div>
                                          <Typography sx={{ color: "#545454", fontSize: "16px" }}>{student.college_name}</Typography>
                                          <Typography
                                            sx={{ color: "#165983", fontSize: "16px", "&:hover": { cursor: "pointer" }, width: "fit-content", textDecoration: "underline" }}
                                            onClick={() => {
                                              if (student.status_name === "Interview scheduled") {
                                                setSelectedStudent(student);
                                                setChatModal(true);
                                              } else {
                                                setNotSchedule(true);
                                              }
                                            }}
                                          >
                                            Chat Now
                                          </Typography>
                                        </Box>
                                        {student.interview_time === "Not Scheduled" ? (
                                          <Typography sx={{ width: "100%", color: "#545454", fontSize: "16px", textAlign: "center" }}>Not Scheduled</Typography>
                                        ) : (
                                          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                            <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>
                                              {time.convertEpochToDate(parseInt(student.interview_time))} {time.convertEpochToTime(parseInt(student.interview_time))}
                                            </Typography>
                                            {student.status_name === "Interview missed" ? (
                                              <Typography sx={{ color: "#FF0E0E", fontSize: "16px", textAlign: "center", "&:hover": { cursor: "pointer" } }}>Student Absent</Typography>
                                            ) : (
                                              <Typography sx={{ color: "#165983", fontSize: "16px", textAlign: "center", "&:hover": { cursor: "pointer" } }} onClick={() => handleScheduleMeeting(student)}>
                                                Edit
                                              </Typography>
                                            )}
                                          </Box>
                                        )}
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          {student.interview_note.note === null ? (
                                            <Button
                                              sx={{
                                                color: "#165983",
                                                fontSize: "16px",
                                                textDecoration: "underline",
                                                textTransform: "none",
                                                "&:hover": { cursor: "pointer", background: "none", textDecoration: "underline" },
                                                "&.Mui-disabled": {
                                                  color: "#71797E",
                                                },
                                              }}
                                              onClick={() => {
                                                setSelectedStudent(student);
                                                setDetailModal(true);
                                              }}
                                              disabled={student.status_name === "Interview missed"}
                                            >
                                              Add Interview Notes
                                            </Button>
                                          ) : (
                                            <Box sx={{ display: "flex" }}>
                                              {[1, 2, 3, 4, 5].map((item) => {
                                                if (student.interview_note.rating >= item) {
                                                  return <Star sx={{ color: "#F5A536" }} />;
                                                } else {
                                                  return <StarOutline sx={{ color: "#F5A536" }} />;
                                                }
                                              })}
                                            </Box>
                                          )}
                                        </Box>

                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                          {[null, ""].includes(student.meeting_link) ? (
                                            <Button sx={{ color: "#165983", border: "1px solid #165983", background: "#fff", padding: "10px 15px", height: "fit-content", borderRadius: "15px", textTransform: "none", width: "150px" }} onClick={() => handleScheduleMeeting(student)}>
                                              Confirm Shortlist
                                            </Button>
                                          ) : (
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                              <Button
                                                sx={{
                                                  color: "#fff",
                                                  border: "1px solid #165983",
                                                  background: "#165983",
                                                  borderRadius: "15px",
                                                  textTransform: "none",
                                                  width: "150px",
                                                  padding: "10px 15px",
                                                  height: "fit-content",
                                                  "&:hover": { background: "#165983", color: "#fff" },
                                                  "&.Mui-disabled": {
                                                    background: "#8B8B8B",
                                                    color: "#fff",
                                                    border: "1px solid #8B8B8B",
                                                  },
                                                }}
                                                onClick={() => {
                                                  if (parseInt(student.interview_time) + 1800 * 1000 > Date.now()) {
                                                    handleMeetingInfo(student);
                                                  } else {
                                                    setSelectedStudent(student);
                                                    setHireSingleStudent(true);
                                                  }
                                                }}
                                                disabled={student.status_name === "Interview missed"}
                                              >
                                                {parseInt(student.interview_time) + 1800 * 1000 > Date.now() ? "Meeting Info" : "Declare Result"}
                                              </Button>

                                              {parseInt(student.interview_time) + 1800 * 1000 < Date.now() && (
                                                <Button
                                                  sx={{
                                                    color: "red",

                                                    // background: "#165983",
                                                    borderRadius: "15px",
                                                    textTransform: "none",
                                                    width: "150px",
                                                    padding: "10px 15px",
                                                    height: "fit-content",
                                                    "&:hover": { color: "red" },
                                                  }}
                                                  onClick={() => handleMarkAbsent(index, student)}
                                                  disabled={student.status_name === "Interview missed"}
                                                >
                                                  Student Absent?
                                                </Button>
                                              )}
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>
                                    ))}
                                </div>
                              </div>
                            ) : (
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                <img src="https://du03hetco8xdw.cloudfront.net/recruiter/nojob.svg" style={{ width: "50vw", height: "50vh" }} />
                                <Typography sx={{ fontSize: "32px", textAlign: "center" }}>No students available</Typography>
                                <Typography sx={{ fontSize: "16px", textAlign: "center", color: "#8B8B8B" }}>Kindly change your filter to view the students</Typography>
                              </div>
                            )}
                          </Box>
                        ) : (
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                            <img src="https://du03hetco8xdw.cloudfront.net/recruiter/nojob.svg" style={{ width: "50vw", height: "50vh" }} />
                            <Typography sx={{ fontSize: "32px", textAlign: "center" }}>No students available</Typography>
                            <Typography sx={{ fontSize: "16px", textAlign: "center", color: "#8B8B8B" }}>
                              You will be able to view all the shortlisted students for your job posting here. We will be releasing the shortlist soon.
                              <br /> Thank you for your patience
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  </Box>
                )}
              </Box>
            ) : (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                <img src="https://du03hetco8xdw.cloudfront.net/recruiter/nojob.svg" style={{ width: "50vw", height: "50vh" }} />
                <Typography>Currently no job is Live</Typography>
                <Button sx={{ color: "#fff", background: "#165983", padding: "8px 30px", borderRadius: "18px", "&:hover": { color: "#fff", background: "#165983" } }}>Post new Job</Button>
              </div>
            )}
          </Box>
          <Modal open={meetingInfoModal} onClose={() => setMeetingInfoModal(false)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <MeetingInfo meetingLink={selectedStudent ? selectedStudent.meeting_link : ""} close={() => setMeetingInfoModal(false)} />
          </Modal>

          <Modal open={hiringCandidateModal} onClose={() => setHiringCandidateModal(false)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <NewHiringModal
              studentList={JSON.parse(JSON.stringify(shortListedStudents.filter((stu) => stu.status_name === "Interview scheduled")))}
              close={() => setHiringCandidateModal(false)}
              updateShortListedDat={(data) => {
                let temp_data = data.map((item) => {
                  return {
                    ...item,
                    status_name: data.includes(item.id) ? "Interview scheduled" : item.status_name,
                  };
                });
                setShortListedStudents(temp_data);
                setSelectedJobs((prev) => {
                  return {
                    ...prev,
                    job_status: "Completed",
                  };
                });
              }}
              selectedJobs={selectedJobs}
              handleViewProfile={(student) => {
                setSelectedStudent(student);
                setDetailModal(true);
              }}
            />
          </Modal>
          <Modal open={scheduleMeeting} onClose={() => setScheduleMeeting(false)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <ScheduleMeeting
              student={selectedStudent ? selectedStudent : null}
              close={() => setScheduleMeeting(false)}
              shortListedStudents={shortListedStudents}
              updateShortListedStudent={(data) => {
                setShortListedStudents(data);
                setFilteredStudent(data);
              }}
            />
          </Modal>
          <Modal open={absentModal} onClose={() => setAbsentModal(false)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <AbsentModal
              close={() => setAbsentModal(false)}
              absentList={absentList}
              allShortlistedStudent={JSON.parse(JSON.stringify(shortListedStudents))}
              updateShortListedStudent={(data) => {
                setShortListedStudents(data);
                setFilteredStudent(data);
                setRefresh((prev) => !prev);
                setAbsentList([]);
              }}
            />
          </Modal>

          <Modal open={detailModal} onClose={() => setDetailModal(false)}>
            <DetailModal
              close={() => setDetailModal(false)}
              allShortlistedStudent={JSON.parse(JSON.stringify(shortListedStudents))}
              selectedStudent={JSON.parse(JSON.stringify(selectedStudent))}
              updateShortListedStudent={(data) => {
                setShortListedStudents(data);
                setFilteredStudent(data);
              }}
              updateSelectedStudent={(data) => setSelectedStudent(data)}
              editMeetingInfo={() => {
                setScheduleMeeting(true);
                setDetailModal(false);
              }}
            />
          </Modal>

          <Modal open={chatModal} onClose={() => setChatModal(false)}>
            <StudentChatModal
              close={() => setChatModal(false)}
              selectedStudent={selectedStudent}
              rescheduleMeeting={() => {
                setScheduleMeeting(true);
              }}
            />
          </Modal>

          <Modal open={hireSingleStudent} onClose={() => setHireSingleStudent(false)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <HiringCandidate
              selectedStudent={selectedStudent}
              allStudents={JSON.parse(JSON.stringify(shortListedStudents))}
              close={() => setHireSingleStudent(false)}
              selectedJobs={selectedJobs}
              handleViewProfile={(student) => {
                setSelectedStudent(student);
                setDetailModal(true);
              }}
              updateShortListedData={(data, item) => {
                let temp_data = shortListedStudents.map((student) => {
                  if (student.id === data) {
                    return {
                      ...student,
                      status_name: student.status_name,
                    };
                  } else {
                    return {
                      ...student,
                    };
                  }
                });

                setShortListedStudents(temp_data);
                setFilteredStudent(temp_data);
                setRefresh((prev) => !prev);
              }}
            />
          </Modal>
          <Modal open={selectMultipleStudentModal} onClose={() => setSelectMultipleStudentModal(false)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <SelectMultipleStudent
              close={() => setSelectMultipleStudentModal(false)}
              absentList={absentList}
              allShortlistedStudent={JSON.parse(JSON.stringify(shortListedStudents))}
              selectedJobs={selectedJobs}
              updateShortListedStudent={(data) => {
                setShortListedStudents(data);
                setFilteredStudent(data);
                setRefresh((prev) => !prev);
                setAbsentList([]);
              }}
            />
          </Modal>
          <Modal open={notSchedule} onClose={() => setNotSchedule(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ width: "40vw", height: "30vh", background: "#fff", padding: "40px 20px 20px 20px", borderRadius: "20px", display: "flex", alignItems: "center", flexDirection: "column", gap: "20px" }}>
              <Typography>Kindly schedule the interview to begin a conversation with the candidate.</Typography>
              <Button sx={{ width: "150px", padding: "8px 15px", borderRadius: "15px", background: "#165983", color: "#fff", border: "1px solid #165983", "&:hover": { background: "#165983", color: "#fff" } }} onClick={() => setNotSchedule(false)}>
                Close
              </Button>
            </Box>
          </Modal>

          <Modal open={completeProcess} onClose={() => setCompleteProcess(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ width: "40vw", height: "auto", background: "#fff", padding: "40px 20px 20px 20px", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography>
                  Are you sure you would like to <span style={{ color: "#1EBE70" }}>Complete the process</span>?
                </Typography>

                <IconButton onClick={() => setCompleteProcess(false)}>
                  <Close />
                </IconButton>
              </Box>

              <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>This step will mark your job post as completed and update status for all candidates. Are you sure you want to end the process? </Typography>
              <Button sx={{ width: "fit-content", textTransform: "none", padding: "8px 15px", borderRadius: "15px", background: "#1EBE70", color: "#fff", border: "1px solid #1EBE70", alignSelf: "center", "&:hover": { background: "#165983", color: "#fff" } }} onClick={() => handleCompleteProcess()}>
                Complete Process
              </Button>
            </Box>
          </Modal>

          <Modal open={showHiringModal} onClose={() => setShowHiringModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <HiringStatusModal
              allStudents={JSON.parse(JSON.stringify(shortListedStudents))}
              close={() => setShowHiringModal(false)}
              handleAcceptReject={(data) => {
                setSelectedStudent(data);
                setShowHiringModal(false);
                setHireSingleStudent(true);
              }}
            />
          </Modal>
        </>
      )}
    </Box>
  );
}

export default Shortlists;

const ShortListLoader = () => (
  <div style={{ padding: "2rem 9rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Skeleton height={60} width={300} sx={{ borderRadius: "15px" }} />
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Skeleton height={80} width={60} sx={{ borderRadius: "50%" }} />
        <Box>
          <Skeleton height={30} width={160} sx={{ borderRadius: "15px" }} />
          <Skeleton height={30} width={160} sx={{ borderRadius: "15px" }} />
        </Box>
        <Skeleton height={80} width={60} sx={{ borderRadius: "50%" }} />
      </Box>
    </Box>
    <Box>
      <Skeleton width={200} height={50} variant="rounded" />
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Skeleton width={200} height={50} variant="text" />
        <Skeleton width={200} height={50} variant="text" />
      </Box>
    </Box>
    <Box>
      <Skeleton width={"100%"} height={80} variant="text" />
      <Skeleton width={200} height={40} variant="text" />
    </Box>
  </div>
);
