import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import Cookies from "js-cookie";
import Service from "../../services/httpService";
function RecruiterLogin() {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const services = new Service();
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [formInputs, setFormInputs] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    designation: "",
    email: "",
    contact: "",
  });

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [loginError, setLoginError] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    designation: "",
    email: "",
    contact: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleLoginForm = (e) => {
    setLoginError({
      email: "",
      password: "",
    });

    const { name, value } = e.target;
    let tempForm = { ...loginForm };
    tempForm[name] = value;
    setLoginForm(tempForm);
  };

  const handleFormChange = (e) => {
    setErrors({
      firstName: "",
      lastName: "",
      companyName: "",
      designation: "",
      email: "",
      contact: "",
    });
    const { name, value } = e.target;

    let tempValue = { ...formInputs };
    tempValue[name] = value;
    setFormInputs(tempValue);
  };

  const validateLoginForm = () => {
    let flag = true;
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(loginForm.email)) {
      setLoginError((prev) => {
        return {
          ...prev,
          email: "enter a valid email",
        };
      });
      flag = false;
    }

    if (loginForm.password === "") {
      setLoginError((prev) => {
        return {
          ...prev,
          password: "Password is required",
        };
      });
      flag = false;
    }

    return flag;
  };

  const validateForm = () => {
    let flag = true;
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formInputs.firstName === "") {
      setErrors((prev) => {
        return {
          ...prev,
          firstName: "First Name cannot be empty",
        };
      });
      flag = false;
    }
    if (formInputs.lastName === "") {
      setErrors((prev) => {
        return {
          ...prev,
          lastName: "Last Name cannot be empty",
        };
      });
      flag = false;
    }

    if (formInputs.companyName === "") {
      setErrors((prev) => {
        return {
          ...prev,
          companyName: "Company Name cannot be empty",
        };
      });
      flag = false;
    }

    if (formInputs.designation === "") {
      setErrors((prev) => {
        return {
          ...prev,
          designation: "Designation cannot be empty",
        };
      });
      flag = false;
    }
    if (!emailPattern.test(formInputs.email)) {
      setErrors((prev) => {
        return {
          ...prev,
          email: "Enter a valid email",
        };
      });
      flag = false;
    }
    if (formInputs.contact === "") {
      setErrors((prev) => {
        return {
          ...prev,
          contact: "Contact cannot be empty",
        };
      });
      flag = false;
    } else if (formInputs.contact.length !== 10) {
      setErrors((prev) => {
        return {
          ...prev,
          contact: "Enter an appropriate number",
        };
      });
      flag = false;
    }

    return flag;
  };

  const handleSubmit = async (e) => {
    if (validateForm()) {
      const body = {
        first_name: formInputs.firstName,
        email: formInputs.email,
        user_type: "recruiter",
        company_name: formInputs.companyName,
        designation: formInputs.designation,
        last_name: formInputs.lastName,
        ph_no: formInputs.contact,
        signup_platform: "CareerCarve",
        device_type: "Web",
      };
      try {
        const signUpPost = await services.post("/signup", body);
        if (signUpPost.status) {
          localStorage.setItem("company", formInputs.companyName);
          localStorage.setItem("email", formInputs.email);
          setErrorMessage("");

          navigate("/ActivateAccount");
        }
      } catch (err) {
        if (err.response.data.message === "User exists") {
          setErrorMessage("User already exists. Try Login");
        } else if (err.response.data.message === "Mail already sent") {
          setErrorMessage("Mail is Already Sent. Activate your account to continue");
        } else {
          setErrorMessage(err.response.data.message);
        }
      }
    }
  };

  const handleLoginSubmit = async (e) => {
    if (validateLoginForm()) {
      const body = {
        email: loginForm.email,
        password: loginForm.password,
      };

      const loginData = await services.post("/login", body);
      if (loginData.status === 200) {
        Cookies.set("token", loginData.headers.jwt, { expires: 182.5 });
        Cookies.set("user_type", loginData.data.user_type, { expires: 182.5 });
        navigate("/Recruiter");
      }
    }
  };
  return (
    <Box sx={{ display: "flex", gap: "2rem", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          width: "100%",
          backgroundImage: "url('https://du03hetco8xdw.cloudfront.net/recruiter/cc_login_bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png"
          alt="background"
          style={{
            height: "150px",
            width: "150px",
            // top: "max(30%,200px)",
            // left: "20%",
          }}
        />
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/CareerCarve.png"
          alt="background"
          style={{
            height: "3rem",
            width: "15rem",
            // top: "max(52%,350px)",
            // left: "13%",
          }}
        />
        <p
          style={{
            top: "max(62%, 430px)",

            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>
      {isLogin ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1.5rem",
            padding: "0px 8rem",
          }}
        >
          <Typography sx={{ fontSize: "36px" }}>Login</Typography>
          <TextField label="Email" name="email" onChange={(e) => handleLoginForm(e)} helperText={loginError.email} error={loginError.email === "" ? false : true} sx={{ width: "70%" }} value={loginForm.email} />

          <TextField type="password" label="Password" name="password" onChange={(e) => handleLoginForm(e)} helperText={loginError.password} error={loginError.password === "" ? false : true} sx={{ width: "70%" }} value={loginForm.password} />
          <Button
            sx={{
              background: "linear-gradient(99deg, #2AA8C4 -9.01%, #38C1DF 103.32%)",
              color: "#fff",
              width: "70%",
              padding: "10px 20px",
              borderRadius: "15px",
            }}
            onClick={(e) => handleLoginSubmit()}
          >
            Login
          </Button>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              marginTop: "2rem",
            }}
          >
            <Typography>Don't have an account?</Typography>
            <Typography sx={{ color: "#1C8EA8", "&:hover": { cursor: "pointer" } }} onClick={(e) => setIsLogin(false)}>
              Signup
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "3rem 8rem",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "32px", textAlign: "center" }}>Join us as a Recruiter Today!</Typography>
            <Typography sx={{ fontSize: "16px", textAlign: "center", color: "#8B8B8B" }}>Enter your details and we shall get back to you</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <TextField label="First Name" name="firstName" helperText={errors.firstName} error={errors.firstName === "" ? false : true} onChange={(e) => handleFormChange(e)} sx={{ width: "100%" }} value={formInputs.firstName} />
            <TextField label="Last Name" onChange={(e) => handleFormChange(e)} helperText={errors.lastName} error={errors.lastName === "" ? false : true} name="lastName" sx={{ width: "100%" }} value={formInputs.lastName} />
          </Box>
          <TextField
            label="Company Name"
            name="companyName"
            onChange={(e) => handleFormChange(e)}
            helperText={errors.companyName}
            error={errors.companyName === "" ? false : true}
            sx={{
              width: "100%",
            }}
            value={formInputs.companyName}
          />
          <TextField label="Designation" name="designation" onChange={(e) => handleFormChange(e)} helperText={errors.designation} error={errors.designation === "" ? false : true} sx={{ width: "100%" }} value={formInputs.designation} />
          <TextField type="email" label="Email" name="email" onChange={(e) => handleFormChange(e)} sx={{ width: "100%" }} helperText={errors.email} error={errors.email === "" ? false : true} value={formInputs.email} />
          <TextField label="Contact Number" name="contact" onChange={(e) => handleFormChange(e)} helperText={errors.contact} error={errors.contact === "" ? false : true} sx={{ width: "100%" }} value={formInputs.contact} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox checked={privacyCheck} onChange={(e) => setPrivacyCheck(e.target.checked)} />
            <Typography>
              I agree to the{" "}
              <a href="https://www.careercarve.com/TermsOfUse" target="_blank" style={{ textDecoration: "none" }}>
                <span style={{ color: "#1C8EA8" }}>Terms of Use</span>
              </a>{" "}
              and{" "}
              <a href="https://www.careercarve.com/PrivacyPolicy" target="_blank" style={{ textDecoration: "none" }}>
                {" "}
                <span style={{ color: "#1C8EA8" }}>Privacy Policy</span>
              </a>
            </Typography>
          </Box>
          {errorMessage !== "" && <Typography sx={{ color: "red", textAlign: "center" }}>{errorMessage}</Typography>}
          <Button
            sx={{
              background: "linear-gradient(99deg, #2AA8C4 -9.01%, #38C1DF 103.32%)",
              color: privacyCheck === true ? "#fff" : "#000",
              padding: "10px 20px",
              borderRadius: "12px",
              "&:disabled": {
                background: "#C0C0C0",
              },
            }}
            onClick={(e) => handleSubmit()}
            disabled={privacyCheck === true ? false : true}
          >
            Create
          </Button>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <Typography>Already have an account?</Typography>
            <Typography sx={{ color: "#1C8EA8", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/")}>
              Login
            </Typography>
          </Box>
          {/* <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <Typography>Already have an account?</Typography>
            <Typography
              sx={{ color: "#1C8EA8", "&:hover": { cursor: "pointer" } }}
              onClick={e => setIsLogin(true)}
            >
              Login
            </Typography>
          </Box> */}
        </Box>
      )}
    </Box>
  );
}

export default RecruiterLogin;
