import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import Service from "../../../services/httpService";

function SelectMultipleStudent({ absentList, close, allShortlistedStudent, updateShortListedStudent, selectedJobs }) {
  const services = new Service();
  const handleAcceptStudent = async () => {
    try {
      let ids = absentList.map((item) => item.id);
      let data = absentList.map((item) => {
        return {
          id: item.id,
          is_accepted: 1,
        };
      });
      const body = {
        job_id: selectedJobs.id,
        student_list_data: data,
      };

      const putRequest = await services.put(`/jobs/recruiter/accRejStudent`, body);
      const temp = allShortlistedStudent.map((item) => {
        if (ids.includes(item.id)) {
          return {
            ...item,
            status_name: "Selected",
          };
        } else {
          return { ...item };
        }
      });
      updateShortListedStudent(temp);
      close();
    } catch (error) {}
  };
  return (
    <Box sx={{ background: "#fff", display: "flex", flexDirection: "column", gap: "20px", width: "50%", height: "auto", borderRadius: "20px", padding: "40px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>
          Are you sure you would like to <span style={{ color: "#1EBE70" }}>Hire these candidates</span>?
        </Typography>
        <IconButton onClick={() => close()} sx={{ height: "100%" }}>
          <Close sx={{ fontSize: "24px" }} />
        </IconButton>
      </Box>
      <Box>
        <Typography sx={{ textAlign: "center" }}>You have selected the following students to hire:</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {absentList.map((absent_student) => (
            <Box sx={{ background: "#FAFAFA", padding: "10px" }}>
              <Typography sx={{ color: "#165983", fontSize: "16px", textAlign: "center" }}>{absent_student.student_name}</Typography>
              <Typography sx={{ color: "#545454", fontSize: "16px", textAlign: "center" }}>{absent_student.college_name}</Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography sx={{ color: "#8B8B8B", fontSize: "16px" }}>This step will send an email confirmation to the selected candidate about their interview decision. This is an irreversible step, Are you sure you want to continue?</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button sx={{ color: "#fff", border: "1px solid #1EBE70", background: "#1EBE70", padding: "8px 15px", borderRadius: "15px", textTransform: "none", width: "150px", marginBlock: "10px", "&:hover": { background: "#1EBE70", color: "#fff" } }} onClick={() => handleAcceptStudent()}>
            Accept Student(s)
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button sx={{ color: "#165983", background: "#fff", padding: "3px 15px", borderRadius: "15px", textTransform: "none", width: "150px", textDecoration: "underline", alignSelf: "center" }} onClick={() => close()}>
            Go Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default SelectMultipleStudent;
