import { Box, Button, Checkbox, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../components/Navbar";
import TimeService from "../../exportedFunctions/epochTime";
import { Color, DivCenter } from "../../services/GlobalStyles";
import Service from "../../services/httpService";
import Loader from "../Loader/Loader";
import RecruiterSidenavbar from "./RecruiterSidenavbar";
import "./applicants.css";
function Applicants() {
  const services = new Service();
  const timeServices = new TimeService();
  const navigate = useNavigate();

  const [loading, setLoading] = useState({ page_loading: false, small_page_loading: false });
  const initial_requestData = {
    user_id: null,
    first_name: "",
    last_name: "",
    college_name: "",
    assessment_details: [
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
      {
        assessment_name: "",
        assessment_score: null,
      },
    ],
  };

  const initial_assessment_details = {
    assessment_id: null,
    assessment_name: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [allRequestData, setAllRequestData] = useState([{ ...initial_requestData }]);
  const [assessmentDetails, setAssessmentDetails] = useState([{ ...initial_assessment_details }]);

  const [selectedStudent, setSelectedStudent] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const job_id = queryParams.get("job_id");
  const [reRender, setReRender] = useState(true);

  //for filtering data
  const [backup_RequestData, setBackup_RequestData] = useState({ ...allRequestData });
  const [studentDetailModal, setStudentDetailModal] = useState(false);
  const [detailCandidate, setDetailCandidate] = useState(null);

  useEffect(() => {
    const handleMainUseEffect = async () => {
      setLoading((prev) => ({ ...prev, small_page_loading: true }));
      const { temp_data, assessment } = await shortlistStudent_operations();

      setAllRequestData(temp_data);
      setBackup_RequestData(temp_data);
      setAssessmentDetails(assessment);
      setLoading((prev) => ({ ...prev, small_page_loading: false }));
    };
    handleMainUseEffect();
  }, [reRender]);

  const shortlistStudent_operations = async () => {
    const { data, assessment_details, shortlistedStudent } = await fetchApplicantsbyJobId();
    let assessment = [...assessment_details];

    data.forEach((d) => {
      d.competency_score = (d.assessment_details.reduce((acc, assessment) => acc + assessment.assessment_score, 0) / d.assessment_details.length).toFixed(4);
    });

    //inserting empty assessment inside the main-data because it wouldn't come at all if there has been no test
    //inside assessment details outside of data[], there is always assessment names and ids so we take it from there
    // data.forEach((d) => {
    //   const difference = assessment_details.length - d.assessment_details.length;

    //   if (difference > 0) {
    //     for (let i = 0; i < difference; i++) {
    //       d.assessment_details.push({
    //         assessment_name: null,
    //         assessment_score: null,
    //         no_of_test: null,
    //       });
    //     }
    //   }
    // });

    let tempData = data.map((stu, index) => {
      let test_data = assessment_details.map((test, index) => {
        let testDetails = stu.assessment_details.find((item) => item.test_type_id === test.test_type_id);
        return [null, undefined].includes(testDetails)
          ? {
              no_of_test: null,
              test_type_id: testDetails.test_type_id,
              assessment_name: testDetails.assessment_name,
              assessment_score: null,
            }
          : {
              no_of_test: testDetails.no_of_test,
              test_type_id: testDetails.test_type_id,
              assessment_name: testDetails.assessment_name,
              assessment_score: testDetails.assessment_score,
            };
      });

      return {
        ...stu,
        assessment_details: test_data,
      };
    });

    const ids = shortlistedStudent.map((item) => item.user_id);
    let temp_data = tempData.filter((item) => item.user_id);
    // temp_data = temp_data.map((item, index) => {
    //   return {
    //     ...item,
    //     is_shortlisted: ids.includes(item.user_id) ? 1 : 0,
    //     is_selected: false,
    //   };
    // });

    temp_data = temp_data.filter((item, index) => !ids.includes(item.user_id));

    temp_data.sort((a, b) => b.competency_score - a.competency_score);
    return { temp_data, assessment };
  };

  const fetchApplicantsbyJobId = async () => {
    try {
      const response = await services.get(`/jobs/recruiter/jobUserScore?job_id=${job_id}`);
      const studentGet = await services.get(`/jobs/recruiter/interviewApplicants?job_id=${job_id}`);

      if (response) return { data: response.data, assessment_details: response.assessment_details, shortlistedStudent: studentGet.get_shortlisted_student };
      else return [];
    } catch (error) {
      console.error("Error fetching applicants:", error);
    }
  };

  const handleCheckBoxClick = (user_id) => {
    const temp_data = allRequestData.map((item) => {
      return {
        ...item,
        is_selected: item.user_id === user_id ? !item.is_selected : item.is_selected,
      };
    });

    const temp_selectedStudent = temp_data.filter((stu) => stu.is_selected === true);

    setSelectedStudent(temp_selectedStudent);

    setAllRequestData(temp_data);
  };

  return (
    <>
      {loading.small_page_loading ? (
        <Loader show={loading.small_page_loading} />
      ) : (
        <Box sx={{ height: "100Vh", overflowY: "hidden" }}>
          <RecruiterSidenavbar />
          <Box
            sx={{
              padding: "2rem 9rem",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Navbar title="Applicants" description={"To shortlist a candidate directly, select the candidate(s) name by clicking on the checkbox."} />
              {selectedStudent.length > 0 && (
                <Box>
                  <Button sx={{ padding: "8px 15px", borderRadius: "15px", background: "#165983", color: "#fff", border: "1px solid #165983", "&:hover": { background: "#165983", color: "#fff" }, textTransform: "none" }} onClick={() => setConfirmationModal(true)}>
                    Shortlist Student
                  </Button>
                </Box>
              )}
              {allRequestData.length > 0 && (
                <Box className="box__header" sx={{ gap: "0.2rem", paddingInline: "20px 10px" }}>
                  <Box sx={{ flex: 0.2 }}></Box>
                  <Typography style={{ flex: 1 }}>Name</Typography>
                  {assessmentDetails.map((assessment) => (
                    <Typography className="typography__header" style={{ flex: 1 }}>
                      {assessment.assessment_name.includes("SJT") ? "Situational Judgement" : assessment.assessment_name}
                    </Typography>
                  ))}
                  <Typography className="typography__header" style={{ flex: 1 }}>
                    Competency
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                width: "100%",
                overflowY: "auto",
                height: "66vh",
                paddingInline: "16px 10px",
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                  height: "30%",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#165983",
                  borderRadius: "15px",
                  backgroundClip: "padding-box",
                },
                "&::-webkit-scrollbar": {
                  // display: "none",

                  width: "4px",
                },
                "&::-webkit-scrollbar-track-piece:start": {
                  marginTop: "10px",
                },
                "&::-webkit-scrollbar-track-piece:end": {
                  marginBottom: "10px",
                },
              }}
            >
              {allRequestData.length > 0 ? (
                allRequestData.map((request_row, index) => (
                  <Box key={index} sx={{}}>
                    <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-evenly", padding: "16px 0 16px 0", background: request_row.is_shortlisted === 1 ? "rgba(0,0,0,0.05)" : "", borderRadius: "15px" }}>
                      <Box sx={{ flex: 0.2 }}>
                        <Checkbox checked={request_row.is_selected} onChange={() => handleCheckBoxClick(request_row.user_id)} disabled={request_row.is_shortlisted === 1 ? true : false} />
                      </Box>
                      <Typography style={{ flex: 1 }}>
                        <span style={{ color: Color.primary1 }}>{request_row.first_name + " " + request_row.last_name}</span>
                        <br />
                        <span style={{ color: Color.neutralMidGrey }}>{request_row.college ?? "N/A"}</span>
                        <br />
                        {/* <a href={request_row.resume_urls} target="_blank" style={{ textDecoration: "none" }}>
                          <span style={{ color: Color.primary1, textDecoration: "underline" }}>View Details</span>
                        </a> */}

                        <Typography
                          sx={{ color: Color.primary1, textDecoration: "underline" }}
                          onClick={() => {
                            setDetailCandidate(request_row.user_id);
                            setStudentDetailModal(true);
                          }}
                        >
                          View Details
                        </Typography>
                      </Typography>

                      {request_row.assessment_details.map((assessment) => (
                        <Typography style={{ flex: 1, textAlign: "center" }}>
                          {![null, undefined].includes(assessment?.assessment_score) ? (
                            <span>
                              <span style={{ color: Color.neutralDarkGrey }}>{assessment.assessment_score}</span>
                              <span style={{ color: Color.neutralMidGrey }}> ({assessment.no_of_test})</span>
                            </span>
                          ) : (
                            "-"
                          )}
                        </Typography>
                      ))}
                      <Typography style={{ flex: 1, textAlign: "center" }}>{request_row.competency_score}</Typography>
                    </Box>
                    <hr />
                  </Box>
                ))
              ) : (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "30px" }}>
                  <img src="https://du03hetco8xdw.cloudfront.net/recruiter/nojob.svg" style={{ width: "50vw", height: "50vh" }} />
                  <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>Oops! No Applicants Available</Typography>
                </div>
              )}
            </Box>
          </Box>

          <Modal open={confirmationModal} onClose={() => setConfirmationModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Confirm
              close={() => setConfirmationModal(false)}
              selectedStudent={selectedStudent}
              allRequestData={allRequestData}
              job_id={job_id}
              updateData={(data) => {
                setAllRequestData(data);
                setSelectedStudent([]);
                setConfirmationModal(false);
                setReRender((prev) => !prev);
              }}
            />
          </Modal>

          <Modal open={studentDetailModal} onClose={() => setStudentDetailModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ViewStudent
              close={() => setStudentDetailModal(false)}
              detailCandidate={detailCandidate}
              allRequestData={allRequestData}
              job_id={job_id}
              updateData={(data) => {
                setAllRequestData(data);
                setSelectedStudent((prev) => prev.filter((item) => item.user_id !== detailCandidate));
                setStudentDetailModal(false);
                setReRender((prev) => !prev);
              }}
            />
          </Modal>
        </Box>
      )}
    </>
  );
}

export default Applicants;

const Confirm = ({ close, allRequestData, selectedStudent, job_id, updateData }) => {
  const services = new Service();
  const handleShortlistStudent = async () => {
    let body = {
      job_id: job_id,
      shortlisted_students: selectedStudent.map((item) => {
        return {
          user_id: item.user_id,
          competency: item.competency_score,
        };
      }),
    };

    const response = await services.post(`/jobs/recruiter/recruiterShortlist`, body);

    if (response.data.success) {
      // let temp_allRequestData = allRequestData.map((item) => {
      //   return {
      //     ...item,
      //     is_shortlisted: item.is_selected ? 1 : item.is_shortlisted,
      //     is_selected: false,
      //   };
      // });

      let studentIds = selectedStudent.map((item) => item.user_id);
      let temp_allRequestData = allRequestData
        .filter((item) => !studentIds.includes(item.user_id))
        .map((item) => {
          return {
            ...item,
            is_selected: false,
          };
        });

      updateData(temp_allRequestData);
    }
  };
  return (
    <Box sx={{ width: "40vw", background: "#fff", borderRadius: "20px", padding: "20px 20px 20px 30px", display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ textAlign: "justify" }}>
        The following student(s) will be shortlisted for further process.<span>Decision cannot be changed once taken</span>
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        {selectedStudent.map((item) => (
          <Box sx={{ display: "flex", gap: "0.2rem" }}>
            <Typography>{`${item.first_name} ${item.last_name}`}</Typography>

            <Typography sx={{ color: Color.neutralMidGrey }}>{`- ${item.college}`}</Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Button sx={{ padding: "8px 15px", borderRadius: "15px", background: "#fff", textTransform: "none", color: "#165983", border: "1px solid #165983" }} onClick={() => close()}>
          Cancel
        </Button>
        <Button sx={{ padding: "8px 15px", borderRadius: "15px", background: "#165983", color: "#fff", border: "1px solid #165983", "&:hover": { background: "#165983", color: "#fff" }, textTransform: "none" }} onClick={() => handleShortlistStudent()}>
          Confirm Shortlist
        </Button>
      </Box>
    </Box>
  );
};

const ViewStudent = ({ close, allRequestData, detailCandidate, job_id, updateData }) => {
  const student = allRequestData.find((item) => item.user_id === detailCandidate);
  const services = new Service();
  const handleShortlistStudent = async () => {
    let body = {
      job_id: job_id,
      shortlisted_students: [
        {
          user_id: student.user_id,
          competency: student.competency_score,
        },
      ],
    };

    const response = await services.post(`/jobs/recruiter/recruiterShortlist`, body);

    if (response.data.success) {
      // let temp_allRequestData = allRequestData.map((item) => {
      //   return {
      //     ...item,
      //     is_shortlisted: item.is_selected ? 1 : item.is_shortlisted,
      //     is_selected: false,
      //   };
      // });

      let temp_allRequestData = allRequestData
        .filter((item) => item.user_id !== student.user_id)
        .map((item) => {
          return {
            ...item,
            is_selected: false,
          };
        });

      updateData(temp_allRequestData);
      close();
    }
  };
  return (
    <Box sx={{ width: "40vw", background: "#fff", borderRadius: "20px", padding: "20px 20px 20px 30px", display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontSize: "24px" }}>{student.first_name + " " + student.last_name}</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: "18px", color: "#8B8B8B", flex: 1 }}>Student Name:</Typography>
          <Typography sx={{ fontSize: "18px", flex: 3 }}>{student.first_name + " " + student.last_name}</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: "18px", color: "#8B8B8B", flex: 1 }}>University:</Typography>
          <Typography sx={{ fontSize: "18px", flex: 3 }}>{student.college}</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: "18px", color: "#8B8B8B", flex: 1 }}>Contact Number:</Typography>
          <Typography sx={{ fontSize: "18px", flex: 3 }}>{student.ph_no}</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <a href={student.resume_urls} target="_blank" style={{ textDecoration: "none" }}>
            <Typography sx={{ fontSize: "18px", color: Color.primary1, textDecoration: "underline" }}>View Resume</Typography>
          </a>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button sx={{ padding: "8px 15px", borderRadius: "15px", background: "#165983", color: "#fff", border: "1px solid #165983", "&:hover": { background: "#165983", color: "#fff" }, textTransform: "none" }} onClick={() => handleShortlistStudent()}>
          Shortlist Student
        </Button>
      </Box>
    </Box>
  );
};
