import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Service from "../../services/httpService";

function ActivateAccount() {
  const service = new Service();
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [toaster, setToster] = useState("");

  const handleResend = async () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regex.test(value)) {
      const body = {
        email: value,
      };
      try {
        setToster(true);
        const resend = await service.post(`/resendVerify`, body);
        setShowResendButton(false);
        // setDisabled(true);
      } catch (e) {
        setError(e.response.data.message);
      }
    } else {
      if (value.length > 0) {
        setError("Enter a valid email address");
      } else {
        setError("Email is required");
      }
    }
  };
  const [showResendButton, setShowResendButton] = useState(false);
  return (
    <Box sx={{ display: "flex", gap: "2rem", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          width: "100%",
          backgroundImage: "url('https://du03hetco8xdw.cloudfront.net/recruiter/cc_login_bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/cc_logo.png"
          alt="background"
          style={{
            height: "150px",
            width: "150px",
            // top: "max(30%,200px)",
            // left: "20%",
          }}
        />
        <img
          src="https://du03hetco8xdw.cloudfront.net/recruiter/CareerCarve.png"
          alt="background"
          style={{
            height: "3rem",
            width: "15rem",
            // top: "max(52%,350px)",
            // left: "13%",
          }}
        />
        <p
          style={{
            top: "max(62%, 430px)",

            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1.5rem",
          padding: "0px 8rem",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "36px", textAlign: "center" }}>Activation Email Sent!</Typography>
          <Typography sx={{ fontSize: "16px", color: "#8B8B8B", textAlign: "center", paddingInline: "8%" }}>To access your profile and start posting job opportunities, go to your email address and complete the verification process and get started.</Typography>
        </Box>
        {showResendButton && (
          <TextField
            required
            label="Email"
            sx={{ width: "300px" }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setError("");
            }}
            error={error === "" ? false : true}
            helperText={error}
          />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", justifyContent: "center" }}>
          <Typography sx={{ fontSize: "16px", color: "#8B8B8B", textAlign: "center" }}>Didn’t receive the mail?</Typography>
          {showResendButton ? (
            <Button disabled={disabled} sx={{ background: "#165983", border: "none", color: "#fff", alignSelf: "center", "&:hover": { cursor: "pointer", background: "#165983", color: "#fff" }, padding: "8px 12px", textTransform: "none", borderRadius: "8px" }} onClick={() => handleResend(true)}>
              Resend
            </Button>
          ) : (
            <Button sx={{ background: "#fff", border: "none", color: "#165983", alignSelf: "center", "&:hover": { cursor: "pointer" } }} onClick={() => setShowResendButton(true)}>
              Resend
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ActivateAccount;
