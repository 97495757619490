import { Close, Edit, Star, StarOutline } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import TimeService from "../../../exportedFunctions/epochTime";
import Service from "../../../services/httpService";
import { get_specialCharReplace, send_specialCharReplace } from "../../../exportedFunctions/specialCharacterReplace";
import { motion } from "framer-motion";
function DetailModal({ close, selectedStudent, editMeetingInfo, allShortlistedStudent, updateShortListedStudent, updateSelectedStudent }) {
  const time = new TimeService();

  const services = new Service();
  const [editInterViewNotes, setEditInterviewNotes] = useState(false);
  const [note, setNote] = useState(["", null].includes(selectedStudent.interview_note.note) ? "" : get_specialCharReplace(selectedStudent.interview_note.note));
  const [rating, setRating] = useState(selectedStudent.interview_note.rating);
  const [formError, setFormError] = useState({
    rating: "",
    note: "",
  });
  console.log("SELECTED STUDENTS", selectedStudent);
  const verifyNote = () => {
    let flag = true;
    if ([null, ""].includes(rating)) {
      setFormError((prev) => {
        return {
          ...prev,
          rating: "Rating is important",
        };
      });
      flag = false;
    }

    if ([null, ""].includes(note)) {
      setFormError((prev) => {
        return {
          ...prev,
          note: "Note is important",
        };
      });
      flag = false;
    }

    return flag;
  };
  const handleSave = async () => {
    let temp = {
      ...selectedStudent,
    };
    temp.interview_note.rating = rating;
    temp.interview_note.note = note;
    let temp_shortlistedStudents = allShortlistedStudent.map((stu) => {
      if (stu.id === selectedStudent.id) {
        return {
          ...stu,
          interview_note: {
            note: note,
            rating: rating,
          },
        };
      } else {
        return stu;
      }
    });

    try {
      let body = {
        id: selectedStudent.id,
        note: send_specialCharReplace(note),
        rating: rating,
      };
      if (verifyNote()) {
        const postData = await services.put(`/jobs/recruiter/interviewData`, body);

        if (postData.success) {
          updateSelectedStudent(temp);
          updateShortListedStudent(temp_shortlistedStudents);
          setEditInterviewNotes(false);
        }
      }
    } catch (error) {}
  };
  return (
    <motion.div style={{ padding: "10px", background: "#fff", borderRadius: "40px 0px 0px 40px", position: "absolute", height: "100vh" }} transition={{ duration: 0.5, type: "spring", delay: 0.1 }} initial={{ width: 0, x: "100vw", opacity: 0 }} animate={{ width: "40vw", opacity: 1, x: "60vw" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          height: "100vh",

          padding: "20px 40px",
          gap: "1.5rem",
          overflowY: "scroll",
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "15px",
            height: "30%",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#165983",
            borderRadius: "15px",
            backgroundClip: "padding-box",
          },
          "&::-webkit-scrollbar": {
            // display: "none",

            width: "4px",
          },
          "&::-webkit-scrollbar-track-piece:start": {
            marginTop: "10px",
          },
          "&::-webkit-scrollbar-track-piece:end": {
            marginBottom: "10px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ fontSize: "32px" }}>{selectedStudent.student_name}</Typography>
          </Box>

          <IconButton onClick={() => close()} sx={{ height: "100%" }}>
            <Close sx={{ fontSize: "24px" }} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: "90%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ flex: 1, color: "#8B8B8B", fontSize: "16px" }}>Interview Date:</Typography>
              <Box sx={{ flex: 2 }}>{[null, "", undefined].includes(selectedStudent.meeting_link) ? <Typography sx={{ width: "70%", fontSize: "16px", color: "red" }}>Not Scheduled</Typography> : <Typography sx={{ width: "70%", fontSize: "16px" }}>{time.convertEpochToDate(parseInt(selectedStudent.interview_time))}</Typography>}</Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ flex: 1, color: "#8B8B8B", fontSize: "16px" }}>Interview Time:</Typography>
              <Box sx={{ flex: 2 }}>{[null, "", undefined].includes(selectedStudent.meeting_link) ? <Typography sx={{ width: "70%", fontSize: "16px", color: "red" }}>Not Scheduled</Typography> : <Typography sx={{ width: "70%", fontSize: "16px" }}> {time.convertEpochToTime(parseInt(selectedStudent.interview_time))}</Typography>}</Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ color: "#8B8B8B", fontSize: "16px", flex: 1 }}>Interview Details:</Typography>

              <Box sx={{ flex: 2 }}>
                {[null, "", undefined].includes(selectedStudent.meeting_link) ? (
                  <Typography sx={{ fontSize: "16px", color: "red" }}>Not Scheduled</Typography>
                ) : (
                  <a href={selectedStudent.meeting_link} target="_blank" style={{ color: "#165983", textDecoration: "none" }} rel="noreferrer">
                    {" "}
                    <Typography sx={{ fontSize: "16px", color: "#165983", textDecoration: "none" }}>{selectedStudent.meeting_link}</Typography>{" "}
                  </a>
                )}
              </Box>
            </Box>
          </Box>
          {!["Selected", "Not selected"].includes(selectedStudent.status_name) && (
            <Button sx={{ textTransform: "none", color: "#165983", background: "#fff", padding: "5px 15px", border: "1px solid #165983", width: "fit-content", height: "fit-content", borderRadius: "20px", fontSize: "14px" }} onClick={() => editMeetingInfo()}>
              <Edit sx={{ fontSize: "20px", mr: 1 }} /> Edit
            </Button>
          )}
        </Box>
        <Box sx={{ height: "2px", background: "#E6E6E6", width: "100%" }}></Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <Typography sx={{ fontSize: "20px" }}>Student Details</Typography>
          <Box sx={{ width: "90%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ width: "28%", color: "#8B8B8B", fontSize: "16px" }}>Student Name:</Typography>
              <Typography sx={{ width: "72%", fontSize: "16px" }}>{selectedStudent.student_name}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ width: "28%", color: "#8B8B8B", fontSize: "16px" }}>University</Typography>
              <Typography sx={{ width: "72%", fontSize: "16px" }}> {selectedStudent.college}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ width: "28%", color: "#8B8B8B", fontSize: "16px" }}>Contact number</Typography>
              <Typography sx={{ width: "72%", fontSize: "16px" }}> {selectedStudent.student_phone_number}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ width: "28%", color: "#8B8B8B", fontSize: "16px" }}>Resume:</Typography>
              <a href={selectedStudent.resume_urls} target="_blank" style={{ color: "#165983", textDecoration: "none" }} rel="noreferrer">
                <Typography sx={{ width: "72%", fontSize: "16px", color: "#165983", textDecoration: "none" }}>Resume.pdf</Typography>
              </a>
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: "2px", background: "#E6E6E6", width: "100%" }}></Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography sx={{ fontSize: "20px" }}>Interview Notes</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            {selectedStudent.assessment_details.map((item) => (
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ flex: 1, color: "#8B8B8B" }}>{item.assessment_name.includes("SJT") ? "Situtional Judgement" : item.assessment_name}</Typography>
                <Typography sx={{ flex: 1.2 }}>{item.assessment_score}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {editInterViewNotes ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "20px" }}>Interview Notes</Typography>

              <Button sx={{ color: "#165983", background: "#fff", padding: "5px 15px", border: "1px solid #165983", width: "fit-content", height: "fit-content", borderRadius: "20px", fontSize: "14px" }} onClick={() => handleSave()}>
                Save
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              {[1, 2, 3, 4, 5].map((item) => {
                if (rating >= item) {
                  return (
                    <IconButton
                      onClick={() => {
                        setRating(item);
                        setFormError({
                          rating: "",
                          note: "",
                        });
                      }}
                    >
                      <Star sx={{ color: "#F5A536" }} />
                    </IconButton>
                  );
                } else {
                  return (
                    <IconButton
                      onClick={() => {
                        setRating(item);
                        setFormError({
                          rating: "",
                          note: "",
                        });
                      }}
                    >
                      <StarOutline sx={{ color: "#F5A536" }} />
                    </IconButton>
                  );
                }
              })}
            </Box>
            {![null, ""].includes(formError.rating) && <Typography sx={{ fontSize: "12px", color: "#FF0E0E" }}>Rating is Important!</Typography>}
            <Box sx={{ boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)", borderRadius: "12px", padding: "10px" }}>
              <TextField
                onChange={(e) => {
                  setNote(e.target.value);
                  setFormError({
                    rating: "",
                    note: "",
                  });
                }}
                error={[null, ""].includes(formError.note) ? false : true}
                autoFocus={true}
                fullWidth
                multiline
                variant="outlined"
                sx={{
                  fontSize: "16px",
                  color: "#545454",
                  borderRadius: "15px 0px 0px 15px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "red",
                  },
                }}
                helperText={formError.note}
                value={note}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "20px" }}>Interview Notes</Typography>
              {selectedStudent.status_name !== "Selected" && (
                <Box>
                  {["", null].includes(note) ? (
                    <Button sx={{ color: "#165983", background: "#fff", padding: "5px 15px", border: "1px solid #165983", width: "fit-content", height: "fit-content", borderRadius: "20px", fontSize: "14px", textTransform: "none" }} onClick={() => setEditInterviewNotes(true)}>
                      Add notes
                    </Button>
                  ) : (
                    <Button sx={{ color: "#165983", background: "#fff", padding: "5px 15px", border: "1px solid #165983", width: "fit-content", height: "fit-content", borderRadius: "20px", fontSize: "14px" }} onClick={() => setEditInterviewNotes(true)}>
                      <Edit sx={{ fontSize: "24px", mr: 1 }} /> Edit
                    </Button>
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex" }}>
              {[1, 2, 3, 4, 5].map((item) => {
                if (rating >= item) {
                  return <Star sx={{ color: "#F5A536" }} />;
                } else {
                  return <StarOutline sx={{ color: "#F5A536" }} />;
                }
              })}
            </Box>
            <Box sx={{ boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)", borderRadius: "12px", padding: "10px" }}>
              {["", null].includes(note) ? (
                <Typography sx={{ fontSize: "16px", color: "#E8E8E8" }} onClick={() => setEditInterviewNotes(true)}>
                  Add Notes
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "16px", color: "#545454", overflowWrap: "break-word" }}>{note}</Typography>
              )}
            </Box>
          </Box>
        )}
        <Box sx={{ height: "2px", background: "#E6E6E6", width: "100%" }}></Box>
        {![null, ""].includes(selectedStudent.rejection_remark) && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Typography sx={{ fontSize: "20px" }}>Rejection Remarks</Typography>

            <Box sx={{ boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)", borderRadius: "12px", padding: "10px" }}>
              <Typography sx={{ fontSize: "16px", color: "#545454" }}>{get_specialCharReplace(selectedStudent.rejection_remark)}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </motion.div>
  );
}

export default DetailModal;
