export const SortJobs = (job) => {
  let order = {
    Accept: 1,
    "In review": 2,
    "On hold": 3,
    Incomplete: 4,
    Closed: 5,
    Expired: 6,
  };

  let tempData = job.sort((a, b) => {
    const statusComparison = order[a.job_status] - order[b.job_status];

    if (statusComparison !== 0) {
      return statusComparison;
    }

    return b.job_published_time - a.job_published_time;
  });

  return tempData;
};
