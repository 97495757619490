import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  user_type: null,
  user_id: null,
  companyName: null,
  email: "",
  designation: "",
  ph_no: null,
  chatPage: false,
  chat_id: null,
  company_id: null,
  company_image: null,
  linkedin: "",
  website: "",
  location: "",
  about_company: "",
  industry: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name ? action.payload.name : state.name;
      state.user_type = action.payload.userType ? action.payload.userType : state.userType;
      state.user_id = action.payload.user_id ? action.payload.user_id : state.user_id;
      state.email = action.payload.email ? action.payload.email : state.email;
      state.companyName = action.payload.companyName ? action.payload.companyName : state.companyName;
      state.designation = action.payload.designation ? action.payload.designation : state.designation;
      state.ph_no = action.payload.ph_no ? action.payload.ph_no : state.ph_no;
      state.company_id = action.payload.company_id ? action.payload.company_id : state.company_id;
      state.company_image = action.payload.company_image ? action.payload.company_image : state.company_image;
    },
    setChatPage: (state, action) => {
      state.chatPage = action.payload.chatPage;
      state.chat_id = action.payload.chat_id;
    },
    setCompanyDetails: (state, action) => {
      state.website = action.payload.website ? action.payload.website : state.website;
      state.linkedin = action.payload.linkedin ? action.payload.linkedin : state.linkedin;
      state.location = action.payload.location ? action.payload.location : state.location;
      state.about_company = action.payload.about_company ? action.payload.about_company : state.about_company;
      state.industry = action.payload.industry ? action.payload.industry : state.industry;
    },
  },
});

export const { setUser, setChatPage, setCompanyDetails } = userSlice.actions;

export default userSlice.reducer;
