import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import TimeService from "../../../exportedFunctions/epochTime";
import Service from "../../../services/httpService";

function ScheduleMeeting({ student, close, shortListedStudents, updateShortListedStudent }) {
  const [formError, setFormError] = useState({
    meetingLink: "",
  });
  const timeService = new TimeService();

  let previousdate = student.interview_time === "Not Scheduled" ? new Date() : timeService.convertEpochToDate(parseInt(student.interview_time));

  let previousTime = student.interview_time === "Not Scheduled" ? `${new Date().getHours()}:${new Date().getMinutes()}` : `${new Date(parseInt(student.interview_time)).getHours()}:${new Date(parseInt(student.interview_time)).getMinutes()}`;

  let timeCheck = previousTime.split(":");
  if (timeCheck[0].length !== 2) {
    previousTime = `0${timeCheck[0]}:${timeCheck[1]}`;
  }
  if (timeCheck[1].length !== 2) {
    previousTime = `${timeCheck[0]}:0${timeCheck[1]}`;
  }

  const [value, setValue] = useState(dayjs(previousdate));
  const [ampm, setAmpm] = useState("AM");

  const [time, setTime] = useState(previousTime);
  const [meetingLink, setMeetingLink] = useState(student.meeting_link ? student.meeting_link : "");
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const services = new Service();
  const handleSave = async () => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!["", null].includes(meetingLink)) {
      if (urlRegex.test(meetingLink)) {
        const newTime = new Date(`${value["$d"].getFullYear()}-${value["$d"].getUTCMonth() + 1}-${value["$d"].getDate()} ${time}`);
        const epoch = newTime.getTime();

        try {
          let body = {
            id: student.id,
            interview_link: meetingLink,
            interview_time: epoch,
          };
          const putMeetingDetails = await services.put(`/jobs/recruiter/interviewApplicants`, body);
          if (![null, undefined].includes(shortListedStudents)) {
            let temp = shortListedStudents.map((item) => {
              if (item.id === student.id) {
                return {
                  ...item,
                  meeting_link: meetingLink,
                  interview_time: epoch,
                  status_name: "Interview scheduled",
                };
              } else {
                return {
                  ...item,
                };
              }
            });

            //Updating Values
            updateShortListedStudent(temp);
          }
          close();
        } catch (error) {
          window.alert(error.response.data.message);
        }
      } else {
        setFormError((prev) => {
          return {
            ...prev,
            meetingLink: "Enter a valid link",
          };
        });
      }
    } else {
      setFormError((prev) => {
        return {
          ...prev,
          meetingLink: "Link is required",
        };
      });
    }
  };

  useEffect(() => {
    if (parseInt(time.substring(0, 2)) > 11) {
      setAmpm("PM");
    } else {
      setAmpm("AM");
    }
  }, [time]);

  const handleAmPm = (val) => {
    let timeCheck = time.split(":");

    if (val === "PM" && parseInt(timeCheck[0]) < 12) {
      let newTime = `${(parseInt(timeCheck[0]) + 12) % 24}:${timeCheck[1]}`;
      setTime(newTime);
      setAmpm("PM");
    } else {
      let hour = parseInt(timeCheck[0]) % 12;

      hour = JSON.stringify(hour).length === 2 ? hour : `0${hour}`;
      let newTime = `${hour}:${timeCheck[1]}`;
      console.log("NEW TIME", newTime);
      setAmpm("AM");
      setTime(newTime);
    }
  };

  return (
    <Box>
      <Modal open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ width: "50%", background: "#fff", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "20px", paddingBottom: "20px" }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "45%", background: "#165983", borderRadius: "15px 0px 0px 0px", padding: "20px", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "20px" }}>{student.student_name}</Typography>
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>{student.college_name}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "20px" }}>{value["$d"].getFullYear()}</Typography>
                <Typography></Typography>
                <Typography sx={{ color: "#fff", fontSize: "32px" }}>
                  {days[value["$d"].getDay()]}, {months[value["$d"].getUTCMonth()]} {value["$d"].getDate()}th
                </Typography>
              </Box>

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeClock value={time} onChange={(newValue) => setTime(newValue)} ampm />
              </LocalizationProvider> */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  type="time"
                  value={time}
                  InputProps={{
                    sx: {
                      height: "74px",
                      width: "fit-content",
                      m: 0,

                      fontSize: "30px",
                      color: "#fff",
                      borderRadius: "10px 0px 0px 10px",

                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                      },
                    },
                    endAdornment: null,
                  }}
                  onChange={(e) => setTime(e.target.value)}
                  sx={{
                    background: "#165983",
                    border: "1px solid #fff",
                    borderRight: "none",
                    borderRadius: "15px 0px 0px 15px",
                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                      filter: "invert(100%) sepia(5%) saturate(2066%) hue-rotate(199deg) brightness(92%) contrast(100%);",
                      color: "#165983",
                    },
                    "& .MuiFilledInput-root": {
                      background: "rgb(232, 241, 250)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                      borderColor: "none!important",
                    },
                  }}
                />
                <Box ox sx={{ display: "flex", flexDirection: "column", gap: "2px", background: "#165983", padding: "5px", border: "1px solid #fff", borderLeft: "none", borderRadius: "0px 15px 15px 0px" }}>
                  <Typography sx={{ fontSize: "14px", padding: "5px", background: ampm === "AM" ? "#fff" : "#165983", color: ampm === "AM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }} onClick={() => handleAmPm("AM")}>
                    AM
                  </Typography>
                  <Typography sx={{ fontSize: "14px", padding: "5px", background: ampm === "PM" ? "#fff" : "#165983", color: ampm === "PM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }} onClick={() => handleAmPm("PM")}>
                    PM
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "55%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} views={["year", "month", "day"]} dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "14px", color: "#165983" }}>{day}</Typography>} disablePast />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "20px", textAlign: "center" }}>Add Meeting Link</Typography>
            <Typography sx={{ fontSize: "16px", textAlign: "center", color: "#8B8B8B" }}>Add your meeting link for the student to join it at the scheduled time</Typography>
          </Box>
          <TextField
            placeholder="Add meeting link"
            InputProps={{ sx: { height: "40px" } }}
            sx={{ width: "400px", alignSelf: "center" }}
            value={meetingLink}
            onChange={(e) => {
              setMeetingLink(e.target.value);
              setFormError((prev) => {
                return {
                  ...prev,
                  meetingLink: "",
                };
              });
            }}
            error={formError.meetingLink === "" ? false : true}
            helperText={formError.meetingLink}
          ></TextField>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <Button sx={{ width: "150px", padding: "8px 15px", borderRadius: "15px", background: "#fff", color: "#165983", border: "1px solid #165983" }} onClick={() => close()}>
              Cancel
            </Button>
            <Button sx={{ width: "150px", padding: "8px 15px", borderRadius: "15px", background: "#165983", color: "#fff", border: "1px solid #165983", "&:hover": { background: "#165983", color: "#fff" } }} onClick={() => handleSave()}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ScheduleMeeting;
