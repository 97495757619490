import React, { useState } from "react";

import { motion } from "framer-motion";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../Styles/GlobalStyles";
function AreaOfWork({ close }) {
  const [onExit, setOnexit] = useState(false);

  const data = [
    {
      specialization: "Sales & Marketing",
      roles: ["Lead generation", "Digital Marketing", "Market research", "Data Analysis & Report Writing", "Marketing Plan", " Sales Effectiveness", "Beat Planning", "Distribution management/development", "B2B sales"],
    },
    {
      specialization: "Human Resources",
      roles: ["Market research and Benchmarking", "HR policy development", "Vendor Empanelment", "Talent Acquisition", "Performance Management", "Learning and Development", "Compliance", "Industrial relations"],
    },
    {
      specialization: "Operations",
      roles: ["Process development/improvement", "routine operations management", "supply chain management", "digital transformation", "program management", "Logistics", "cost improvement", "procurement"],
    },
    {
      specialization: "Technology",
      roles: ["Project management", "program management", "product management and research", "digital transformation", "analytics", "pre-sales", "proposal building", "tech consulting"],
    },
    {
      specialization: "Finance",
      roles: ["Equity research", "Financial Product Sales", "Risk Management and dashboards", "Corporate finance and planning", "Cost Reduction", "Business Finance and Reports", "Financial Due Diligence"],
    },
    {
      specialization: "Analytics",
      roles: ["Data visualization", "Data modelling", "Data validation", "model testing and verification", "documentation", "business modelling"],
    },
    {
      specialization: "Strategy & Consulting",
      roles: ["Industry benchmarking", "market research", "Go-To-Market strategy", "process excellence", "digital transformation", "analytics enablement"],
    },
  ];

  return (
    <motion.div
      style={{ position: "absolute", background: "#fff", borderRadius: "20px", padding: "2px" }}
      transition={{ duration: 0.3, type: "spring", delay: 0.3 }}
      initial={{ width: 0, height: 0, x: "100vw", y: "0", opacity: 0 }}
      animate={!onExit ? { width: "70vw", height: "70vh", x: "15vw", y: "15vh", opacity: 1 } : { width: 0, height: 0, x: "100vw", y: "100vh", opacity: 0 }}
      onClick={() => {
        setOnexit(true);
        setTimeout(() => {
          close();
        }, 400);
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          gap: "12px",
          overflowY: "auto",
          height: "100%",
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "15px",
            height: "30%",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#165983",
            borderRadius: "15px",
            backgroundClip: "padding-box",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track-piece:start": {
            marginTop: "10px",
          },
          "&::-webkit-scrollbar-track-piece:end": {
            marginBottom: "10px",
          },
        }}
      >
        <Typography sx={{ fontSize: "24px", textAlign: "center", color: Colors.primary }}>Typical area of work for Live Project?</Typography>
        <Typography sx={{ fontSize: "16px", textAlign: "center", color: "#999999" }}>Please do assess if a project is worthy enough for an MBA student. A task which can be done simply by a undergrad student should be avoided as it may not elicit enough applications. For live projects, please assess if the task given can be performed remotely to increase the project outcome</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
          {data.map((item) => (
            <RenderBlock item={item} />
          ))}
        </Box>
      </Box>
    </motion.div>
  );
}

const RenderBlock = ({ item }) => (
  <Box sx={{ padding: "20px", borderRadius: "20px", border: "1px solid black", width: "230px" }}>
    <Typography sx={{ fontSize: "16px", textAlign: "center" }}>{item.specialization}</Typography>
    {item.roles.map((element, index) => (
      <Typography sx={{ fontSize: "12px", color: "#999999", textAlign: "center" }}>{element}</Typography>
    ))}
  </Box>
);

export default AreaOfWork;
