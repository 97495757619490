// import React from "react";
// import { useTimer } from "../ResendButton/ResendButton";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
export const validateNameEmailPhone = (values, phone) => {
  const errors = {};
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.firstname || values.firstname?.trim() === "") {
    errors.firstname = "First name is required!";
  }
  if (!values.email || values.email?.trim() === "") {
    errors.email = "Email is required!";
  } else if (!regex.test(values.email.trim())) {
    errors.email = "This is not a valid email format!";
  }

  if (phone.length > 2 && phone.length < 7) {
    errors.phone = "Phone number must be more than 7 characters";
  }
  return errors;
};
export const validateEmailPassword = (values, phone) => {
  const errors = {};
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email || values.email?.trim() === "") {
    errors.email = "Email is required!";
  } else if (!regex.test(values.email)) {
    errors.email = "This is not a valid email format!";
  }

  if (!values.password || values.password === "") {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be more than 8 characters";
  } else if (values.password.length > 20) {
    errors.password = "Password cannot exceed more than 20 characters";
  }
  return errors;
};

export const validatePassword = (values) => {
  const errors = {};
  // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  if (!values.password || values.password === "") {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be more than 8 characters";
  } else if (values.password.length > 20) {
    errors.password = "Password cannot exceed more than 20 characters";
  }
  // else if (!strongRegex.test(values.password)) {
  //   errors.password = "wrong format";
  // }

  return errors;
};
export const validateConfirmPassword = (values) => {
  const errors = {};
  // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  if (!values.password || values.password === "") {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be more than 8 characters";
  } else if (values.password.length > 20) {
    errors.password = "Password cannot exceed more than 20 characters";
  }
  if (!values.confirmPwd || values.confirmPwd === "") {
    errors.confirmPwd = "Confirmation is required!";
  } else if (values.password !== values.confirmPwd) {
    errors.confirmPwd = "Password isn't Matching!";
  }
  // else if (!strongRegex.test(values.password)) {
  //   errors.password = "wrong format";
  // }

  return errors;
};

export const validateEmail = (values) => {
  const errors = {};
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email || values.email?.trim() === "") {
    errors.email = "Email is required!";
  } else if (!regex.test(values.email)) {
    errors.email = "This is not a valid email format!";
  }
  return errors;
};

//AI section Bullet - Validation
export const validateBullet = (values) => {
  let errors = "";
  let content = values.trim();
  if (content === "") {
    return (errors = "Empty");
  } else if (content.length < 1) return (errors = "Empty");
  else if (content.length > 300) return (errors = "Cannot be more than 300 characters");
  return errors;
};

// formUtils.js
// validate password
const validate = (values) => {
  const errors = {};
  // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};

export const useTimer = () => {
  const [resendTime, setResendTime] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setResendTime((prevTime) => (prevTime > 0 ? prevTime - 1 : prevTime));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return resendTime;
};

export const handleSubmit = async (e, formValues, setFormErrors, setIsSubmit, navigate, setErrMsg, service, signUpButtonText, endpoint) => {
  const handleResend = async () => {
    const resendEndpoint = "/resendVerify"; // Replace this with your desired dynamic endpoint
    const resendSuccessMessage = `Link successfully resent to ${formValues.email}`; // Replace this with your desired success message

    const res = await service.post(resendEndpoint, { email: formValues.email });
    if (res.status === 200) {
      setErrMsg(
        <p>
          {resendSuccessMessage}
          <br />
          Didn't get the link?
          <button className="signup-button" type="button" onClick={handleResend}>
            Resend
          </button>
        </p>
      );
    }
  };

  e.preventDefault();
  const formError = validate(formValues);
  setFormErrors(formError);

  if (Object.keys(formError).length === 0) {
    try {
      setIsSubmit(true);
      const res = await service.post(endpoint, {
        email: formValues.email,
        password: formValues.password,
      });
      if (res.status === 200) {
        if (res.data.user_type !== "recruiter") {
          setErrMsg(<p>Please Login with recruiter Account</p>);
          return;
        }
        Cookies.set("token", res.headers.jwt, { expires: 182.5 });
        Cookies.set("user_type", res.data.user_type, { expires: 182.5 });

        if (res.data.user_type === "recruiter") {
          navigate("/Recruiter");
        }

        // const cookies = res.headers.jwt;
      }
    } catch (err) {
      if (err?.response.status === 400 && err?.response.data.message === "user not registered") {
        setErrMsg(
          <p>
            You have not signed up yet. Please sign up first to create your account.
            <button className="signup-button" type="button" onClick={() => navigate("/GetYourFreeAccount")}>
              {signUpButtonText}
            </button>
          </p>
        );
      } else if (err?.response.status === 401 && err?.response.data.message === "unauthorized") {
        setErrMsg(<p>Wrong Password</p>);
      } else if (err?.response.data.message === "wrong password") {
        setErrMsg(<p>Incorrect password. Please confirm and try again</p>);
      } else if (err?.response.data.message === '"password" length must be at least 8 characters long') {
        setErrMsg(<p>Incorrect password. Please confirm and try again</p>);
      } else if (err?.response.status === 500 && err?.response.data.message === "Unknown Error Occurred") setErrMsg(<p>Unknown Error Occurred</p>);
      else if (err?.response.data.message === "Please sign in using Google or Apple ID.") {
        setErrMsg(<p>This email is already signed up using Google/Apple. Please continue with Google/Apple.</p>);
      } else if (err?.response.data.message === "verify first") {
        setErrMsg(
          <p>
            Your account is not activated yet. Please check {formValues.email} to activate your account.
            <br /> Can't find activation link?
            <button className="signup-button" type="button" onClick={handleResend}>
              Resend
            </button>
          </p>
        );
      } else {
        //
        setErrMsg(<p>{err.response.data.message}</p>);
      }
    }
  }
};
