import { Box, Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RecruiterSidenavbar from "../Recruiter/RecruiterSidenavbar";
import { useDispatch, useSelector } from "react-redux";
import { AccountCircle, EditOutlined } from "@mui/icons-material";
import Navbar from "../../components/Navbar";
import Service from "../../services/httpService";
import ImageCropper from "./ImageCropper";
import { setCompanyDetails, setUser } from "../../store/reducers";
import Cookies from "js-cookie";
import "react-quill/dist/quill.snow.css";

import axios from "axios";
import { get_specialCharReplace, send_specialCharReplace } from "../../exportedFunctions/specialCharacterReplace";
import ReactQuill from "react-quill";
import { makeTextBold } from "../../exportedFunctions/htmlParser";

function Profile() {
  const { companyName, email, designation, ph_no, name, company_id, company_image, website, industry, linkedin, about_company, location } = useSelector((store) => store.user);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [editImageModal, setEditImageModal] = useState(false);

  const [formError, setFormError] = useState({
    about: "",
    location: "",
    industry: "",
  });
  const services = new Service();
  const [values, setValues] = useState({
    name: companyName,
    image_url: company_image,
    email: email,
    designation: designation,
    ph_no: ph_no,
    website: website,
    linkedin: linkedin,
    about: get_specialCharReplace(about_company),
    location: location,
    industry: industry,
  });

  const imageURL = useRef("");
  const verifySave = () => {
    let flag = true;
    let temp_form = { ...formError };
    if (["", null, undefined].includes(values.location)) {
      temp_form.location = "Location is required";
      flag = false;
    }

    if (["", null, undefined].includes(values.industry)) {
      temp_form.industry = "Industry is required";
      flag = false;
    }

    if (["", null, undefined].includes(values.about)) {
      temp_form.about = "About Company is required";
      flag = false;
    }
    setFormError(temp_form);
    return flag;
  };
  const handleSave = async () => {
    if (verifySave()) {
      const body = {
        id: company_id,
        website_url: ["", null, undefined].includes(values.website) ? "null" : values.website,
        linkedin: ["", null, undefined].includes(values.linkedin) ? "null" : values.linkedin,
        about: ["", null, undefined].includes(values.about) ? "null" : send_specialCharReplace(values.about),
        location: ["", null, undefined].includes(values.location) ? "null" : values.location,
        industry: ["", null, undefined].includes(values.industry) ? "null" : values.industry,
      };

      const put_data = await services.put("/jobs/admin/recruiterCompanies", body);

      if (put_data.success) {
        dispatch(
          setCompanyDetails({
            website: ["", null, undefined].includes(values.website) ? "" : values.website,
            linkedin: ["", null, undefined].includes(values.linkedin) ? "" : values.linkedin,
            about_company: ["", null, undefined].includes(values.about) ? "" : values.about,
            location: ["", null, undefined].includes(values.location) ? "" : values.location,
            industry: ["", null, undefined].includes(values.industry) ? "" : values.industry,
          })
        );
      }
      setEdit(false);
      setFormError({
        location: "",
        industry: "",
        about: "",
      });
    }
  };

  const handlePostRequest = async (image) => {
    setLoading(true);
    const byteString = window.atob(image.split(",")[1]);
    const mimeString = image.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blobFile = new Blob([ia], { type: mimeString });
    const formData = new FormData();
    formData.append("company_logo", blobFile, `${companyName}`);

    let headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    };
    const response = await axios.post(
      `
    ${process.env.REACT_APP_API_URL}/jobs/uploadCompanyLogo`,
      formData,
      { headers }
    );

    if (response.data.success) {
      let body = {
        id: company_id,
        company_image: response.data.company_logos.url,
      };
      try {
        const uploadlink = services.put("/jobs/admin/recruiterCompanies", body);
        let tempValue = { ...values };
        tempValue.image_url = response.data.company_logos.url;
        setValues(tempValue);
        dispatch(
          setUser({
            company_image: response.data.company_logos.url,
          })
        );
        setLoading(false);
        setEditImageModal(false);
      } catch (e) {
        window.alert("Some error occurred while uploading the image ");
      }
    }
  };

  const handleImageUpload = (image) => {
    imageURL.current = image;

    handlePostRequest(image);
  };

  const getData = async () => {
    if (!["", null, undefined].includes(values.about)) {
      return;
    }

    if ([null, undefined].includes(company_id)) {
      return;
    }
    const getComapnyData = await services.get(`/jobs/recruiter/companyById?company_id=${company_id}`);
    let data = {
      name: companyName,
      image_url: getComapnyData.data.company_image ? getComapnyData.data.company_image : "",
      email: email,
      designation: designation,
      ph_no: ph_no,
      website: getComapnyData.data.website_url ? getComapnyData.data.website_url : "",
      linkedin: getComapnyData.data.linkedin ? getComapnyData.data.linkedin : "",
      about: getComapnyData.data.about ? get_specialCharReplace(getComapnyData.data.about) : "",
      location: getComapnyData.data.location ? getComapnyData.data.location : "",
      industry: getComapnyData.data.industry ? getComapnyData.data.industry : "",
    };
    setValues(data);
  };

  const handleValueChange = (e) => {
    let temp_data = {
      ...values,
      about: e,
    };

    setValues(temp_data);
  };

  useEffect(() => {
    getData();
  }, [company_id]);
  return (
    <Box>
      <RecruiterSidenavbar />
      <Box sx={{ padding: "2rem 9rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Navbar title="About Company" />
        <Box sx={{ border: "1px solid #E6E6E6", padding: "20px", borderRadius: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "24px" }}>Basic Information</Typography>
            {/* <Button sx={{ border: "1px solid #165983", padding: "5px 12px", color: "#165983", "&:hover": { background: "#fff", color: "#165983", borderRadius: "20px" } }}>
              {" "}
              <EditOutlined sx={{ marginRight: "5px" }} /> Edit
            </Button> */}
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              {[null, undefined, ""].includes(company_image) ? (
                <Box sx={{ background: "#165983", borderRadius: "50%", height: 120, width: 120, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography style={{ fontSize: "63px", textAlign: "center", color: "#fff", fontWeight: "500" }}>{companyName ? companyName[0].toUpperCase() : "A"}</Typography>
                </Box>
              ) : (
                <img style={{ width: 150, height: 150, borderRadius: "50%" }} src={company_image} />
              )}
              <IconButton onClick={() => setEditImageModal(true)} sx={{ position: "relative", right: "2rem", zIndex: 999, background: "#fff", color: "#165983", border: "1px solid #165983", padding: "5px", "&:hover": { background: "#fff" }, width: "fit-content", height: "fit-content" }}>
                <EditOutlined sx={{ fontSize: "20px" }} />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "16px" }}>{companyName}</Typography>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{email}</Typography>
              <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>{ph_no}</Typography>
            </Box>
          </Box>
        </Box>
        {edit ? (
          <Box sx={{ border: "1px solid #165983", padding: "20px", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography sx={{ fontSize: "24px" }}>Organization Profile</Typography>
                {/* <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>All information has been taken from linkedin</Typography> */}
              </Box>
              <Button sx={{ border: "1px solid #165983", padding: "5px 12px", color: "#165983", height: "fit-content", "&:hover": { background: "#fff", color: "#165983" }, borderRadius: "20px" }} onClick={() => handleSave()}>
                {" "}
                <EditOutlined sx={{ marginRight: "5px" }} /> Save
              </Button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
              <Box sx={{ display: "flex", gap: "40px" }}>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Website</Typography>
                  <TextField
                    autoFocus={true}
                    onChange={(e) => {
                      let temp = { ...values };
                      temp.website = e.target.value;
                      setValues(temp);
                    }}
                    // variant="outlined"
                    sx={{
                      fontSize: "16px",
                      color: "#545454",

                      width: "100%",
                      borderRadius: "15px 0px 0px 15px",
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //   border: "red",
                      // },
                    }}
                    InputProps={{
                      sx: { padding: "0px", margin: 0, width: "fit-content", height: "40px" },
                    }}
                    // helperText={formError.note}
                    value={values.website}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>linkedin</Typography>
                  <TextField
                    onChange={(e) => {
                      let temp = { ...values };
                      temp.linkedin = e.target.value;
                      setValues(temp);
                    }}
                    // variant="outlined"
                    sx={{
                      fontSize: "16px",
                      color: "#545454",

                      width: "100%",
                      borderRadius: "15px 0px 0px 15px",
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //   border: "red",
                      // },
                    }}
                    InputProps={{
                      sx: { padding: "0px", margin: 0, width: "fit-content", height: "40px" },
                    }}
                    value={values.linkedin}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "2rem" }}>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Location</Typography>
                  <TextField
                    error={[null, "", undefined].includes(formError.location) ? false : true}
                    helperText={formError.location}
                    onChange={(e) => {
                      let temp = { ...values };
                      temp.location = e.target.value;
                      setValues(temp);
                    }}
                    // variant="outlined"
                    sx={{
                      fontSize: "16px",
                      color: "#545454",

                      width: "100%",
                      borderRadius: "15px 0px 0px 15px",
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //   border: "red",
                      // },
                    }}
                    InputProps={{
                      sx: { padding: "0px", margin: 0, width: "fit-content", height: "40px" },
                    }}
                    // helperText={formError.note}
                    value={values.location}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Industry</Typography>
                  <TextField
                    error={[null, "", undefined].includes(formError.industry) ? false : true}
                    helperText={formError.industry}
                    onChange={(e) => {
                      let temp = { ...values };
                      temp.industry = e.target.value;
                      setValues(temp);
                    }}
                    // variant="outlined"
                    sx={{
                      fontSize: "16px",
                      color: "#545454",

                      width: "100%",
                      borderRadius: "15px 0px 0px 15px",
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //   border: "red",
                      // },
                    }}
                    InputProps={{
                      sx: { padding: "0px", margin: 0, width: "fit-content", height: "40px" },
                    }}
                    // helperText={formError.note}
                    value={values.industry}
                  />
                </Box>
              </Box>
              <Box sx={{}}>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>About Company</Typography>
                <ReactQuill
                  theme="snow"
                  style={{ marginBottom: "20px" }}
                  value={values.about}
                  onChange={(e) => handleValueChange(e)}
                  modules={{
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                />
                {/* <TextField
                  error={[null, "", undefined].includes(formError.about) ? false : true}
                  helperText={formError.about}
                  multiline
                  fullWidth
                  onChange={(e) => {
                    let temp = { ...values };
                    temp.about = e.target.value;
                    setValues(temp);
                  }}
                  // variant="outlined"
                  sx={{
                    fontSize: "16px",
                    color: "#545454",

                    width: "100%",
                    borderRadius: "15px 0px 0px 15px",
                    // "& .MuiOutlinedInput-notchedOutline": {
                    //   border: "red",
                    // },
                  }}
                  InputProps={{
                    sx: { padding: "0px", margin: 0 },
                  }}
                  // helperText={formError.note}
                  value={values.about}
                /> */}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ border: "1px solid #E6E6E6", padding: "20px", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography sx={{ fontSize: "24px" }}>Organization Profile</Typography>
                {/* <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>All information has been taken from linkedin</Typography> */}
              </Box>
              <Button
                sx={{ border: "1px solid #165983", padding: "5px 12px", color: "#165983", height: "fit-content", "&:hover": { background: "#fff", color: "#165983" }, borderRadius: "20px" }}
                onClick={() => {
                  setEdit(true);
                }}
              >
                {" "}
                <EditOutlined sx={{ marginRight: "5px" }} /> Edit
              </Button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex", gap: "40px" }}>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Website</Typography>
                  {values.website === "" ? (
                    <Typography sx={{ fontSize: "16px" }}>N/A</Typography>
                  ) : (
                    <a href={values.website.includes("http") ? values.website : `https://${values.website}`} style={{ color: "#165983", textDecoration: "none" }} target="_blank">
                      <Typography sx={{ fontSize: "16px" }}>{values.website}</Typography>
                    </a>
                  )}
                </Box>

                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>LinkedIn</Typography>
                  {values.linkedin === "" ? (
                    <Typography sx={{ fontSize: "16px" }}>N/A</Typography>
                  ) : (
                    <a href={values.linkedin.includes("http") ? values.linkedin : `https://${values.linkedin}`} style={{ color: "#165983", textDecoration: "none" }} target="_blank">
                      <Typography sx={{ fontSize: "16px" }}>{values.linkedin}</Typography>
                    </a>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "2rem" }}>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Location</Typography>
                  <Typography sx={{ fontSize: "16px" }}>{values.location === "" ? "N/A" : values.location}</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Industry</Typography>
                  <Typography sx={{ fontSize: "16px" }}>{values.industry === "" ? "N/A" : values.industry}</Typography>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>About Company</Typography>
                <Typography sx={{ fontSize: "16px", padding: "10px", border: "1px solid #E6E6E6", borderRadius: "10px" }}>{values.about === "" ? "N/A" : makeTextBold(get_specialCharReplace(values.about))}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Modal open={editImageModal} onClose={() => setEditImageModal(false)}>
          {/* <ProfilePictureSelection marginLeft={"max(12%,10px)"} getProfileImage={(data) => setPicture(data)} move_picture={movePofilePicture} show_user={1} pic={values.image_url} /> */}
          <ImageCropper handleImageUpload={(data) => handleImageUpload(data)} imageUrl={values.image_url} close={() => setEditImageModal(false)} loading={loading} />
        </Modal>
      </Box>
    </Box>
  );
}

export default Profile;
