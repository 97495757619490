import "react-image-crop/dist/ReactCrop.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ActivateAccount from "./screens/Login/ActivateAccount";
import Login from "./screens/Login/Login";
import Profile from "./screens/Profile/Profile";
import Jobs from "./screens/Recruiter/Jobs";
import NewPassword from "./screens/Recruiter/NewPassword";
import RecruiterDashboard from "./screens/Recruiter/RecruiterDashboard";
import RecruiterJobs from "./screens/Recruiter/RecruiterJobs";
import RecruiterLogin from "./screens/Recruiter/RecruiterLogin";
import Schedule from "./screens/Recruiter/Schedule";
import Shortlists from "./screens/Recruiter/Shortlists";

import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CreateNewPassword from "./screens/Login/CreateNewPassword";
import ForgotPassword from "./screens/Login/ForgotPassword";
import Applicants from "./screens/Recruiter/Applicants";
import NewChatPage from "./screens/Recruiter/Modals/NewChatPage";
import NewScheduler from "./screens/Recruiter/NewScheduler";
import { setChatPage } from "./store/reducers";
import AddEventButton from "./screens/Recruiter/AddEventButton";

function App() {
  const { chatPage } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  return (
    <BrowserRouter>
      <Modal
        open={chatPage}
        onClose={() => {
          dispatch(
            setChatPage({
              chatPage: false,
              chat_id: null,
            })
          );
        }}
      >
        <NewChatPage />
      </Modal>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Mail" element={<Schedule />} />

        <Route path="/Jobs" element={<Jobs />} />

        <Route path="/NewPassword/:email/:token" element={<NewPassword />} />
        <Route path="/ActivateAccount" element={<ActivateAccount />} />

        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/SetNewPassword/:email/:token" element={<CreateNewPassword />} />

        <Route path="/Recruiter" element={<RecruiterDashboard />} />

        <Route path="/Applicants" element={<Applicants />} />
        <Route path="/Recruiter/Jobs" element={<RecruiterJobs />} />
        <Route path="/Recruiter/Shortlist" element={<Shortlists />} />

        <Route path="/Recruiter/Schedule" element={<NewScheduler />} />
        <Route path="/Recruiter/Signup" element={<RecruiterLogin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
